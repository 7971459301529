import { Transition } from '@headlessui/react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';

import CloseIcon from 'assets/icons/CrossIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';
import SuccessIcon from 'assets/icons/SuccessIcon';

const Notification = ({
  onClose,
  message,
  type = 'success',
  timeout = 2500,
  className,
  closeButtonClassName,
  icon,
}) => {
  useEffect(() => {
    const timer = setTimeout(onClose, timeout);

    return () => clearTimeout(timer);
  }, [onClose, timeout]);

  return (
    <Transition
      as={Fragment}
      show
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-[-1000px]"
      enterTo="opacity-100 translate-y-0"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-[-1000px]"
    >
      <div
        className={cn(
          className,
          'border border-gray text-sm flex items-start bg-white w-full shadow-lg rounded-xl p-5',
        )}
      >
        {icon ? (
          icon
        ) : (
          <>
            {type === 'error' ? (
              <ErrorIcon className="h-8 w-8 min-w-[32px] text-orange mr-2" />
            ) : (
              <SuccessIcon className="h-8 w-8 min-w-[32px] text-mint mr-2" />
            )}
          </>
        )}
        <span className="whitespace-pre-line">{message}</span>
        <button
          type="button"
          className={cn(
            closeButtonClassName,
            'ml-auto text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green',
          )}
          onClick={onClose}
        >
          <CloseIcon className="h-5 w-5" />
        </button>
      </div>
    </Transition>
  );
};

Notification.propTypes = {
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(['error', 'success']).isRequired,
  className: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  icon: PropTypes.element,
};

const Alert = ({
  placement = 'top',
  items = [],
  hide,
  timeout = 2500,
  className,
  closeButtonClassName,
  getClassByType,
}) => {
  const handleHide = useCallback(
    (key) => {
      hide(key);
    },
    [hide],
  );

  return (
    <Transition appear show={items.length > 0} as={Fragment}>
      <div
        className={'fixed z-[10110] overflow-y-auto right-0'}
        style={{
          top: placement === 'top' ? '0px' : 'auto',
          bottom: placement === 'top' ? 'auto' : '0px',
        }}
      >
        <div className="w-[300px] min-h-full pr-4 text-base inline-block max-w-full my-4 overflow-hidden text-left transition-all transform">
          <div className="flex flex-col space-y-4">
            {items.map(({ key, message, type, icon }, i) => (
              <Notification
                key={key}
                message={message}
                onClose={() => handleHide(key)}
                type={type}
                timeout={timeout + 1000 * i}
                className={getClassByType ? getClassByType(type) : className}
                closeButtonClassName={closeButtonClassName}
                icon={icon}
              />
            ))}
          </div>
        </div>
      </div>
    </Transition>
  );
};
Alert.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['error', 'success']).isRequired,
      icon: PropTypes.element,
    }),
  ),
  placement: PropTypes.oneOf(['top', 'bottom']).isRequired,
  hide: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  className: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  getClassByType: PropTypes.func,
};
export default Alert;

export const AlertSingle = ({ placement = 'top', item, className, closeButtonClassName }) => {
  const [alerts, setAlerts] = useState([item]);

  const removeErrorByKey = (key) => {
    setAlerts((prev) => prev.filter((error) => error.key !== key));
  };

  return (
    <Alert
      hide={removeErrorByKey}
      items={alerts}
      placement={placement}
      className={className}
      closeButtonClassName={closeButtonClassName}
    />
  );
};
AlertSingle.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['error', 'success']).isRequired,
    icon: PropTypes.element,
  }).isRequired,
  placement: PropTypes.oneOf(['top', 'bottom']),
  className: PropTypes.string,
  closeButtonClassName: PropTypes.string,
};
