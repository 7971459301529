import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

const InputWithAddon = ({
  name,
  value,
  onChange,
  onKeyDown,
  autoFocus,
  valid = true,
  prefix,
  postfix,
  addonAfter,
  placeholder,
  className,
  inputClassName,
  size = 'small',
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  let sizeClassName = 'px-1.5 pt-0.5 h-[22px] text-xs leading-none';
  if (size === 'large') {
    sizeClassName = 'h-11 px-3 py-4 text-sm leading-5';
  }

  let borderColor = 'border-gray-300';
  if (!valid) {
    borderColor = 'border-red';
  }
  if (isFocused) {
    borderColor = 'border-green';
  }

  const onFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input using the ref
    }
    setIsFocused(true);
  };
  return (
    <div
      className={cn(
        ' relative flex items-center w-full appearance-none border rounded  text-gray-700   focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 ',
        {
          'pl-6': prefix,
        },
        borderColor,
        className,
      )}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {prefix && (
        <span className="text-xs absolute pointer-events-none left-[8px] top-[4px]">{prefix}</span>
      )}
      <input
        className={cn(
          'rounded w-full h-full appearance-none  text-gray-700 focus:outline-none',
          {
            'pl-6': prefix,
            'pr-1.5': postfix,
          },
          sizeClassName,
          inputClassName,
        )}
        ref={inputRef}
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {postfix && (
        <span
          className={cn('text-xs  pt-0.5', addonAfter ? 'pr-0.5' : 'pr-2')}
          onClick={onFocusInput}
        >
          {postfix}
        </span>
      )}

      {addonAfter && <span className="text-xs">{addonAfter}</span>}
    </div>
  );
};

InputWithAddon.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  valid: PropTypes.bool,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
};

export default InputWithAddon;
