const TabletIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 45 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="3.50049" width="43" height="51" rx="3.5" stroke="currentColor" />
      <rect x="8.5" y="14.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="14.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="19.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="19.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="24.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="24.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="29.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="29.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="34.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="34.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="39.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="39.0005" width="12" height="1" fill="currentColor" />
      <rect x="8.5" y="44.0005" width="12" height="1" fill="currentColor" />
      <rect x="24.5" y="44.0005" width="12" height="1" fill="currentColor" />
      <path
        d="M14 3.50049C14 1.84363 15.3431 0.500488 17 0.500488H28C29.6569 0.500488 31 1.84363 31 3.50049C31 5.15734 29.6569 6.50049 28 6.50049H17C15.3431 6.50049 14 5.15734 14 3.50049Z"
        fill="#F1FCF6"
        stroke="currentColor"
      />
    </svg>
  );
};

export default TabletIcon;
