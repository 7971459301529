import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import ButtonStar from 'components/ButtonStar';
import { LIST_TYPE_BY_FIELD } from 'constants/list';
import { PAGE_BRANDS, PAGE_GROWTH_PRODUCTS, PAGE_PRODUCTS, PAGE_SELLERS } from 'constants/pages';
import { COLUMNS, FIELD_IDS } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFavourite } from 'hooks/useFavourite';
import { isGrowthPage, isProductsPage, isSubjectPage } from 'utils/pages';

export const TableCellFilterLinkButton = ({ type, id, value }) => {
  const { addBrands, addSeller, setCategory, setSort, addSubject } = useFilterReducer();
  const navigate = useNavigate();
  const isSellers = window.location.pathname.indexOf(PAGE_SELLERS) >= 0;
  const isBrands = window.location.pathname.indexOf(PAGE_BRANDS) >= 0;
  // const isSubjects = window.location.pathname.indexOf(PAGE_SUBJECTS) >= 0;
  const link = isGrowthPage() ? PAGE_GROWTH_PRODUCTS : PAGE_PRODUCTS;

  const actions = {
    [COLUMNS.BRAND]: addBrands,
    [COLUMNS.CATEGORY]: setCategory,
    [COLUMNS.SELLER]: addSeller,
    [COLUMNS.SUBJECT]: addSubject,
  };

  const handleFilterUpdate = () => {
    if (!actions[isSellers ? COLUMNS.SELLER : isBrands ? COLUMNS.BRAND : type]) return;

    actions[isSellers ? COLUMNS.SELLER : isBrands ? COLUMNS.BRAND : type]({
      key: id,
      title: value,
      nextLink: link,
    });
    navigate(link);
    setSort({ sort: 'stock', order: 'desc' });
  };

  return (
    <button
      className="cursor-pointer no-underline hover:text-green hover:border-green"
      onClick={handleFilterUpdate}
    >
      <span>{value}</span>
    </button>
  );
};
TableCellFilterLinkButton.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const TableCellFilterLink = ({
  value = '',
  valueFormatted = '',
  data = {},
  id: cellId,
  colDef: { field },
}) => {
  const type = cellId || field;
  const isProducts = isProductsPage();
  const isSubjects = isSubjectPage();
  const key = data?.[FIELD_IDS[type]] || data.id;

  const {
    listData,
    selected,
    handleFavourite,
    isLoading: isFavouritesLoading,
  } = useFavourite(LIST_TYPE_BY_FIELD?.[type], key);

  return (
    <div className="flex items-center">
      {!isProducts && !isSubjects && (
        <ButtonStar
          active={!!selected.length}
          options={listData}
          isLoading={isFavouritesLoading}
          onChange={handleFavourite}
          selected={selected}
        />
      )}
      <TableCellFilterLinkButton
        type={type}
        id={key}
        value={valueFormatted ? valueFormatted : value}
      />
    </div>
  );
};

TableCellFilterLink.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  colDef: PropTypes.shape({
    field: PropTypes.string,
  }),
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellFilterLink;
