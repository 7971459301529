import TabletIcon from 'assets/icons/TabletIcon';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useQuery } from 'react-query';
import baseClient from 'services/baseClient';

const PING_TIME = 60000;
const PING_ERROR_TRIES = 2;

const PING_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const handlePing = async () => {
  const response = await baseClient.ping();
  const { ok } = response || {};
  if (ok !== 1) {
    throw new Error('Non-200 status code received');
  }
  return response;
};

const Ping = () => {
  const [status, setStatus] = useState(null);

  useQuery({
    queryKey: null,
    refetchInterval: PING_TIME, // Refetch every 60 seconds (1 minute)
    refetchIntervalInBackground: true, // Ensure it keeps refetching even if the tab is in the background
    retry: PING_ERROR_TRIES,
    retryDelay: 3000,
    queryFn: () => handlePing(),
    onSuccess: () => {
      setStatus(PING_STATUS.SUCCESS);
    },
    onError: () => {
      setStatus(PING_STATUS.ERROR);
    },
  });

  return (
    <>
      {status === PING_STATUS.ERROR && (
        <Modal
          isOpen
          closeModal={() => setStatus(PING_STATUS.SUCCESS)}
          classNames="!p-0"
          headerClassNames="!px-5"
          width={'100%'}
          disableCloseOnClickOutside
          disableCloseButton
          footer={false}
        >
          <div className="min-h-screen flex flex-col">
            <div className="bg-gray-100 border border-l-0 border-r-0 border-gray-200 flex-grow flex items-center justify-center">
              <div className="text-gray-500 text-center space-y-10">
                <div className="flex flex-col items-center space-y-2">
                  <TabletIcon className="h-10 w-10 m-auto" />
                  <div>Сайт находится на техническом обслуживании</div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Ping;
