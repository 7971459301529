import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from 'assets/images/smallWhiteArrowRight.svg';

const ButtonArrow = ({ onClick, disabled = false }) => {
  return (
    <button
      className={`flex flex-none bg-green w-[22px] h-[22px] items-center justify-center rounded focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <Arrow />
    </button>
  );
};

ButtonArrow.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ButtonArrow;
