import cn from 'classnames';
import PropTypes from 'prop-types';

const highlightedText = ({ label, highlighted }) => {
  if (!highlighted) {
    return label;
  }

  const index = label.toLowerCase().indexOf(highlighted.toLowerCase());

  if (index === -1) {
    return label;
  }

  const beforeStr = label.substr(0, index);
  const afterStr = label.substr(index + highlighted.length);

  return (
    <>
      {beforeStr}
      <span
        className={cn('text-green', {
          ['capitalize']: beforeStr.length === 0,
        })}
      >
        {highlighted}
      </span>
      {afterStr}
    </>
  );
};

export const Checkbox = ({
  onChange,
  color = 'green',
  label,
  id,
  checked,
  disabled,
  highlighted,
}) => (
  <div
    className={cn('flex items-center text-gray-450', {
      ['pointer-events-none opacity-50']: disabled,
    })}
  >
    <input
      id={id}
      name={id}
      type="checkbox"
      className={cn(
        'checkbox h-4 w-4 shrink-0 bg-origin-border align-middle',
        'rounded appearance-none transition-all border focus:ring-2 focus:ring-offset-2  focus:outline-none',
        'checked:border-transparent checked:bg-contain checked:bg-center checked:bg-no-repeat',
        {
          [`color-${color}-bright border-${color}-bright focus:ring-${color}-bright checked:bg-${color}-bright`]:
            color,
        },
      )}
      onChange={onChange}
      checked={checked}
    />
    <label htmlFor={id} className="py-4 lg:py-0 ml-2 translate-y-px leading-none">
      {highlightedText({ label, highlighted })}
    </label>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  highlighted: PropTypes.string,
  disabled: PropTypes.bool,
};
