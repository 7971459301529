import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { ReactComponent as Arrow } from 'assets/images/arrowRight.svg';

const PageButton = ({ page, activePage, getPage }) => {
  return (
    <button
      className={cn(
        'flex text-black text-xs p-1 min-w-6 items-center justify-center hover:text-green transition-all rounded disabled:pointer-events-none',
        {
          ['bg-green !text-white hover:!text-white']: activePage === page,
        },
      )}
      onClick={() => getPage(page)}
      disabled={activePage === page}
    >
      {page}
    </button>
  );
};
PageButton.propTypes = {
  page: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  getPage: PropTypes.func.isRequired,
};

const Pagination = ({
  currentPage,
  totalPages,
  getPrevPage,
  getNextPage,
  getPage,
  isFilterPagination = false,
  className,
  showNextButton: outerShowNextButton,
}) => {
  const pagesButtons = useMemo(() => {
    const result = [];

    for (let i = 1; i <= 5; i++) {
      if (i > totalPages) break;

      const current = i < 5 ? i : currentPage < 6 ? 5 : currentPage;

      result.push(current);
    }
    return result;
  }, [currentPage, totalPages]);

  const showNextButton =
    outerShowNextButton !== undefined
      ? outerShowNextButton
      : /*(isFilterPagination && totalPages % 5 === 0) ||*/
        !isFilterPagination && totalPages !== currentPage;

  return (
    <div className={`flex items-center space-x-8 ${className}`}>
      <div className="flex space-x-1 items-center">
        {currentPage !== 1 && (
          <button
            className="flex p-2 opacity-20 hover:opacity-100 transition-all transform rotate-180"
            onClick={getPrevPage}
          >
            <Arrow />
          </button>
        )}

        {pagesButtons.map((page) => (
          <React.Fragment key={page}>
            <PageButton page={page} getPage={getPage} activePage={currentPage} />
            {page === 4 && currentPage > 5 && <span className="p-1">...</span>}
          </React.Fragment>
        ))}

        {showNextButton && (
          <button
            className="flex p-2 opacity-20 hover:opacity-100 transition-all"
            onClick={getNextPage}
          >
            <Arrow />
          </button>
        )}
      </div>
    </div>
  );
};
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  getPrevPage: PropTypes.func.isRequired,
  getNextPage: PropTypes.func.isRequired,
  getPage: PropTypes.func.isRequired,
  isFilterPagination: PropTypes.bool,
  className: PropTypes.string,
  showNextButton: PropTypes.bool,
};

export default Pagination;
