import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { CrossIcon } from 'assets/images';
import Popover from 'components/Popover';
import { NOT_AVAILABLE_BECAUSE_TARIFF } from 'constants/tooltips';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useActiveNavLink } from 'hooks/useActiveNavLink';

const labelPropTypes = {
  value: PropTypes.number,
  bg: PropTypes.string,
  onClick: PropTypes.func,
};

const Label = ({ bg = 'blue', value = 0, onClick }) => {
  if (!value) return <></>;

  return (
    <button
      className={`block mr-2 cursor-pointer !py-0.5 !px-1 !h-fit flex items-center gap-2 min-w-4 transition-all group table-nav-label bg-${bg}`}
      onClick={onClick}
    >
      <span className="text-xs font-medium font-SofiaPro">{value}</span>
      <CrossIcon className="transition-colors fill-gray-300 group-hover:fill-black" />
    </button>
  );
};

Label.propTypes = labelPropTypes;

export const tableNavLiPropTypes = {
  isAvailable: PropTypes.bool,
  hidden: PropTypes.bool,
  tip: PropTypes.string,
  link: PropTypes.string,
  search: PropTypes.string,
  title: PropTypes.string.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  single: PropTypes.bool,
  label: PropTypes.shape(labelPropTypes),
};

const TableNavLi = ({
  isAvailable = true,
  link,
  hidden,
  first,
  last,
  title,
  label,
  tip,
  single,
  search,
  callback,
  useTooltip = true,
}) => {
  const tooltipRef = useRef();

  const { setFilterDefault } = useFilterReducer();

  const handleFilterUpdate = () => {
    if (!isAvailable) return;

    //при переходе между страницами обнулять некоторые параметры фильтра
    setFilterDefault({ nextLink: link, prevLink: window.location.pathname });

    callback && callback({ link, search });
  };

  return (
    <li
      data-html={true}
      data-tip={`${!isAvailable && tip ? tip : !isAvailable ? NOT_AVAILABLE_BECAUSE_TARIFF : ''}`}
      className={cn('button-group__item button-group__item--no-padding whitespace-nowrap', {
        ['!text-green bg-[#F1FCF6] focus:outline-none']: useActiveNavLink(link),
        ['pointer-events-auto button-group__item--disabled']: !isAvailable,
        ['button-group__item--first']: first && !single,
        ['button-group__item--last']: last && !single,
        ['button-group__item--single']: single,
        ['mr-3']: single || last,
        ['border-l border-gray-250']: useActiveNavLink(link) && last,
        ['hidden']: hidden,
      })}
    >
      <div ref={tooltipRef} className="button-group__item_wrapper">
        {link !== undefined && (
          <button onClick={handleFilterUpdate} disabled={!isAvailable}>
            {title}
          </button>
        )}
      </div>

      {label && <Label value={label.value} bg={label.bg} onClick={label.onClick} />}

      {!isAvailable && useTooltip && (
        <Popover tooltip={tip ? tip : NOT_AVAILABLE_BECAUSE_TARIFF} tooltipRef={tooltipRef} />
      )}
    </li>
  );
};

TableNavLi.propTypes = {
  ...tableNavLiPropTypes,
  callback: PropTypes.func.isRequired,
};

export const NavListWrapper = ({ items, classList, callback }) => {
  const navRef = useRef();

  return (
    <nav
      ref={navRef}
      className={cn('py-4 mr-3', {
        [classList]: classList,
      })}
    >
      <ul className="button-group">
        {items.map((item, i) => (
          <TableNavLi
            key={i}
            isAvailable={item.isAvailable}
            hidden={item.hidden}
            link={item.link}
            search={item.search}
            title={item.title}
            label={item.label}
            tip={item.tip}
            useTooltip={item.useTooltip}
            first={!item.single && (i === 0 || items?.[i - 1].single || item.first)}
            last={
              !item.single &&
              (i === items.length - 1 || items?.[i + 1].single || items?.[i + 1].first)
            }
            single={item.single}
            callback={callback}
          />
        ))}
      </ul>
    </nav>
  );
};

NavListWrapper.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(tableNavLiPropTypes)),
  classList: PropTypes.string,
  callback: PropTypes.func.isRequired,
};
