import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useAuth } from 'context/auth.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';

export const RadioButton = ({
  name,
  value,
  label,
  isChecked,
  onChange,
  disabled = false,
  last,
  containerCn,
  wrapperCn,
  radioCn,
  labelCn,
  position = 'left',
}) => (
  <div
    className={cn('flex', {
      ['border-b-[1px] mx-4 py-4']: !last,
      ['p-4']: last,
      [containerCn]: containerCn,
    })}
  >
    <div
      className={cn('flex items-center relative', {
        [wrapperCn]: wrapperCn,
      })}
    >
      <input
        className={cn(
          'appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
          {
            ['checked:bg-white-600 checked:border-green-600 border-width-4']: isChecked,
            [radioCn]: radioCn,
          },
        )}
        type="radio"
        name={name}
        id={name}
        value={value}
        checked={isChecked}
        onChange={() => onChange(value)}
        disabled={disabled}
      />

      {isChecked && (
        <div
          style={{
            [position]: '4px',
          }}
          className={`absolute rounded-full top-1 h-2 w-2 bg-green`}
        />
      )}
      <label
        className={cn('text-xs ml-3 text-gray-600', {
          [labelCn]: labelCn,
        })}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  </div>
);

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  last: PropTypes.bool,
  wrapperCn: PropTypes.string,
  radioCn: PropTypes.string,
  labelCn: PropTypes.string,
  containerCn: PropTypes.string,
  position: PropTypes.string,
};

export const PlatformPanel = () => {
  const { filter, setPlatform } = useFilterReducer();
  const [activePlatform, setActivePlatform] = useState(filter.platform);
  const { platforms } = useAuth();
  const { setActiveModal } = useUI();

  const applyPlatform = () => {
    setPlatform(activePlatform);
    setActiveModal('');
  };

  const changePlatform = (id) => {
    setActivePlatform(id);
  };

  const platformsFormatter = {
    wb: {
      label: 'WildBerries',
    },
  };
  return (
    <div className="flex flex-col w-full grow pb-4">
      {platforms.map((platform, index) => (
        <RadioButton
          key={platform.id}
          onChange={changePlatform}
          name={platform.name}
          label={platformsFormatter[platform.name]?.label || platform.name}
          value={platform.id}
          isChecked={+platform.id === +activePlatform}
          last={index + 1 === platforms.length}
        />
      ))}
      <button
        className="mt-auto mx-4 bg-green text-white shadow-sm items-center px-3 py-1.5 text-sm rounded"
        onClick={() => applyPlatform()}
      >
        Применить
      </button>
    </div>
  );
};
