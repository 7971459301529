import PropTypes from 'prop-types';

import { PAGE_MAIN } from 'constants/pages';

const ErrorFallbackCommon = ({ message = null }) => {
  const handleRedirect = () => window.location.replace(PAGE_MAIN);

  return (
    <main role="alert" className="flex wrapper flex-auto flex flex-col items-center justify-center">
      <h1 className="mb-4">{message || 'Что-то пошло не так :('}</h1>
      <a onClick={handleRedirect} className="button-primary">
        Вернуться на главную
      </a>
    </main>
  );
};
ErrorFallbackCommon.propTypes = {
  message: PropTypes.string,
};

export default ErrorFallbackCommon;
