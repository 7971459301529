import PropTypes from 'prop-types';

const SearchInputSimpleInputField = ({ value, onChange, placeholder }) => {
  return (
    <input
      type="text"
      value={value || ''}
      onChange={onChange}
      className="px-10 pr-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
      placeholder={placeholder}
    />
  );
};

SearchInputSimpleInputField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInputSimpleInputField;
