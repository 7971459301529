import PropTypes from 'prop-types';

const ActivationCard = ({ title, description }) => (
  <>
    <h1
      className="text-center text-base font-light mb-2"
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
    {description && <div className="text-center font-light text-gray-400">{description}</div>}
  </>
);

ActivationCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default ActivationCard;
