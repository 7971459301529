import SearchInputSimple from 'components/SearchInputSimple';
import { useMultiSearch } from 'hooks/useSearch';
import PropTypes from 'prop-types';
import ProductSearchFormItem from './ProductSearchFormItem';

const ProductSearchForm = ({ setSelectedProducts, selectedProducts = [] }) => {
  const {
    onChange,
    value,
    query: { data = {}, isLoading, isFetching, isSuccess },
  } = useMultiSearch();

  const products = data?.all_data ?? [];

  return (
    <>
      <div className={'f-full py-3 mx-4 grow'}>
        <SearchInputSimple
          placeholder={'Введите артикул товара или ссылку'}
          onSearch={onChange}
          isLoading={isLoading}
        />
      </div>
      <div className="px-5 overflow-y-auto max-h-96 min-h-96">
        {!isLoading && !isFetching && isSuccess && (
          <EmptyMessages value={value} products={products} />
        )}
        {products.map((product) => (
          <ProductSearchFormItem
            key={product.id}
            product={product}
            setSelectedProducts={setSelectedProducts}
            selectedProducts={selectedProducts}
          />
        ))}
      </div>
    </>
  );
};

ProductSearchForm.propTypes = {
  setSelectedProducts: PropTypes.func,
  selectedProducts: PropTypes.object,
};

export default ProductSearchForm;

const EmptyMessages = ({ value, products }) => {
  const emptyProducts = products.length === 0;
  const hasValue = value;

  if (!emptyProducts) return null;

  return hasValue
    ? 'По вашему запросу нет данных. Попробуйте использовать другие слова для поиска'
    : '';
};

EmptyMessages.propTypes = {
  value: PropTypes.string,
  products: PropTypes.array,
};
