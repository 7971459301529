import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';

const ComparisonTable = () => {
  const { data, isLoading, isFetching } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.COMPARISON,
  });
  // console.log(data, 'data');
  return (
    <Table
      data={prepareDataForTable(data)}
      dataPagesKey="data"
      type={TABLE_TYPE.COMPARISON}
      isLoading={isLoading || isFetching}
      noRowsText={`По вашему запросу нет данных.`}
    />
  );
};

export default ComparisonTable;

const prepareDataForTable = (data) => {
  return {
    pages: data?.pages?.map((page) => {
      // const {data} = page
      return {
        data: page.data?.map((el) => {
          const { sales_chart, stock_chart, price_chart, category_charts, category, ...other } = el;
          const result = {
            sales_chart: transformChartData(sales_chart, 'sales_chart', 'orders_rub'),
            stock_chart: transformChartData(stock_chart, 'stock_chart', 'stock'),
            price_chart: transformChartData(price_chart, 'price_chart', 'price'),
            category_charts: transformChartData(
              category_charts,
              'category_charts',
              'categories_qty',
            ),
          };
          return {
            ...other,
            sales_chart: result.sales_chart ? JSON.stringify(result.sales_chart) : '{}',
            stock_chart: result.stock_chart ? JSON.stringify(result.stock_chart) : '{}',
            price_chart: result.price_chart ? JSON.stringify(result.price_chart) : '{}',
            category_charts: result.category_charts ? JSON.stringify(result.category_charts) : '{}',
            category: category?.[0]?.name || '-',
          };
        }),
      };
    }),
  };
};

const transformChartData = (chartData, chartName, valueField = 'value') => {
  return chartData?.reduce((acc, { date, [valueField]: value }) => {
    acc[`${chartName}/${date}`] = value;
    return acc;
  }, {});
};
