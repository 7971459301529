import Table from 'components/Table';
import { TABLE_TYPE } from 'constants';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';

const SearchContent = () => {
  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.SEO_SEARCH,
  });

  return (
    <Table
      data={data}
      type={TABLE_TYPE.SEO_SEARCH}
      isLoading={isLoading || (isFetching && !hasNextPage)}
      noRowsText={`По вашему запросу нет данных.`}
      paginationOnBack={true}
    />
  );
};

export default SearchContent;
