import { useEffect } from 'react';

/**
 * Сапомисный хук для выполнения переданного callback'a при клике ВНЕ контейнера, к которому прикреплен переданный `ref`
 *
 * @param {import('react').MutableRefObject<unknown>} ref Ссылка на наблюдаемый компонент
 * @param {function} callback Функция, которая будет вызвана при клике ВНЕ контейнера
 * */
export default function useClickOutside(ref, callback, excludeRefs = []) {
  useEffect(() => {
    const listener = (event) => {
      // If ref is null or the click is inside the ref element, do nothing
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (Array.isArray(excludeRefs) &&
          excludeRefs.some((excludeRef) => {
            // Exclude by ref
            if (excludeRef.current && excludeRef.current.contains(event.target)) {
              return true;
            }

            // Exclude by class or id using CSS selectors
            if (
              (excludeRef.class && event.target.classList.contains(excludeRef.class)) ||
              (excludeRef.id && event.target.id === excludeRef.id)
            ) {
              return true;
            }
            return false;
          }))
      ) {
        return;
      }

      callback(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback, excludeRefs]);
}
