import { getApi } from 'utils/api';
import AbstractClient from './abstractClient';

class ProfileClient extends AbstractClient {
  constructor(baseURL) {
    super(baseURL);
  }

  async get(endpoint) {
    return this.client
      .get(endpoint)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  async post(endpoint, data = {}) {
    const config = {
      body: data ? data : null,
      headers: {},
    };

    return this.client.post(endpoint, data, config);
  }

  changePassword(password, newPassword, repeatPassword) {
    return this.post(`/auth/changePassword`, {
      password: password,
      new_password: newPassword,
      repeat_password: repeatPassword,
    });
  }

  getTariffPrice(info) {
    const filter = {
      type: [info.tariff],
      platform: info.platform,
      period_id: [info.period],
      promocode: info.promoCode,
      quantile: [info.quantile],
    };

    return this.get(`/user/tariffPrices?filter=${JSON.stringify(filter)}`);
  }

  getPaymentLink({ info, businessmanInfo, arbitraryAmount, paymentTypeId }) {
    const filter = {
      type_id: info.tariff,
      platforms: info.platform,
      payment_type_id: paymentTypeId,
      period_id: info.period,
      credentials: businessmanInfo,
      ...(info.promoCode
        ? {
            promocode: info.promoCode,
          }
        : {}),
      ...(arbitraryAmount?.length
        ? {
            custom_payment: '1',
            sum: arbitraryAmount,
          }
        : {}),
    };

    return this.post(`/user/submitOrder`, filter);
  }
}

const client = new ProfileClient(getApi());

export default client;
