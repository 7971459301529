import LoopIcon from 'assets/icons/LoopIcon';
import { useDebounce } from 'hooks';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import SearchInputSimpleInputField from './SearchInputSimpleInputField';
import SearchInputSimplePostfix from './SearchInputSimplePostfix';

const SearchInputSimple = memo(({ onSearch, placeholder, isLoading, noDebounce = false }) => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  let debouncedSearch = useDebounce(search, 500);
  if (!noDebounce) {
    debouncedSearch = search;
  }
  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  const onCancel = () => {
    setSearch(undefined);
    onSearch(undefined);
  };

  return (
    <div className={'relative border rounded border-gray-230 flex items-center  p-0.5 f-wull'}>
      <LoopIcon width={15} height={15} className={`text-wb absolute left-3 mb-0.5`} />
      <SearchInputSimpleInputField
        value={search}
        placeholder={placeholder}
        onChange={handleSearchChange}
      />
      {(search || isLoading) && (
        <SearchInputSimplePostfix isLoading={isLoading} onCancel={onCancel} />
      )}
    </div>
  );
});

SearchInputSimple.displayName = 'SearchInputSimpleMemo';

SearchInputSimple.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  noDebounce: PropTypes.bool,
};

export default SearchInputSimple;
