import PropTypes from 'prop-types';

const Loading = ({ isLoading = true, error = null }) => {
  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка: {JSON.stringify(error)}</div>;
  }

  return <></>;
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Loading;
