import PropTypes from 'prop-types';

import { ReactComponent as Icon } from 'assets/images/cross.svg';
import cn from 'classnames';

const Chip = ({ title, color, action, className, onChipClick, textClassName }) => {
  if (!title) return <></>;

  return (
    <div
      className={cn(
        `flex h-8 items-center rounded-md border border-transparent ${color} whitespace-nowrap`,
        className,
        { 'pr-2': !action },
      )}
      onClick={onChipClick}
    >
      <span
        className={cn('pl-2', {
          [textClassName]: textClassName,
        })}
      >
        {title}
      </span>
      {action && (
        <button onClick={action} className="m-1.5 p-1.5">
          <Icon />
        </button>
      )}
    </div>
  );
};

Chip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  color: PropTypes.string.isRequired,
  action: PropTypes.func,
  onChipClick: PropTypes.func,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default Chip;
