const ProductTableHeaderExtraFilters = () => {
  let filters = [];

  if (filters.length === 0) {
    return null;
  }
  return <div className="mx-4 py-4 border-t border-gray-200">{filters}</div>;
};

export default ProductTableHeaderExtraFilters;
