/**
 * Хук для работы с сортировкой колонок
 * column Объект aggrid с данными по колонке
 **/

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import { useMemo } from 'react';

export default function useColumnSort({ column, sortable }) {
  const { isNestedHeaderCell, isTableNeedHideFilter, type } = useTable();
  const {
    filter: { sort, order, sortNested, orderNested },
    setSort: setSortDefault,
    setSortNested,
  } = useFilterReducer();

  const isNested = isNestedHeaderCell(column);
  const isTableFilterHidden = isTableNeedHideFilter(type);

  const isSorted = useMemo(() => {
    if (isNested) {
      return sortNested === column?.colId;
    }

    return sort === column?.colId;
  }, [sort, isNested, column?.colId, sortNested]);

  const columnOrder = useMemo(() => {
    if (isSorted) {
      return isNested ? orderNested : order;
    }

    return 'desc';
  }, [isNested, order, orderNested, isSorted]);

  const getNextOrder = () => {
    if (!isSorted) {
      return 'desc';
    }

    if (isNested) {
      return orderNested === 'desc' ? 'asc' : 'desc';
    }

    return order === 'desc' ? 'asc' : 'desc';
  };

  const onSortClick = () => {
    if (isTableFilterHidden || !sortable) return;

    const orderValue = getNextOrder();
    if (!isNested) {
      setSortDefault({
        sort: column?.colId,
        order: orderValue,
      });
    } else {
      setSortNested({
        sort: column?.colId,
        order: orderValue,
      });
    }
  };

  return {
    isSorted,
    order: columnOrder,
    onSortClick,
  };
}
