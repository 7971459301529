import cn from 'classnames';
import PropTypes from 'prop-types';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import PieChartPercent from '../PieChart/PieChartPercent';
import { PopoverV2 } from '../PopoverV2';
import { Skeleton } from '../Skeleton';

function toDivide(numb) {
  if (numb === undefined) {
    return '-';
  }
  var int = String(Math.trunc(numb));
  if (int.length <= 3) return int;
  var space = 0;
  var number = '';

  for (var i = int.length - 1; i >= 0; i--) {
    if (space === 3) {
      number = ' ' + number;
      space = 0;
    }
    number = int.charAt(i) + number;
    space++;
  }

  return number;
}

const ColoredBack = ({ color = 'gray', value, type, label, pie, percent, isLoading, tooltip }) => {
  const postfix = type === 'currency' ? '&nbsp;₽' : type === 'amount' ? '&nbsp;шт.' : '';
  if (value === 'undefined') return <></>;

  let percentValue = value === '-' ? 0 : value;
  let stringValue = value;

  if (Number.isInteger(+value)) {
    stringValue = `${toDivide(Number(stringValue))}${postfix}`;
    percentValue = Number(percentValue);
  }

  return (
    <div
      className={cn('rounded-lg px-4 py-3 h-full border', {
        ['!bg-green-200 border-green']: color === 'green',
        ['bg-[#FAFAFA] border-gray-200']: color === 'gray',
      })}
    >
      {percent ? (
        <div className="flex items-center divide-x divide-gray-200">
          <div className="flex-grow-0">
            {pie ? (
              <PieChartPercent percent={percentValue} isLoading={isLoading} />
            ) : (
              <>{isLoading ? <Skeleton /> : <b>{`${value}${postfix}`}%</b>}</>
            )}
          </div>
          <div
            className="text-gray-400 pl-3 ml-3 leading-tight"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="py-0.5 text-gray-400" dangerouslySetInnerHTML={{ __html: label }} />
            {tooltip && (
              <div className="relative group">
                <QuestionIcon className="h-4 w-4 cursor-pointer text-gray-300" />
                <PopoverV2
                  classes={`opacity-0 !-z-10000 group-hover:opacity-100 group-hover:!z-10000 transition-opacity top-6 -left-10`}
                >
                  <div
                    className="text-gray-400 font-SofiaPro text-sm font-medium whitespace-nowrap"
                    dangerouslySetInnerHTML={{ __html: tooltip }}
                  />
                </PopoverV2>
              </div>
            )}
          </div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              className={cn('py-0.5 text-base leading-none', {
                ['text-green']: color === 'green',
              })}
              dangerouslySetInnerHTML={{ __html: stringValue }}
            />
          )}
        </>
      )}
    </div>
  );
};
export const coloredBBackPropTypes = {
  color: PropTypes.oneOf(['green', 'gray']),
  label: PropTypes.string,
  tooltip: PropTypes.string,
  pie: PropTypes.bool,
  percent: PropTypes.bool,
  type: PropTypes.oneOf(['currency', 'amount']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
};
ColoredBack.propTypes = coloredBBackPropTypes;

export default ColoredBack;
