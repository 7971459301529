import PropTypes from 'prop-types';

import { formatNumber } from 'components/Tooltip/utils';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { DATE_FORMAT } from 'context/filter/filter.context.reducer.methods';
import { useFetchFilteredChartsData } from 'hooks/useFetchFilteredChartsData';
import moment from 'moment/moment';
import { CHART_TYPE, PAGE_BRANDS, PAGE_SELLERS } from '../../constants';

const CHART_DATE = [
  moment().subtract(7, 'days').format(DATE_FORMAT),
  moment().subtract(1, 'days').format(DATE_FORMAT),
];

const TableCellAmountWithPercent = (props) => {
  const { value = '', valueFormatted = '', data = {}, colDef, percentKey } = props;
  const isSellers = window.location.pathname.indexOf(PAGE_SELLERS) >= 0;
  const isBrands = window.location.pathname.indexOf(PAGE_BRANDS) >= 0;
  const isFrontCalcPercent =
    (isSellers || isBrands) && (colDef.field === 'orders_rub' || colDef.field === 'orders_qty');
  const { filter } = useFilterReducer();
  const { data: chartData } = useFetchFilteredChartsData({
    type: CHART_TYPE.ORDERS,
    date: filter.date || CHART_DATE,
  });

  const total = chartData?.[colDef.field];
  const getPercent = () => {
    const percent = isFrontCalcPercent
      ? (+value / +total) * 100
      : data[percentKey ? percentKey : `${colDef.field}_percent`];
    if (percent || percent === 0) {
      return `${formatNumber(percent)}%`;
    }

    return '';
  };

  return (
    <span className="flex justify-end">
      {valueFormatted || value}
      &nbsp;
      <span className="text-gray-400 w-[44px] text-right flex-none">{getPercent()}</span>
    </span>
  );
};

TableCellAmountWithPercent.propTypes = {
  data: PropTypes.object,
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }),
  percentKey: PropTypes.string,
};

export default TableCellAmountWithPercent;
