import QuestionIcon from 'assets/icons/QuestionIcon';
import { useAuth } from 'context/auth.context';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { BookmarkInsertIcon } from '../../assets/icons/BookmarkInsertIcon';
import { useMonitoringGroups } from '../../context/monitoring.groups.context';
import baseClient from '../../services/baseClient';
import ButtonPrimary from '../ButtonPrimary';
import Checkbox from '../Checkbox';
import Popover from '../Popover';
import InsertGroup from '../SeoCompare/InsertGroup';
import { MonitoringGroupCreator } from './MonitoringGroupCreator';

const MonitoringInsert = ({
  products,
  phrases,
  setProducts,
  setPhrases,
  handleMonitoring,
  isEntering,
  setIsEntering,
}) => {
  const { editGroup, monitoringGroups, setMonitoringGroups, setEditGroup } = useMonitoringGroups();
  const handleSubmit = () => handleMonitoring();
  const handleEditGroup = () => {
    const before = JSON.parse(JSON.stringify(monitoringGroups));
    const resultGroups = [...monitoringGroups];
    const index = monitoringGroups.findIndex((el) => el.id === editGroup.id);
    resultGroups[index] = { ...monitoringGroups[index], preset: { phrases, products, isEntering } };
    setMonitoringGroups(resultGroups);
    baseClient
      .updateCommonTemplate({
        id: editGroup.id,
        preset: {
          phrases: phrases.filter((el) => el),
          products: products.filter((el) => el),
          isEntering,
        },
      })
      .then((data) => {
        if (data.status !== 200) {
          setMonitoringGroups(before);
        } else {
          // create or update
        }
      });
    setEditGroup(undefined);
  };
  const { noTariff } = useAuth();
  const tooltipHelpRef = useRef();
  const tooltipNoTariffRef = useRef();
  return (
    <div className="pt-4 px-8">
      <div className="mb-4 flex space-x-4">
        <InsertGroup
          id={'group1'}
          list={products}
          title={'Добавьте товары для мониторинга'}
          setList={setProducts}
        />
        <InsertGroup
          id={'group2'}
          list={phrases}
          title={'Добавьте поисковые фразы для мониторинга'}
          setList={setPhrases}
          placeholder={'Поисковая фраза...'}
          external={
            <div className="flex p-4 pr-0">
              <div className="pr-4 h-full">
                <div className="w-px h-full bg-[#F0F0F0]" />
              </div>
              <div>
                <span className="block text-xs leading-[18px] text-[#858585] pb-4 border-b border-[#F0F0F0] mb-4">
                  Если не указывать поисковые <br />
                  фразы, то мониторинг будет
                  <br />
                  выполняться по всем поисковым
                  <br />
                  фразам, по которым встречался
                  <br />
                  товар
                </span>
                <div className="flex items-center">
                  <Checkbox
                    label="Точное совпадение"
                    onChange={() => setIsEntering(!isEntering)}
                    name="is-new"
                    checked={!isEntering}
                    className={'whitespace-nowrap'}
                  />
                  <div ref={tooltipHelpRef} className="ml-1.5 text-gray-300 relative top-[1px]">
                    <QuestionIcon width={15} height={15} />
                  </div>
                  <Popover
                    tooltipRef={tooltipHelpRef}
                    tooltip="Отобразятся поисковые фразы, которые полностью совпадают с указанными"
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="relative w-fit">
        <div ref={tooltipNoTariffRef} className="flex space-x-2 items-center">
          {editGroup ? (
            <ButtonPrimary
              onClick={handleEditGroup}
              label={
                <div className="flex space-x-2 items-center">
                  <BookmarkInsertIcon />
                  <span className="block">Сохранить изменения</span>
                </div>
              }
              className="text-base"
            />
          ) : (
            <ButtonPrimary
              onClick={handleSubmit}
              label="Получить отчёт"
              className="text-base"
              disabled={products.filter((el) => el).length === 0 || noTariff}
            />
          )}
          <MonitoringGroupCreator products={products} phrases={phrases} isEntering={isEntering} />
          {noTariff && (
            <Popover
              tooltipRef={tooltipNoTariffRef}
              className="whitespace-nowrap"
              tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
            />
          )}
        </div>
      </div>
    </div>
  );
};

MonitoringInsert.propTypes = {
  products: PropTypes.array,
  phrases: PropTypes.array,
  setProducts: PropTypes.func,
  setPhrases: PropTypes.func,
  handleMonitoring: PropTypes.func,
  isEntering: PropTypes.bool,
  setIsEntering: PropTypes.func,
  monitoringGroups: PropTypes.array,
  setMonitoringGroups: PropTypes.func,
  showModalAfterCreate: PropTypes.bool,
  setShowModalAfterCreate: PropTypes.func,
};

export default MonitoringInsert;
