import DownIcon from 'assets/icons/DownIcon';
import cn from 'classnames';
import Checkbox from 'components/Checkbox';
import Popover from 'components/Popover';
import PropTypes from 'prop-types';
import { useRef } from 'react';

const Node = (props) => {
  const {
    data,
    labelKey = 'title',
    valueKey = 'key',
    childrenKey = 'children',
    level,
    onCheck,
    onExpand,
    expandedKeys = [],
    checkable = true,
    checkboxRender,
    checkedKeys,
    selection = 'single',
    isParentChecked = false,
  } = props;
  const value = data[valueKey];
  const children = data[childrenKey];
  const tooltipRef = useRef();
  const isExpanded = expandedKeys.includes(value);
  const isChecked = isParentChecked || checkedKeys.includes(value);
  const isDisabled =
    selection === 'single' && checkedKeys.length !== 0 && !checkedKeys.includes(data[valueKey]);
  return (
    <div key={value} className="mb-2">
      <div className="flex">
        {children && (
          <button
            onClick={() => onExpand(data)}
            className={cn('mr-1.5', { 'rotate-180': isExpanded, 'opacity-50': isDisabled })}
          >
            <DownIcon height={14} width={14} />
          </button>
        )}
        {checkable && (!checkboxRender || checkboxRender({ ...data, level })) && (
          <div className="group relative flex self-center" ref={tooltipRef}>
            <Checkbox
              checked={isChecked}
              onChange={() => onCheck(data, isChecked)}
              disabled={isDisabled}
              className={cn('cursor-pointer', { 'ml-5': !children })}
              label=""
              name={`checkbox_${data[valueKey]}`}
            />
            {isDisabled && (
              <Popover
                className="opacity-100 bg-opacity-100 whitespace-nowrap"
                offset={[80, 10]}
                tooltip={'Можно выбрать только один пункт'}
                tooltipRef={tooltipRef}
              />
            )}
          </div>
        )}
        <span className={cn('relative top-0.5', { 'opacity-50': isDisabled })}>
          {data[labelKey]}
        </span>
      </div>
      {isExpanded && children && children.length !== 0 && (
        <div className="ml-4 mt-2">
          {children.map((el) => (
            <Node
              {...props}
              data={el}
              level={level + 1}
              key={el[valueKey]}
              isParentChecked={isChecked}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const TreeView = (props) => {
  const { data, level = 0, valueKey = 'key', className } = props;
  if (!data) {
    return null;
  }
  return (
    <div className={cn('px-5 md:py-3 py-1', className)}>
      {Array.isArray(data) ? (
        data.map((el) => <Node {...props} data={el} level={level} key={el[valueKey]} />)
      ) : (
        <Node {...props} level={level} />
      )}
    </div>
  );
};
const commonTypes = {
  data: PropTypes.any,
  level: PropTypes.number,
  className: PropTypes.string,
  expandedKeys: PropTypes.array,
  checkedKeys: PropTypes.array,
  onExpand: PropTypes.func,
  onCheck: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  childrenKey: PropTypes.string,
  checkable: PropTypes.bool,
  checkboxRender: PropTypes.func,
  selection: PropTypes.oneOf(['single', 'multiple']),
};

TreeView.propTypes = commonTypes;

Node.propTypes = {
  ...commonTypes,
  isParentChecked: PropTypes.bool,
};

export default TreeView;
