import cn from 'classnames';
import PropTypes from 'prop-types';
import ProductCard from './ProductCard';

const ProductGrid = ({ searchData, showFullInfo = true }) => {
  if (searchData.length === 0) return null;
  return (
    <div className="flex justify-center">
      <div
        className={cn(
          'sm:mt-[3.75rem] pb-8 lg:pb-5 grid gap-x-5 gap-y-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 max-w-[1200px]',
          {
            'lg:mt-2': !showFullInfo,
          },
        )}
      >
        {searchData.map((el) => (
          <ProductCard key={el.id} cardInfo={el} showFullInfo={showFullInfo} />
        ))}
      </div>
    </div>
  );
};
ProductGrid.propTypes = {
  searchData: PropTypes.array,
  showFullInfo: PropTypes.bool,
};
export default ProductGrid;
