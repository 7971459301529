import cn from 'classnames';
import PropTypes from 'prop-types';

import ExternalLinkIcon from 'assets/icons/ExternalLinkIcon';
import { isOzonPlatform } from 'context/filter/filter.context.reducer.methods';
import { useProduct } from 'context/product.context';
import { useMemo } from 'react';

export const ProductPlatformLink = ({ className, isInner }) => {
  const { productData, platformId } = useProduct();

  const platform = useMemo(
    () => (isOzonPlatform(platformId) ? 'Ozon' : 'Wildberries'),
    [platformId],
  );
  const resultLink = isInner ? productData?.link?.[0] : productData?.url;
  if (!resultLink || resultLink === '-') {
    return null;
  }
  return (
    <a
      className={cn(className, 'text-gray-400 flex items-center lg:ml-auto')}
      target="_blank"
      rel="noreferrer"
      href={resultLink}
    >
      Страница товара на {platform}
      <ExternalLinkIcon className="w-4 h-4 ml-2" />
    </a>
  );
};
ProductPlatformLink.propTypes = {
  className: PropTypes.string,
  isInner: PropTypes.bool,
};
