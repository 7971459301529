import cn from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ArrowRightIcon } from '../../assets/icons/ArrowRightIcon';
import { BookmarkCheckedIcon } from '../../assets/icons/BookmarkCheckedIcon';
import { useMonitoringGroups } from '../../context/monitoring.groups.context';
import Modal from '../Modal';
import { MonitoringGroupsMonitoringContent } from './MonitoringGroupsMonitoringContent';
import { MODES, MONITORING_GROUPS_MODAL_MODES } from './constants';

const MonitoringGroupsModal = ({ mode: monitoringMode }) => {
  const {
    monitoringGroups,
    setMonitoringGroups,
    showGroupsModal,
    setShowGroupsModal,
    // activeGroupId,
    // editGroup,
  } = useMonitoringGroups();
  const [modalSelected, setModalSelected] = useState();
  const [mode, setMode] = useState(MONITORING_GROUPS_MODAL_MODES.INFO);
  const searchParams = new URLSearchParams(document.location.search);
  const productsURL = searchParams.get('products');
  const phrasesURL = searchParams.get('phrases');
  const isEnteringURL = searchParams.get('isEntering');
  const filter = searchParams.get('filter');
  const currentGroup = monitoringGroups.find(
    ({ preset }) =>
      JSON.stringify(preset.products) ===
        JSON.stringify(productsURL ? productsURL.split(',') : []) &&
      JSON.stringify(preset.phrases) === JSON.stringify(phrasesURL ? phrasesURL.split(',') : []) &&
      preset.isEntering === (isEnteringURL === null ? true : isEnteringURL === 'true'),
  );

  useEffect(() => {
    if (currentGroup) {
      setModalSelected(currentGroup);
    } else {
      setModalSelected(undefined);
    }
  }, [currentGroup]);
  const handleSubmit = () => {
    setShowGroupsModal(false);
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
    const params = new URLSearchParams({
      ['products']: modalSelected.preset.products.filter((el) => el).join(','),
      ['phrases']: modalSelected.preset.phrases.filter((el) => el).join(','),
      ['isEntering']: modalSelected.preset.isEntering,
      filter,
    });
    location.search = params + '';
  };

  const handleClose = () => {
    setShowGroupsModal(false);
    setModalSelected(undefined);
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
  };

  return (
    <>
      <button
        className={cn(
          'h-fit flex items-center space-x-2 text-sm bg-gray-light border border-gray-250 rounded cursor-pointer py-1.5 px-2 leading-4 whitespace-nowrap transition-all hover:disabled:text-black hover:text-green disabled:opacity-30 disabled:bg-gray-light disabled:cursor-not-allowed',
          {
            ['!border-[#219653] !bg-[#F1FCF6] !text-green']:
              monitoringMode === MODES.RESULT && currentGroup,
          },
        )}
        onClick={() => setShowGroupsModal(true)}
      >
        <BookmarkCheckedIcon />
        <span className="block">
          {monitoringMode === MODES.RESULT && currentGroup ? currentGroup.name : 'Избранное'}
        </span>
        <ArrowRightIcon />
      </button>
      {showGroupsModal && (
        <Modal
          isOpen={showGroupsModal}
          title={'Избранное'}
          closeModal={handleClose}
          submitModal={handleSubmit}
          classNames="!p-0"
          headerClassNames="!px-5"
          width={1000}
        >
          <div className="flex min-h-[515px]">
            <div className="border-r border-[#F0F0F0]">
              <div className="overflow-y-auto overflow-x-hidden max-h-[515px]">
                <div className="py-4 px-5">
                  <span className="block text-[#CBCBCB] text-sm leading-[14px]">Группы</span>
                </div>
                {monitoringGroups.map((group) => {
                  const { id, name } = group;
                  const isSelected = id === modalSelected?.id;
                  return (
                    <button
                      key={id}
                      className={cn('flex py-4 px-5 border-r-2 lg:w-[200px] justify-between', {
                        'bg-green-100  border-green': isSelected,
                        'border-white': !isSelected,
                      })}
                      onClick={() => {
                        setModalSelected(group);
                      }}
                    >
                      <span
                        className={cn('text-gray-700 text-left', {
                          '!text-green': isSelected,
                        })}
                      >
                        {name}
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
            <MonitoringGroupsMonitoringContent
              mode={mode}
              setMode={setMode}
              modalSelected={modalSelected}
              setModalSelected={setModalSelected}
              monitoringGroups={monitoringGroups}
              setMonitoringGroups={setMonitoringGroups}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

MonitoringGroupsModal.propTypes = {
  mode: PropTypes.any,
  setMode: PropTypes.func,
};

export { MonitoringGroupsModal };
