import DownIcon from 'assets/icons/DownIcon';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

const BlockWithToggler = ({ label, toggleLabel = ['Свернуть', 'Развернуть'], children }) => {
  const [stateCollapsed, setStateCollapsed] = useState(false);
  function handleHeaderClick() {
    setStateCollapsed(!stateCollapsed);
  }
  return (
    <div className="bg-gray-100 px-3 py-2">
      <div className="border border-solid border-gray-200 rounded-xl bg-white">
        <button onClick={handleHeaderClick} className="w-full">
          <div className="flex w-full justify-between text-left px-4 py-2 lg:py-3 group cursor-pointer">
            <span className="block font-medium lg:font-bold text-lg">{label}</span>
            <div className="flex items-center min-w-max">
              <span className="hidden relative top-0.5 lg:block text-gray-400 group-hover:text-green">
                {toggleLabel[stateCollapsed ? 1 : 0]}
              </span>
              <DownIcon
                width={16}
                height={16}
                className={cn('ml-1.5 group-hover:text-green', {
                  'rotate-180': !stateCollapsed,
                })}
              />
            </div>
          </div>
        </button>
        {!stateCollapsed && (
          <div className="px-4">
            <div
              className={cn('py-3', {
                'border-t border-grey': !stateCollapsed,
              })}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BlockWithToggler.propTypes = {
  label: PropTypes.string.isRequired,
  toggleLabel: PropTypes.array,
  children: PropTypes.node,
};

export default BlockWithToggler;
