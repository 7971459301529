import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useTable } from 'context/table.context';
import { useTableTooltip } from 'context/table.tooltip.context';
import { FilterButton, SortButton } from './components';

import { TABLE_INNER_TYPE } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import useColumnSort from 'hooks/useColumnSort';

const DATE_SORTING_TYPES = [
  TABLE_INNER_TYPE.RATINGS,
  TABLE_INNER_TYPE.WEEKS,
  TABLE_INNER_TYPE.IN_DAYS_2,
  TABLE_INNER_TYPE.PRICES_TAB,
];

const TableHeaderCell = ({ displayName, column, setSort, sortable = false, ...rest }) => {
  const ref = useRef(null);
  const nameWithTooltip = ref.current;

  const { hideFilter, isTableNeedHideFilter, type } = useTable();
  const { setInfoTooltip, cleanTooltip } = useTableTooltip();
  const { isSorted, order, onSortClick } = useColumnSort({ column, sortable });

  const isTableFilterHidden = isTableNeedHideFilter(type);
  const isSortable = sortable && !isTableFilterHidden;

  const { filterText, filterRange } = useFilterReducer();

  const isFiltered = useMemo(() => {
    const isSearchFilter = !!filterText?.[column?.colDef?.field];
    const isRestrictsFilter = !!filterRange?.[column?.colDef?.field];

    return isRestrictsFilter || isSearchFilter;
  }, [filterRange, filterText, column?.colDef?.field]);

  // скрывать попап с фильтров после добавления изменений
  const toggleFiltered = useCallback(() => {
    hideFilter(column.colId);
  }, [column.colId, hideFilter]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    column.addEventListener('filterChanged', toggleFiltered);
    // eslint-disable-next-line react/prop-types
    return () => column.removeEventListener('filterChanged', toggleFiltered);
  }, [column, toggleFiltered]);

  const showBarIcon = !column?.colDef?.suppressMenu;
  const hideSortIcon = !sortable;
  // const showHeaderTooltip =
  //   column.colDef?.headerTooltip && column.colDef?.headerTooltip !== DIRTY_HOOK_TO_SHOW_TOOLTIP;
  const showHeaderTooltip = column.colDef?.headerTooltip;

  // показывать тултип с пояснением значения колонки
  useEffect(() => {
    if (!nameWithTooltip || !showHeaderTooltip) return;

    nameWithTooltip.addEventListener('mouseenter', setInfoTooltip);
    nameWithTooltip.addEventListener('mouseleave', cleanTooltip);

    return () => {
      nameWithTooltip.removeEventListener('mouseenter', setInfoTooltip);
      nameWithTooltip.removeEventListener('mouseleave', cleanTooltip);
    };
  }, [nameWithTooltip, setInfoTooltip, cleanTooltip, showHeaderTooltip]);

  // задавать сортировку aggrid аналогично сортировке в фильтре
  useEffect(() => {
    if (isSorted && type) {
      // TO DO
      // костыль для того чтобы заработала сортировка для inner/weeks
      // почему inner/weeks сломались мне не удалось понять
      // баг появляется на 2й попытке поменять сортировку у одной и той же колонки
      // сортировки sort на desc меняются местами
      // в других таблицах таблицах я такого бага не нашла
      setTimeout(
        () => {
          setSort(order);
        },
        DATE_SORTING_TYPES.includes(type) ? 200 : 0,
      );
    }
  }, [isSorted, order, setSort, column?.colDef?.field, type]);

  return (
    <div className="flex items-center flex-1 justify-between">
      <span
        ref={ref}
        className={cn('text-gray-900 font-bold py-3', {
          ['underline decoration-dashed underline-offset-4 decoration-gray-300 hover:decoration-green']:
            showHeaderTooltip,
        })}
      >
        {displayName}
      </span>

      {showBarIcon && (
        <div className="flex items-center absolute right-0 px-1">
          {/*подложка, которая перекрывает заголовок, если колонка уже заголовка*/}
          <span className="absolute inset-y-0 -left-1 right-0 bg-gray-gradient" />
          {!hideSortIcon && (
            <SortButton
              {...rest}
              isSorted={isSorted}
              isSortable={isSortable}
              onSortClick={onSortClick}
              columnOrder={order}
            />
          )}
          <FilterButton {...rest} isFiltered={isFiltered} columnOrder={order} />
        </div>
      )}
    </div>
  );
};

TableHeaderCell.propTypes = {
  displayName: PropTypes.string.isRequired,
  column: PropTypes.shape({
    colId: PropTypes.oneOfType([PropTypes.string || PropTypes.number]),
    colDef: PropTypes.shape({
      headerTooltip: PropTypes.string,
      suppressMenu: PropTypes.bool,
      field: PropTypes.string,
      hideFilter: PropTypes.bool,
    }),
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
  }).isRequired,
  sortable: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
};
export default TableHeaderCell;
