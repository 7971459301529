import { ComparisonProvider } from '../../context/comparison.context';
import ComparisonLayout from './ComparisonLayout';
import ComparisonPage from './ComparisonPage';

const Comparison = () => {
  return (
    <ComparisonProvider>
      <ComparisonLayout>
        <ComparisonPage />
      </ComparisonLayout>
    </ComparisonProvider>
  );
};

export default Comparison;
