import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';
import PropTypes from 'prop-types';

const AddSmthButton = ({ onClick, label }) => {
  return (
    <button
      onClick={onClick}
      className="flex-1 lg:flex-auto flex focus:outline-current items-center p-1 text-green hover:text-gray-900 focus:text-gray-900 transition-all"
    >
      <PlusIcon className="w-[14px] h-[14px]" />
      <span className="ml-[6px]">{label}</span>
    </button>
  );
};
AddSmthButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
export default AddSmthButton;
