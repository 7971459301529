import Modal from 'components/Modal';
import { TABLE_EXPORT_ITEMS_LIMIT, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants';
import { useTable } from 'context/table.context';
import { PER_PAGE } from 'helpers/generateGridConfig';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableExportContent from './TableExportContent';

const TableExportContentModal = (props) => {
  const { onHide, width, isOpened = false, ...otherProps } = props || {};
  const [fileUrl, setFileUrl] = useState(null);
  const { totalPages } = useTable();

  const totalItems = totalPages * PER_PAGE;

  const handleGetFile = () => {
    if (fileUrl) {
      window.open(fileUrl);
    }
  };

  const handleOnHide = () => {
    setFileUrl(null);
    onHide();
  };
  return (
    <Modal
      width={width}
      title={!fileUrl ? 'Создать отчет' : 'Отчет готов!'}
      isOpen={isOpened}
      closeModal={handleOnHide}
      submitButtonText={'Отлично, скачать!'}
      submitModal={handleGetFile}
      cancelButtonText={'Уже не нужен'}
      footer={!!fileUrl}
    >
      <TableExportContent
        {...otherProps}
        onHide={handleOnHide}
        setFileUrl={setFileUrl}
        fileUrl={fileUrl}
        isLimitReached={totalItems > TABLE_EXPORT_ITEMS_LIMIT}
      />
    </Modal>
  );
};

TableExportContentModal.propTypes = {
  format: PropTypes.oneOf(['xlsx', 'csv']),
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
  onHide: PropTypes.func,
  width: PropTypes.number,
  isOpened: PropTypes.bool,
};

export default TableExportContentModal;
