import PropTypes from 'prop-types';

import LinkIcon from 'assets/icons/LinkIcon';

const TableCellPlatformLink = ({ value = '', valueFormatted = '' }) => {
  if (!value && !valueFormatted) return '';
  return (
    <a
      href={`https://www.wildberries.ru/catalog/${value}/detail.aspx?targetUrl=XS`}
      rel="noreferrer"
      target="_blank"
      className={
        'relative -top-[3px] cursor-pointer border border-[#FBF2FA] hover:border-wb bg-[#FBF2FA] hover:bg-wb rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle transition-all text-wb hover:text-white'
      }
    >
      <span className="inline-block align-middle">WB</span>
      <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
    </a>
  );
};

TableCellPlatformLink.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellPlatformLink;
