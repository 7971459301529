import cn from 'classnames';
import PropTypes from 'prop-types';

const TableMonitoringPhrasesTableCell = (props) => {
  const { data, mainField, isNoMonitoring } = props;
  const sortPosition = isNoMonitoring ? data[mainField] : data[`${mainField}_sort_position`];
  const diff = isNoMonitoring
    ? data[mainField.split('_').join('_diff_')] || '-'
    : data[`${mainField}_diff`];
  return (
    <div className="flex justify-end">
      {diff !== '-' && (
        <span
          className={cn('mr-2.5', {
            'text-[#5D9D52]': diff > 0,
            'text-[#D4483E]': diff < 0,
            '!text-gray-300': diff === 0,
          })}
        >
          {diff === 0 ? diff : diff < 0 ? `↓ ${Math.abs(diff)}` : `↑ ${diff}`}
        </span>
      )}
      {sortPosition}
    </div>
  );
};

TableMonitoringPhrasesTableCell.propTypes = {
  data: PropTypes.any,
  mainField: PropTypes.any,
  isNoMonitoring: PropTypes.any,
};

export default TableMonitoringPhrasesTableCell;
