import TabletIcon from 'assets/icons/TabletIcon';

const Error404 = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="bg-gray-100 border border-l-0 border-r-0 border-gray-200 flex-grow flex items-center justify-center">
        <div className="text-gray-500 text-center space-y-10">
          <div className="flex flex-col items-center space-y-2">
            <TabletIcon className="h-10 w-10 m-auto" />
            <div>
              Страница не найдена. <br /> Попробуйте перейти в один из разделов навигации
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
