import classNames from 'classnames';
import PropTypes from 'prop-types';

const TableListingWithPositionCell = (props) => {
  const { value } = props || {};

  //  'bg-orange-100': (params) => params.value?.promotion_type?.id === '1',
  //  'bg-pink-100': (params) => params.value?.promotion_type?.id === '2',
  let label = '';
  const { listing, sort_position, new_position, old_position, cpm, promotion_type } = value || {};
  let hasPromotion = false;
  if (!value) {
    label = '-';
  } else if (value === ' ') {
    label = '';
  } else if (typeof value === 'number') {
    label = value;
  } else {
    hasPromotion = promotion_type?.id !== null;

    label = listing || sort_position;
    if (new_position === null) {
      label = listing || '-';
    } else if (old_position === null) {
      label = sort_position || '-';
    }
  }
  return (
    <div
      className={classNames('flex justify-around items-center h-auto my-2 pl-2', {
        'border-l-2 border-[#E2A339]': hasPromotion && promotion_type?.id == 1,
        'border-l-2 border-[#ED5B73]': hasPromotion && promotion_type?.id == 2,
        // 'border-l-2 border-[#4D73FB]': 1 == 1 || (hasPromotion && promotion_type?.id === 2),
      })}
    >
      <div className="text-gray-400 text-xs text-right" style={{ width: 40 }}>
        {hasPromotion && (
          <>
            {old_position} <span className="font-light">→</span>
          </>
        )}
      </div>

      <div className={classNames('px-2 text-sm font-bold text-right')} style={{ width: 60 }}>
        {label}
      </div>

      <div className="text-gray-400 text-xs text-right" style={{ width: 40 }}>
        {' '}
        {hasPromotion && <>{cpm} ₽</>}
      </div>
    </div>
  );
};

TableListingWithPositionCell.propTypes = {
  data: PropTypes.any,
  mainField: PropTypes.any,
  isSearch: PropTypes.bool,
};

export default TableListingWithPositionCell;
