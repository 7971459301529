import classNames from 'classnames';
import PropTypes from 'prop-types';

import LinkIcon from 'assets/icons/LinkIcon';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { isOzonPlatform, isWbPlatform } from 'context/filter/filter.context.reducer.methods';

const LABELS = {
  1: 'OZ',
  2: 'WB',
};

const TableCellPlatformLink = ({ value = '', valueFormatted = '' }) => {
  const { filter } = useFilterReducer();
  const label = LABELS[filter.platform];
  const isOzon = isOzonPlatform(filter.platform);
  const isWb = isWbPlatform(filter.platform);

  if (!value && !valueFormatted) return '';
  if (!label) return value;

  const cellValue = valueFormatted ? valueFormatted : value;
  if (!cellValue || cellValue === '-') {
    return null;
  }
  return (
    <a
      href={cellValue}
      rel="noreferrer"
      target="_blank"
      className={classNames(
        'cursor-pointer border border-gray-250 rounded-sm text-xs leading-none px-1 py-0.5 inline-block align-middle transition-all hover:text-white',
        {
          ['text-ozon hover:border-ozon hover:bg-ozon']: isOzon,
          ['text-wb hover:border-wb hover:bg-wb']: isWb,
        },
      )}
    >
      <span className="inline-block align-middle pt-0.5">{label}</span>
      <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
    </a>
  );
};

TableCellPlatformLink.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellPlatformLink;
