import NavList from 'components/NavList';
import { BRAND, CATEGORY, SELLER } from 'constants/navColors';
import {
  PAGE_GROWTH,
  PAGE_GROWTH_BRANDS,
  PAGE_GROWTH_PRODUCTS,
  PAGE_GROWTH_SELLERS,
} from 'constants/pages';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';

const TableNavGrowth = () => {
  const { filter, updateChips } = useFilterReducer();

  const { isSellersAvailable } = useUserTariffs();

  const handleClean = (key) => {
    updateChips({ [key]: [] });
  };

  return (
    <NavList
      items={[
        {
          link: PAGE_GROWTH,
          title: 'Категории',
          label: {
            value: filter?.category?.length + filter?.promoCategory?.length || 0,
            bg: CATEGORY,
            onClick: () => {
              handleClean('category');
              handleClean('promoCategory');
            },
          },
        },
        {
          link: PAGE_GROWTH_SELLERS,
          title: 'Продавцы',
          isAvailable: isSellersAvailable,
          label: {
            value: filter?.seller?.length || 0,
            bg: SELLER,
            onClick: () => handleClean('seller'),
          },
        },
        {
          link: PAGE_GROWTH_BRANDS,
          title: 'Бренды',
          label: {
            value: filter?.brand?.length || 0,
            bg: BRAND,
            onClick: () => handleClean('brand'),
          },
        },
        {
          link: PAGE_GROWTH_PRODUCTS,
          title: 'Товары',
        },
      ]}
    />
  );
};

export default TableNavGrowth;
