const COLORS_LABELS = [
  { color: 'border-[#E2A339]', label: '- товар показывается в Авторекламе' },
  { color: 'border-[#ED5B73]', label: ' - товар показывается в рекламе типа Аукцион' },
  {
    color: 'border-[#4D73FB]',
    label: ' - товар показывается по двум типам рекламы (АРК и Аукцион)',
  },
];
const ProductListingLegend = () => {
  return (
    <div className="flex space-x-10 ml-5">
      {COLORS_LABELS.map((info) => {
        const { color, label } = info;
        return (
          <div className="flex items-center space-x-2" key={label}>
            <span className={'h-5 border-l-2 ' + color}></span>
            <span className="text-xs">{label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProductListingLegend;
