import { IconStarAdded, IconStarRemoved } from 'components/IconStar';
import { FILTER_TYPE, LIST_DATA, LIST_FETCH_TYPE, LIST_TYPE } from 'constants/list';
import { useAuth } from 'context/auth.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import {
  addFavouriteMethod,
  clearListMethod,
  createListMethod,
  deleteListMethod,
  removeFavouriteMethod,
  renameListMethod,
} from 'context/user.list.context.methods';
import { useFetchListItems } from 'hooks/useFetchListItems';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const UserListContext = React.createContext();
UserListContext.displayName = 'User.List.Context';

const UserListProvider = (props) => {
  const [whatListIsLoading, setWhatIsListLoading] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [activeList, setActiveList] = useState(null);
  const [activeListType, setActiveListType] = useState(LIST_TYPE.BY_CATEGORIES);
  const [selectedList, setSelectedList] = useState();
  const [optimisticRemovedIds, setOptimisticRemovedIds] = useState(null);
  const [whitelist, setWhitelist] = useState([]);
  const [itemsToAnalyze, setItemsToAnalyze] = useState();
  const { user } = useAuth();

  const { filter, setFilterByUserList } = useFilterReducer();

  const {
    data: lists,
    isLoading: isListLoading,
    isFetching: isListFetching,
    isFetched,
    refetch,
  } = useFetchListItems({
    type: LIST_FETCH_TYPE.ALL_TYPES,
    enabled: !!user,
    onSuccess: () => setWhatIsListLoading(null),
  });

  const addAlertByKey = ({ key, message, icon }) => {
    setAlerts((prev) => [...prev, { key, message, icon, type: 'success' }]);
  };

  const removeAlertByKey = (key) => {
    setAlerts((prev) => prev.filter((error) => error.key !== key));
  };

  const getListByType = useCallback(
    (by) => {
      if (!lists) return [];

      return lists.filter(({ type }) => type === by);
    },
    [lists],
  );

  const getListById = useCallback(
    (by) => {
      if (!lists) return [];

      return lists.find(({ id }) => id === +by);
    },
    [lists],
  );

  const handleListActive = useCallback((id) => {
    setActiveList(id);
  }, []);

  useEffect(() => {
    if (itemsToAnalyze) {
      const { type, items } = itemsToAnalyze;
      const lists = getListByType(type);
      lists.forEach((list) => {
        const { id, items: itemsList = [] } = list;
        const hasSameItems =
          Array.isArray(itemsList) && itemsList.length > 0 && itemsList.length === items.length
            ? itemsList.every(({ id }) => items.includes(id))
            : false;
        if (hasSameItems) {
          setActiveList(id);
          setSelectedList(list);
          return false;
        }
      });
    }
  }, [itemsToAnalyze, lists, getListByType]);

  const activeListData = useMemo(() => {
    if (!lists || !activeList) return null;

    return lists.filter(({ id }) => id === activeList)[0];
  }, [lists, activeList]);

  const listsByActiveType = useMemo(() => {
    if (activeListType === null) return [];

    let lists = getListByType(activeListType);

    // final filter
    if (optimisticRemovedIds && Array.isArray(optimisticRemovedIds)) {
      return lists.map((listByActiveType) => {
        const { id, items = [] } = listByActiveType || {};
        if (id === selectedList?.id) {
          // if this is our selected item
          return {
            ...listByActiveType,
            items: items.filter(({ id }) => !optimisticRemovedIds.includes(id)),
          };
        }
        return listByActiveType;
      });
    }

    return lists;
  }, [getListByType, activeListType, optimisticRemovedIds, selectedList]);

  const getListIndex = useCallback(
    (id) => {
      if (!id) return null;

      for (let i = 0; i < listsByActiveType.length; i++) {
        if (id === listsByActiveType[i]?.id) {
          return i;
        }
      }

      return null;
    },
    [listsByActiveType],
  );

  const isListDisabledForRenameAndDelete = (id) => {
    const index = getListIndex(id);

    return index === 0;
  };

  const createList = async (name) => {
    setWhatIsListLoading(name);

    return createListMethod({ name, type: activeListType }).then((data) => {
      refetch();

      return data;
    });
  };

  const renameList = async ({ id, name }) => {
    setWhatIsListLoading(id);

    return renameListMethod({ id, name }).then(refetch);
  };

  const deleteList = async (id) => {
    setWhatIsListLoading(id);

    return deleteListMethod(id).then(() => {
      refetch();
      setActiveList(null);
      setFilterByUserList(null);
    });
  };

  const clearList = async (id) => {
    setWhatIsListLoading(id);

    return clearListMethod(id).then(refetch);
  };

  const countListsByType = useCallback(
    (by) => {
      if (!lists) return [];

      const currentLists = getListByType(by);

      return currentLists.length || 0;
    },
    [lists, getListByType],
  );

  const addFavourite = async ({ listId, id, type, callback }) => {
    if (Array.isArray(id)) {
      const promises = id.map((id) =>
        addFavouriteMethod({ listId, id, platform_id: filter.platform }),
      );

      await Promise.all(promises);
      await refetch();
      callback();
    } else {
      return addFavouriteMethod({ listId, id, platform_id: filter.platform }).then(() => {
        refetch().then(callback);

        const messageStart = LIST_DATA[type].alertElement.add;
        const listName = getListById(listId);

        addAlertByKey({
          key: id,
          message: `${messageStart} «${listName?.name || ''}»`,
          icon: <IconStarAdded />,
        });
      });
    }
  };

  const removeFavourite = async ({ listId, id, type, callback }) => {
    return removeFavouriteMethod({ listId, id, platform_id: filter.platform }).then(() => {
      refetch().then(callback);

      const messageStart = LIST_DATA[type].alertElement.remove;
      const listName = getListById(listId);
      // optimistic removal
      setOptimisticRemovedIds([id]);

      addAlertByKey({
        key: id,
        message: `${messageStart} «${listName?.name || ''}»`,
        icon: <IconStarRemoved />,
      });
    });
  };

  const handleActivationListType = useCallback((type) => {
    if (
      !Object.values(LIST_DATA)
        .map((list) => list.id)
        .includes(type)
    )
      return;

    setActiveListType(type);
  }, []);

  const anylizeItemsOnList = useCallback(
    (type, items) => {
      setItemsToAnalyze({ type, items });
    },
    [setItemsToAnalyze],
  );

  // если нет какого-то типа списка - надо создать дефолтный
  const checkListTypesExistence = useCallback(() => {
    if (!isFetched) return;

    const types = Object.values(LIST_TYPE);

    for (let i = 0; i < types.length; i++) {
      const type = types[i];
      const list = getListByType(type);

      if (!list.length) {
        createListMethod({ name: `Избранное ${LIST_DATA[type].label.toLowerCase()}`, type }).then(
          () => refetch(),
        );
      }
    }
  }, [isFetched, getListByType, refetch]);

  const isActiveListEqualToFilter = useMemo(() => {
    if (!activeList) return false;

    const listItems = activeListData?.items;

    if (!listItems || !listItems.length) return false;

    const filterParamsToCheck = Object.values(FILTER_TYPE);

    for (let i = 0; i < filterParamsToCheck.length; i++) {
      const param = filterParamsToCheck[i];
      const filterItems = filter[param];

      if (FILTER_TYPE[activeListData.type] === param) {
        if (filter[param].length !== listItems.length) {
          return false;
        }
        for (let j = 0; j < listItems.length; j++) {
          if (!filterItems.includes(listItems[j])) {
            return false;
          }
        }
      } else {
        if (filter[param].length !== 0) {
          return false;
        }
      }
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    checkListTypesExistence();
  }, [checkListTypesExistence]);

  useEffect(() => {
    if (!activeList) return;

    setFilterByUserList(activeListData);
  }, [activeList, activeListData, setFilterByUserList]);

  useEffect(() => {
    if (!isActiveListEqualToFilter) {
      setActiveList(null);
    }
  }, [isActiveListEqualToFilter]);

  const value = {
    alerts,
    removeAlert: removeAlertByKey,
    getListByType,
    getListById,
    activeList,
    activeListData,
    handleListActive,
    isListsFetched: isFetched,
    isListsLoading: isListLoading || isListFetching,
    whatListIsLoading,
    clearList,
    createList,
    renameList,
    deleteList,
    addFavourite,
    removeFavourite,
    setOptimisticRemovedIds,
    countListsByType,
    isListDisabledForRenameAndDelete,
    listsByActiveType,
    activeListType,
    handleActivationListType,
    selectedList,
    setSelectedList,
    whitelist,
    setWhitelist,
    anylizeItemsOnList,
  };

  return <UserListContext.Provider value={value} {...props} />;
};

const useUserList = () => {
  return React.useContext(UserListContext);
};

export { UserListProvider, useUserList };
