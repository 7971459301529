import PropTypes from 'prop-types';

import { TableCellSelectAvgPosition, TableCellSelectVisibility } from 'components/TableCellSelect';
import { CELL_SELECT_AVG_POSITION, CELL_SELECT_VISIBILITY } from 'constants/table';

const TableCellWithSelect = ({ value = '', valueFormatted = '' }) => {
  let cellValue = valueFormatted ? valueFormatted : value;

  const isSelectVisibility =
    typeof cellValue === 'string' && cellValue?.indexOf(CELL_SELECT_VISIBILITY) >= 0;
  const isSelectAvgPosition =
    typeof cellValue === 'string' && cellValue?.indexOf(CELL_SELECT_AVG_POSITION) >= 0;

  if (isSelectVisibility) {
    cellValue = cellValue.replace(CELL_SELECT_VISIBILITY, '');
  }

  if (isSelectAvgPosition) {
    cellValue = cellValue.replace(CELL_SELECT_AVG_POSITION, '');
  }

  return (
    <span>
      <span dangerouslySetInnerHTML={{ __html: cellValue }} />
      {isSelectVisibility && <TableCellSelectVisibility />}
      {isSelectAvgPosition && <TableCellSelectAvgPosition />}
    </span>
  );
};

TableCellWithSelect.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellWithSelect;
