import cn from 'classnames';
import PropTypes from 'prop-types';
import EditIcon from '../../assets/icons/EditIcon';
import { GoToIcon } from '../../assets/icons/GoToIcon';
import TrashIcon from '../../assets/icons/TrashIcon';
import { useMonitoringGroups } from '../../context/monitoring.groups.context';
import Chip from '../Chip';
import { MODES, MONITORING_GROUPS_MODAL_MODES } from './constants';

const GroupInfo = ({ modalSelected, setMode, setModalSelected }) => {
  const { setEditGroup, setShowGroupsModal, setMonitoringMode } = useMonitoringGroups();
  const commonSettingsButtonsClasses =
    'flex items-center pt-4 lg:pt-0 mb-6.5 py-[1px] group grow justify-start lg:justify-center pb-4 lg:pb-0 border-b border-[#E8E8E8] lg:border-b-0';
  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full grow">
        <div className="overflow-y-auto px-5 w-1/2 border-r border-[#F0F0F0] h-full">
          <div className="py-4">
            <span className="block text-[#CBCBCB] text-sm leading-[14px]">Товары</span>
          </div>
          <div className="overflow-y-auto overflow-x-hidden max-h-[515px] w-full">
            {modalSelected?.preset?.products?.map((el) => (
              <div className="flex h-[46px] py-[7px] items-center" key={el}>
                <span className="block my-[5px] text-[#333D37] text-sm leading-[19px] whitespace-nowrap text-ellipsis overflow-hidden text-center">
                  {el}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="overflow-y-auto px-5 w-1/2">
          <div className="py-4">
            <span className="block text-[#CBCBCB] text-sm leading-[14px]">Ключевые слова</span>
          </div>
          <div className="w-full flex flex-wrap -ml-2">
            {modalSelected?.preset?.phrases?.map((el) => (
              <Chip key={el} title={el} color="bg-blue" className="w-min ml-2 mt-2" />
            ))}
          </div>
        </div>
      </div>
      {modalSelected && (
        <div className="flex px-5 py-4 border-t border-[#F0F0F0] justify-between">
          <div className="flex items-center space-x-4">
            <button
              className={cn('text-[#EB392D]', {
                [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
              })}
              onClick={() => setMode(MONITORING_GROUPS_MODAL_MODES.RENAME)}
            >
              <EditIcon
                width={12}
                height={12}
                className={'text-[#C4C4C4] group-hover:text-green'}
              />
              <span className="ml-2 text-gray-700 group-hover:text-green">Переименовать</span>
            </button>
            <div className="w-px h-[22px] bg-[#F0F0F0]" />
            <button
              className={cn('text-[#EB392D]', {
                [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
              })}
              onClick={() => setMode(MONITORING_GROUPS_MODAL_MODES.DELETE)}
            >
              <TrashIcon width={12} height={12} className={'group-hover:text-green'} />
              <span className="ml-2 group-hover:text-green whitespace-nowrap">Удалить список</span>
            </button>
          </div>
          <button
            className={cn('flex text-[#C4C4C4] !grow-0 items-center', {
              [commonSettingsButtonsClasses]: commonSettingsButtonsClasses,
            })}
            onClick={() => {
              setEditGroup(modalSelected);
              setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
              setMonitoringMode(MODES.INSERT);

              setModalSelected(undefined);
              setShowGroupsModal(false);
            }}
          >
            <span className="mr-2 text-gray-700 group-hover:text-green">
              Редактировать товары и слова
            </span>
            <GoToIcon className={'group-hover:text-green'} />
          </button>
        </div>
      )}
    </div>
  );
};

GroupInfo.propTypes = {
  modalSelected: PropTypes.any,
  setMode: PropTypes.func,
  setModalSelected: PropTypes.func,
};

export { GroupInfo };
