import PropTypes from 'prop-types';
import { lazy } from 'react';

import ButtonBack from 'components/ButtonBack';
import { ProductPlatform } from 'components/ProductHeader/components';
import { useProduct } from 'context/product.context';
import { useUI } from 'context/ui.context';
import { useProductsLink } from 'hooks/useLocationPath';
import { isInnerPage } from 'utils/pages';
import { CalendarButton } from '../Filter';
// import moment from 'moment/moment';
// import { useFilterReducer } from 'context/filter/filter.context.reducer';

const TableHeaderDateControls = lazy(() => import('components/TableHeaderDateControls'));
const WarehouseSelect = lazy(() => import('components/Filter/components'));

// const DATE_FORMAT = 'YYYY-MM-DD';

const ProductFilter = ({ isSticky }) => {
  // const { setDate } = useFilterReducer();
  const { isMobile } = useUI();
  const { productData } = useProduct();
  const isInner = isInnerPage();
  const backTo = useProductsLink(isInner);
  const handleCallback = () => {
    // const endDate = moment().subtract(1, 'days');
    // const startDate = moment().subtract(14, 'days');
    // setDate([startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)]);
  };
  return (
    <div className="px-3 lg:px-4">
      <div className="flex items-center divide-x divider-gray-230">
        <div className="self-start mt-4 mb-6">
          <ButtonBack backTo={backTo} callBack={!isInner && handleCallback} />
        </div>

        {/*<ProductPlatformLink className="ml-3 lg:ml-0 pl-3 pt-1 lg:hidden" />*/}

        {isSticky && (
          <div className="hide lg:flex pl-3 my-2 flex-1 items-center space-x-3">
            <ProductPlatform />
            <h3 className="text-lg mb-0 flex-1 relative flex items-center leading-none">
              <span className="absolute w-full truncate pr-3">
                {productData?.name} <b className="pl-1">{productData?.price?.toLocaleString()} ₽</b>
              </span>
            </h3>
          </div>
        )}
        <div className="space-x-2 lg:space-x-3 ml-auto flex">
          {!isInner && <WarehouseSelect />}
          {isMobile ? <CalendarButton hideBorderRight /> : <TableHeaderDateControls />}
        </div>
      </div>
    </div>
  );
};
ProductFilter.propTypes = {
  isSticky: PropTypes.bool,
};
export default ProductFilter;
