import { LIST_TYPE } from 'constants/list';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserList } from 'context/user.list.context';
import React, { useEffect, useMemo, useState } from 'react';

const ComparisonContext = React.createContext();

const ComparisonProvider = (props) => {
  const {
    activeListData,
    handleListActive,
    anylizeItemsOnList,
    addFavourite,
    handleActivationListType,
  } = useUserList();
  const { setProduct: setProductFilter, filter } = useFilterReducer();
  const productsFromPath = useMemo(() => filter?.product || [], [filter]);
  const [products, setProducts] = useState(productsFromPath);
  const hasProducts = products.length > 0;

  useEffect(() => {
    setProducts(productsFromPath);
    handleActivationListType(LIST_TYPE.BY_PRODUCTS);
    if (productsFromPath.length > 0) anylizeItemsOnList(LIST_TYPE.BY_PRODUCTS, productsFromPath);
  }, [productsFromPath, anylizeItemsOnList, handleActivationListType]);

  useEffect(() => {
    const { items = [] } = activeListData || {};
    setProductFilter(items?.map(({ id }) => id));
  }, [activeListData, setProductFilter]);

  const resetComparison = () => {
    updateProducts([]);
    setProductFilter([]);
    handleListActive(null);
  };

  const updateProducts = (products) => {
    setProducts(products);
    if (!activeListData) {
      setProductFilter(products);
    }
  };

  const updateProductsFromSearch = (products) => {
    const callback = () => {
      updateProducts(products);
    };
    // if we have active list, add this product to the list
    if (activeListData) {
      addFavourite({
        listId: activeListData.id,
        id: products,
        type: LIST_TYPE.BY_PRODUCTS,
        callback,
      });
    } else {
      callback();
    }
  };

  const value = {
    products,
    hasProducts,
    setProducts,
    resetComparison,
    updateProducts,
    updateProductsFromSearch,
    // activeList,
  };

  return <ComparisonContext.Provider value={value} {...props} />;
};

const useComparisonContext = () => {
  return React.useContext(ComparisonContext);
};

export { ComparisonProvider, useComparisonContext };
