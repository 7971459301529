import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonItem = ({ onClick, first = false, last = false, title, isActive }) => (
  <li
    className={cn(`button-group__item min-w-[6.25rem] p-0`, {
      'button-group__item--active': isActive,
      'button-group__item--first': first,
      'button-group__item--last': last,
    })}
  >
    <button className="w-full !py-2 !px-3" onClick={onClick}>
      {title}
    </button>
  </li>
);
ButtonItem.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const ButtonGroup = ({ children }) => {
  return (
    <nav className="my-4">
      <ul className="button-group">
        {React.Children.map(children, (child, i) => (
          <>
            {React.isValidElement(child) &&
              React.cloneElement(child, { first: i === 0, last: i === children.length - 1 })}
          </>
        ))}
      </ul>
    </nav>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(ButtonItem), ButtonItem]),
};
export { ButtonGroup, ButtonItem };
