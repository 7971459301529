import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as Alfa } from 'assets/images/alfa.svg';
import { ReactComponent as Mc } from 'assets/images/mc.svg';
import { ReactComponent as Mir } from 'assets/images/mir.svg';
import { ReactComponent as Qiwi } from 'assets/images/qiwi.svg';
import { ReactComponent as Sber } from 'assets/images/sber.svg';
import { ReactComponent as Tinkoff } from 'assets/images/Tinkoff.svg';
import { ReactComponent as UKassa } from 'assets/images/u-kassa.svg';
import { ReactComponent as Visa } from 'assets/images/Visa.svg';
import { ReactComponent as WM } from 'assets/images/WM.svg';
import { usePayment } from 'context/payment.context';
import { declOfNum } from 'utils/client';

export const Input = ({
  label,
  id,
  className = 'mb-3',
  classNameInput = 'py-2 px-3',
  onChange,
  value,
  type = 'text',
  placeholder,
  isInvalid,
}) => (
  <div className={className}>
    {label ? (
      <label htmlFor={id} className="block font-bold mb-2">
        {label}
      </label>
    ) : (
      <></>
    )}
    <input
      className={cn(
        classNameInput,
        'w-full appearance-none border border-gray-300 rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green',
        { '!border-red': isInvalid },
      )}
      id={id}
      onChange={onChange}
      value={value}
      type={type}
      placeholder={placeholder}
    />
  </div>
);

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
};

const businessOptionPropTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

const BusinessTypeOption = ({ checked, onChange, id, label }) => (
  <div
    className={cn('cursor-pointer', {
      'text-green border-b border-green': checked,
    })}
  >
    <input type="radio" className="appearance-none" checked={checked} id={id} onChange={onChange} />
    <label htmlFor={id}>{label}</label>
  </div>
);

BusinessTypeOption.propTypes = businessOptionPropTypes;

export const BusinessFields = ({ businessTypeOptions }) => (
  <div className="flex space-x-5 my-5">
    {businessTypeOptions.map((option) => (
      <BusinessTypeOption key={option.id} {...option} />
    ))}
  </div>
);

BusinessFields.propTypes = {
  businessTypeOptions: PropTypes.arrayOf(PropTypes.shape(businessOptionPropTypes)),
};

export const Banks = () => (
  <div className="max-w-[300px]">
    <div className="flex items-center justify-between">
      <Mc />
      <Visa className="w-8 h-8" />
      <Alfa />
      <Qiwi className="w-[67px] h-[27px]" />
    </div>
    <div className="flex items-center justify-between">
      <UKassa />
      <WM />
      <Mir />
    </div>
    <div className="flex items-center justify-between">
      <Sber />
      <Tinkoff />
    </div>
  </div>
);

export const DiscountRows = () => {
  const {
    discount: { days, percent, rub },
    promoCode,
  } = usePayment();

  if (!days && !percent && !rub) return null;

  return (
    <div className="flex flex-col text-gray-500 gap-1">
      {percent !== null && rub !== null && promoCode && (
        <div className="flex justify-between">
          <span className="break-words max-w-[80%]">
            Промокод {promoCode}, -{percent}%
          </span>
          <span>-{rub} ₽</span>
        </div>
      )}

      {days && (
        <div className="flex justify-between">
          <span>
            Промокод на{' '}
            <strong className="text-gray-900">
              {days} бесплатных {declOfNum(days, ['день', 'дня', 'дней'])}
            </strong>{' '}
            🎉
          </span>{' '}
          <span>-</span>
        </div>
      )}
    </div>
  );
};

export const DropDiscount = () => {
  const { dropPromoCode } = usePayment();
  const handleDropPromoCode = () => {
    dropPromoCode();
  };
  return (
    <button
      className="mt-4 text-orange transition hover:text-black focus:text-black"
      onClick={handleDropPromoCode}
    >
      Отменить промокод
    </button>
  );
};

const transformPrice = (price) => {
  if (price || price === 0) {
    return `${parseInt(price).toLocaleString('ru')} ₽`;
  }

  return null;
};
export const PriceWithDiscount = () => {
  const {
    discount: { fullPrice },
    paymentAmount,
  } = usePayment();

  return (
    <span className="flex flex-row gap-2">
      {fullPrice && fullPrice !== paymentAmount && (
        <span className="text-gray-500 line-through">{transformPrice(fullPrice)}</span>
      )}
      <span>{transformPrice(paymentAmount)}</span>
    </span>
  );
};
