import { useComparisonContext } from 'context/comparison.context';
import ComparisonEmptyProducts from '../ComparisonEmptyProducts';
import ComparisonTable from '../ComparisonTable';

const ComparisonPage = () => {
  const { hasProducts } = useComparisonContext();

  if (hasProducts) {
    return <ComparisonTable />;
  } else {
    return <ComparisonEmptyProducts />;
  }
};

export default ComparisonPage;
