import cn from 'classnames';
import { useUI } from 'context/ui.context';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import { ReactComponent as ArrowRight } from 'assets/images/arrowRight.svg';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import SmartIcon from '../../assets/icons/SmartIcon';
import { PAGE_SEO_SMART } from '../../constants';
import { useAuth } from '../../context/auth.context';
import { getSortInitialByPath } from '../../context/filter/filter.context.reducer.methods';
import { useNavigation } from '../../context/navigation.context';
import Popover from '../Popover';

const NavigationLink = ({ label, to, isActive, className }) => {
  const { isMobile } = useUI();
  const { setFilterDefault } = useFilterReducer();

  const handleFilterUpdate = () => {
    //при переходе между страницами обнулять некоторые параметры фильтра
    setFilterDefault({ nextLink: to, prevLink: window.location.pathname });

    window.location.pathname = to;
  };
  const isFavorite = to === PAGE_SEO_SMART;
  return (
    <a href={to} className="block h-full" onClick={(e) => e.preventDefault()}>
      <button
        onClick={handleFilterUpdate}
        className={cn(
          'h-full flex justify-between pb-4 border-b-[1px] lg:pb-0 lg:border-b-0 whitespace-nowrap mx-4 lg:mx-0',
          {
            ['border-green border-t-2 text-green']: isActive,
            ['text-gray-500']: !isActive,
            ['lg:my-0 lg:px-2']: !className,
            ['items-center']: !isFavorite,
            ['pt-[9px]']: isFavorite,
          },
          className,
        )}
      >
        <div>
          {isFavorite && (
            <div className="flex justify-center">
              <SmartIcon width={21} height={13} />
            </div>
          )}
          {label}
        </div>
        {isMobile && <ArrowRight className="stroke-gray-400" />}
      </button>
    </a>
  );
};

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  route: PropTypes.string,
  isActive: PropTypes.bool,
  before: PropTypes.func,
  className: PropTypes.string,
};

const NavigationGroup = ({ title, items, className, disabled }) => {
  const isActive = items.some(({ link }) => window.location.pathname.startsWith(link));
  const [isHover, setIsHover] = useState(false);
  const { isAdmin } = useAuth();
  const ref = useRef();
  // const { setSort } = useFilterReducer();
  const handleFilterUpdate = (link) => {
    //при переходе между страницами обнулять некоторые параметры фильтра
    // setFilterDefault({ nextLink: link, prevLink: window.location.pathname });
    //

    if (link.startsWith('/seo') /*&& !window.location.search*/) {
      window.location.href = window.location.origin + link;
    } else {
      const params = new URLSearchParams(window.location.search);
      const sort = getSortInitialByPath(link);
      const filter = new URLSearchParams(params.get('filter'));
      filter.set('sort', sort);
      params.set('filter', filter.toString());
      window.location.href = window.location.origin + link + `?${params.toString()}`;
    }
    // window.location.search = '';
    // window.location.pathname = link;
  };
  const mainProps = {
    className: cn(
      'h-full flex justify-between pb-4 border-b-[1px] lg:pb-0 lg:border-b-0 whitespace-nowrap mx-4 lg:mx-0 items-center cursor-pointer space-x-2 relative',
      {
        ['border-green border-t-2']: isActive,
        ['text-gray-500 border-t-2 border-white']: !isActive,
        ['lg:my-0 lg:px-2']: !className,
        ['hover:text-green']: !disabled,
      },
      className,
    ),
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
  };
  if (items.length === 1) {
    return (
      <a href={items[0].link} onClick={(e) => e.preventDefault()} key={items[0].link}>
        <button onClick={() => handleFilterUpdate(items[0].link)} {...mainProps}>
          {title}
        </button>
      </a>
    );
  }
  return (
    <>
      <div {...mainProps} ref={ref}>
        <div className={cn({ ['opacity-70']: disabled })}>{title}</div>
        <ArrowDownIcon
          color={isHover ? (disabled ? '#858585' : '#219653') : isActive ? '#333D37' : '#858585'}
        />
        {isHover && !disabled && (
          <div
            className="absolute bg-white rounded-[8px] flex flex-col top-[62px] -left-[16px] py-[6px]"
            style={{ boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)' }}
          >
            {items
              .filter(({ isPrivate = false }) => isAdmin || !isPrivate)
              .map(({ link, name }) => {
                return (
                  <a href={link} onClick={(e) => e.preventDefault()} key={link}>
                    <button
                      onClick={() => handleFilterUpdate(link)}
                      className="px-4 py-2 hover:text-green text-black w-full text-left"
                    >
                      <span className="block text-[14px] leading-[16px] font-medium">{name}</span>
                    </button>
                  </a>
                );
              })}
          </div>
        )}
      </div>
      {disabled && (
        <Popover
          tooltipRef={ref}
          offset={[0, -10]}
          className="bg-opacity-100 whitespace-nowrap"
          tooltip="Недоступно для вашего тарифа"
        />
      )}
    </>
  );
};

NavigationGroup.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const MobileLinks = ({ links }) => {
  // const { setFilterDefault } = useFilterReducer();

  const handleFilterUpdate = (to) => {
    //при переходе между страницами обнулять некоторые параметры фильтра
    // setFilterDefault({ nextLink: to, prevLink: window.location.pathname });

    window.location.href = window.location.origin + to;
  };

  return (
    <div className="flex flex-col space-y-2">
      {links.map((link) => {
        const { label, to, className } = link;
        return (
          <a href={to} className="block w-full" onClick={(e) => e.preventDefault()} key={to}>
            <button
              onClick={() => handleFilterUpdate(to)}
              className={cn('flex justify-between text-[#333D37] items-center w-full', className)}
            >
              <span className="block text-sm leading-[22px]">{label}</span>
              <ArrowRight className="stroke-gray-400" />
            </button>
          </a>
        );
      })}
    </div>
  );
};

MobileLinks.propTypes = {
  links: PropTypes.any,
};

const MobileLinksBlock = ({ header, links, border }) => {
  return (
    <div className={cn('px-4 py-3', { 'border-t border-b border-[#E7EDF0]': border })}>
      <span className="block text-sm leading-[22px] font-bold mb-3">{header}</span>
      <div className="pl-4">
        <MobileLinks links={links} />
      </div>
    </div>
  );
};

MobileLinksBlock.propTypes = {
  header: PropTypes.string,
  links: PropTypes.any,
  border: PropTypes.bool,
};

const Navigation = () => {
  const { isMobile } = useUI();
  const { OUTER_LINKS, INNER_LINKS, EXT_LINKS, NAVIGATION_BLOCKS } = useNavigation();
  const { isBasic } = useAuth();
  if (isMobile) {
    return (
      <div>
        <MobileLinksBlock header={'Внешняя аналитика'} links={OUTER_LINKS} />
        <MobileLinksBlock header={'Внутренняя аналитика'} links={INNER_LINKS} border />
        <div className="py-3 px-4 border-b border-[#E7EDF0]">
          <MobileLinks links={EXT_LINKS} />
        </div>
      </div>
    );
  }
  return (
    <nav
      className="flex flex-col lg:flex-row lg:justify-between lg:min-w-max lg:ml-4 lg:mr-4
    before:content-[''] before:my-4 before:border-l before:border-gray-230 before:pr-3"
    >
      {NAVIGATION_BLOCKS.map((el, index) => (
        <NavigationGroup
          {...el}
          key={el.id + '_' + index}
          disabled={isBasic && (index === 1 || index === 2)}
        />
      ))}
    </nav>
  );
};

export default Navigation;
