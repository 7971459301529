import PlatformLink from 'components/PlatformLink';
import PropTypes from 'prop-types';

const ProductSearchFormItem = ({ product, setSelectedProducts }) => {
  const { id, name, inner_product_id: external_id, image } = product || {};
  const onClick = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedProducts((products) => [...products, id]);
    } else {
      setSelectedProducts((products) => products.filter((productId) => productId !== id));
    }
  };
  return (
    <div className="flex items-center justify-between border-b border-gray-200 py-3">
      <input
        type="checkbox"
        className="checkbox h-5 w-5 shrink-0 bg-origin-border
        align-middle color-green-bright border-green-bright border
        rounded appearance-none transition-all cursor-pointer
        focus:ring-2 focus:ring-offset-2 focus:ring-green-bright focus:outline-none
        checked:border-transparent checked:bg-contain checked:bg-center checked:bg-no-repeat checked:bg-green-bright"
        onClick={onClick}
      />

      <div className="flex flex-col flex-grow mx-4 space-y-1">
        <span className="font-semibold text-sm">{name}</span>
        <span className="text-sm text-gray-300 flex items-center space-x-1">
          <span>Арт.{external_id}</span> <PlatformLink isWbPlatform value={external_id} />
        </span>
      </div>

      <img src={image} alt={name} className="h-12 w-12 rounded object-cover" />
    </div>
  );
};

ProductSearchFormItem.propTypes = {
  setSelectedProducts: PropTypes.func,
  product: PropTypes.object,
};

export default ProductSearchFormItem;
