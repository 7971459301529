import {
  CELL_PERIOD_PREFIX,
  COLUMNS,
  COLUMNS_PRODUCT,
  TABLE_INNER_TYPE,
  TABLE_TYPE,
} from 'constants/table';

import { NESTED, TAB_FIRST, TAB_SECOND } from 'constants/seo';
import {
  advTypeFormatter,
  amountFormatter,
  amountFormatterWithHyphen,
  amountShortcutFormatter,
  booleanFormatter,
  currencyFormatter,
  percentageFormatter,
} from './cellValueFormatters';
import { AMOUNT_WITH_PERCENT_TABLE_CELL_NAME, DISABLED_FILTER_TOOLTIP } from './generateGridConfig';

import DonutIcon from 'assets/icons/DonutIcon';
import LoopIcon from 'assets/icons/LoopIcon';
import LoopSuccess from 'assets/icons/LoopSuccess';
import { columnDefsAdvertisingRates } from 'components/AdvertisingRates';
import {
  columnDefsInnerInDays,
  columnDefsInnerInDays2,
  columnDefsInnerOrdersSales,
  columnDefsInnerPriesTab,
  columnDefsInnerProducts,
  columnDefsInnerProductSizes,
  columnDefsInnerProductsSettings,
  columnDefsInnerRatings,
  columnDefsInnerSales,
  columnDefsInnerWarehouses,
  columnDefsInnerWeeks,
  columnsDefsInnerDelivery,
} from 'helpers/generateGridInnerColumns';
import {
  amountTooltipFormatter,
  amountWithPercentTooltipFormatter,
  avgOrderPerDayTooltipFormatter,
  avgOrderSkuTooltipFormatter,
  avgOrdersSellerTooltipFormatter,
  avoidPostfixTooltipFormatter,
  brandsQtyTooltipFormatter,
  currencyTooltipFormatter,
  currencyWithPercentTooltipFormatter,
  formatTooltipStrings,
  lostOrdersTooltipFormatter,
  percentTooltipFormatter,
  potentialTooltipFormatter,
  priceAverageTooltipFormatter,
  priceTooltipFormatter,
  sellersTooltipFormatter,
  simpleBooleanTooltipFormatter,
  simpleTooltipFormatter,
  skuTooltipFormatter,
  splitByCommasTooltipFormatter,
} from 'helpers/tooltipValueGetters';
import {
  categoryValue,
  headerTooltipDecorator,
  imageValue,
  nullComparator,
  oneFilterTab,
  percentageValue,
  rangeFilter,
  sortable,
  sortDateOnAggrid,
  textFilter,
} from './generateGridColumnsHelpers';
import { innerTableTooltipWrapper } from './innerTableTooltipWrapper';

const COMMON_KEYS = {
  [COLUMNS_PRODUCT.ORDERS_RUB]: 'orders_rub',
  [COLUMNS_PRODUCT.ORDERS_QTY]: 'orders_qty',
  [COLUMNS_PRODUCT.STOCK_RUB]: 'stock_rub',
  [COLUMNS_PRODUCT.STOCK_QTY]: 'stock_qty',
  [COLUMNS_PRODUCT.IN_STOCK_RUB]: 'avg_orders_if_in_stock_rub',
  [COLUMNS_PRODUCT.IN_STOCK_QTY]: 'avg_orders_if_in_stock_qty',
  [COLUMNS_PRODUCT.MISSED_RUB]: 'lost_orders_rub',
  [COLUMNS_PRODUCT.MISSED_QTY]: 'lost_orders_qty',
  [COLUMNS_PRODUCT.MISSED_PERCENT]: 'lost_orders_percent',
  [COLUMNS_PRODUCT.POTENTIAL_RUB]: 'potential_rub',
  [COLUMNS_PRODUCT.POTENTIAL_QTY]: 'potential_qty',
};

const COLUMNS_INFO = ({
  tooltipKeyWord = '',
  hideSoldColumn = false,
  isSellerCellClickable = true,
  showFilterHiddenTooltip = false,
}) => ({
  [COLUMNS.ORDERS_RUB]: {
    headerName: 'Заказы, ₽',
    field: COLUMNS.ORDERS_RUB,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyWithPercentTooltipFormatter('Заказы'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Сумма проданных товаров ${tooltipKeyWord}.
                Доля - сумма продаж товаров ${tooltipKeyWord},
                по отношению к сумме продаж всех товаров на сайте.`,
    ),
    width: 130,
    initialSort: 'desc',
    ...rangeFilter,
  },
  [COLUMNS.ORDERS_QTY]: {
    headerName: 'Заказы, шт',
    field: COLUMNS.ORDERS_QTY,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountWithPercentTooltipFormatter('Заказы'),
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Количество товаров, проданных за выбранный период.
                Доля - кол-во проданных товаров ${tooltipKeyWord},
                по отношению к количеству всех
                проданных товаров на сайте.`,
    ),
    width: 130,
    ...rangeFilter,
  },
  [COLUMNS.SOLD_RUB]: {
    headerName: 'Купили более, ₽',
    field: COLUMNS.SOLD_RUB,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyWithPercentTooltipFormatter('Купили более'),
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    width: 130,
    initialHide: hideSoldColumn,
    hide: hideSoldColumn,
    suppressColumnsToolPanel: hideSoldColumn,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `WeCheck отслеживает изменения параметра «купили более» в карточке товара.
    На основании изменния этого параметра расчитываются «заказы купили более».
    Этот показатель полезно применять для товаров с типом склада FBS.
Wildberries не обновляет этот показтель для товаров, которые купили более 100 000 раз.
Поэтому для таких товаров сервис WeCheck будет расчитывать купили более = 0.`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.SOLD_QTY]: {
    headerName: 'Купили более, шт',
    field: COLUMNS.SOLD_QTY,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountWithPercentTooltipFormatter('Купили более'),
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    width: 130,
    initialHide: hideSoldColumn,
    hide: hideSoldColumn,
    suppressColumnsToolPanel: hideSoldColumn,
    ...rangeFilter,
  },
  [COLUMNS.STOCK_QTY]: {
    headerName: 'Остаток',
    field: COLUMNS.STOCK_QTY,
    valueFormatter: amountShortcutFormatter,
    width: 100,
    tooltipValueGetter: amountTooltipFormatter('Остаток товаров на складах'),
    ...rangeFilter,
  },
  [COLUMNS.STOCK_QTY_REAL]: {
    headerName: 'Остаток',
    field: COLUMNS.STOCK_QTY_REAL,
    valueFormatter: amountShortcutFormatter,
    width: 100,
    tooltipValueGetter: amountTooltipFormatter('Остаток товаров на складах'),
    ...rangeFilter,
  },

  [COLUMNS.PRICE]: {
    headerName: 'Цена',
    field: COLUMNS.PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: priceTooltipFormatter,
    cellRendererParams: {
      leaveEmpty: false,
    },
    width: 120,
    ...rangeFilter,
  },
  [COLUMNS.MIN_PRICE]: {
    headerName: 'Мин. цена',
    field: COLUMNS.MIN_PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Минимальная цена товара за период'),
    cellRendererParams: {
      leaveEmpty: false,
    },
    hide: true,
    width: 120,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Минимальная цена 
Минимальная цена товара за период`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.MAX_PRICE]: {
    headerName: 'Макс. цена',
    field: COLUMNS.MAX_PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Максимальная цена товара за период'),
    cellRendererParams: {
      leaveEmpty: false,
    },
    hide: true,
    width: 120,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Максимальная цена
Максимальная цена товара за период`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.OLD_PRICE]: {
    headerName: 'Старая цена',
    field: COLUMNS.OLD_PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: priceTooltipFormatter,
    cellRendererParams: {
      leaveEmpty: false,
    },
    width: 120,
    ...rangeFilter,
  },
  [COLUMNS.AVG_PRICE]: {
    headerName: 'Цена',
    field: COLUMNS.AVG_PRICE,
    tooltipValueGetter: priceAverageTooltipFormatter,
    valueFormatter: currencyFormatter,
    cellRendererParams: {
      leaveEmpty: true,
    },
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDER_PRICE]: {
    headerName: 'Цена заказанного товара',
    field: COLUMNS.AVG_ORDER_PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Цена заказанного товара'),
    cellRendererParams: {
      leaveEmpty: true,
    },
    headerTooltip: showFilterHiddenTooltip
      ? DISABLED_FILTER_TOOLTIP
      : `Средняя стоимость проданных товаров.`,
    width: 100,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.AVG_SOLD_PRICE]: {
    headerName: 'Цена выкупленного товара',
    field: COLUMNS.AVG_SOLD_PRICE,
    valueFormatter: currencyFormatter,
    cellRendererParams: {
      leaveEmpty: true,
    },
    initialHide: hideSoldColumn,
    hide: hideSoldColumn,
    suppressColumnsToolPanel: hideSoldColumn,
    ...rangeFilter,
  },
  [COLUMNS.SKU_QTY]: {
    headerName: 'Товаров',
    field: COLUMNS.SKU_QTY,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: skuTooltipFormatter,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    cellRendererParams: {
      percentKey: COLUMNS.ACTIVE_SKU_PERCENT,
    },
    headerTooltip: showFilterHiddenTooltip
      ? DISABLED_FILTER_TOOLTIP
      : `Количество уникальных товаров, которые были
                в наличии хотя бы один день за выбранный период.
                Доля - уникальные товары ${tooltipKeyWord} по отношению
                ко всему количеству уникальных товаров на сайте.`,
    width: 130,
    ...rangeFilter,
  },
  [COLUMNS.ACTIVE_SKU_QTY]: {
    headerName: 'Т-в с заказами',
    field: COLUMNS.ACTIVE_SKU_QTY,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Товаров с заказами'),
    headerTooltip: showFilterHiddenTooltip
      ? DISABLED_FILTER_TOOLTIP
      : `Количество товаров, у которых была хотя бы одна продажа
                за выбранный период. Доля - товары ${tooltipKeyWord}
                у которых были продажи, по отношению ко всем
                товарам сайта у которых были продажи.`,
    width: 100,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDERS_1SKU_QTY]: {
    headerName: 'Заказов одного товара, шт',
    valueFormatter: amountFormatter,
    field: COLUMNS.AVG_ORDERS_1SKU_QTY,
    width: 80,
    tooltipValueGetter: avgOrderSkuTooltipFormatter,
    ...rangeFilter,
  },
  [COLUMNS.POTENTIAL_QTY]: {
    valueFormatter: amountShortcutFormatter,
    field: COLUMNS.POTENTIAL_QTY,
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDERS_1SKU_RUB]: {
    headerName: 'Заказов одного товара, ₽',
    valueFormatter: currencyFormatter,
    field: COLUMNS.AVG_ORDERS_1SKU_RUB,
    width: 100,
    hide: true,
    tooltipValueGetter: avgOrderSkuTooltipFormatter,
    ...rangeFilter,
  },
  [COLUMNS.POTENTIAL_RUB]: {
    headerName: 'Потенциал, ₽',
    field: COLUMNS.POTENTIAL_RUB,
    valueFormatter: amountShortcutFormatter,
    width: 120,
    tooltipValueGetter: potentialTooltipFormatter,
    ...rangeFilter,
  },
  [COLUMNS.LOST_ORDERS_RUB]: {
    headerName: 'Упущено заказов, ₽',
    field: COLUMNS.LOST_ORDERS_RUB,
    valueFormatter: amountShortcutFormatter,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    cellRendererParams: {
      percentKey: COLUMNS.LOST_ORDERS_PERCENT,
    },
    tooltipValueGetter: lostOrdersTooltipFormatter,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Заказы, не полученные из-за отсутствия товара на складе.
      Пример расчета.
      Товар продавался в среднем 10 шт в сутки.
      Товар был не в наличии 3 дня.
      Упущенные заказы в шт 10 * 3 = 30 шт.
      Упущенные заказы, руб = 30 * последнюю известную цену товара`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDERS_PER_DAY_QTY]: {
    headerName: 'Заказов в сутки, шт',
    field: COLUMNS.AVG_ORDERS_PER_DAY_QTY,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: avgOrderPerDayTooltipFormatter,
    width: 130,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDERS_PER_DAY_RUB]: {
    headerName: 'Заказов в сутки, ₽',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: avgOrderPerDayTooltipFormatter,
    field: COLUMNS.AVG_ORDERS_PER_DAY_RUB,
    width: 120,
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_QTY]: {
    headerName: 'Продавцов',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: sellersTooltipFormatter,
    field: COLUMNS.SELLERS_QTY,
    width: 140,
    ...rangeFilter,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    cellRendererParams: {
      percentKey: COLUMNS.ACTIVE_SELLERS_PERCENT,
    },
  },
  [COLUMNS.ACTIVE_SELLERS_QTY]: {
    headerName: 'Продавцов с заказами',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Продавцов с заказами'),
    field: COLUMNS.ACTIVE_SELLERS_QTY,
    width: 100,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.ACTIVE_SELLERS_QTY_2]: {
    headerName: 'Продавцов с заказами',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Продавцов с заказами'),
    field: COLUMNS.ACTIVE_SELLERS_QTY_2,
    width: 100,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.AVG_ORDERS_1SELLER_RUB]: {
    headerName: 'В среднем заказов у продавца, ₽',
    field: COLUMNS.AVG_ORDERS_1SELLER_RUB,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: avgOrdersSellerTooltipFormatter,
    width: 120,
    ...rangeFilter,
  },
  [COLUMNS.BRANDS_QTY]: {
    headerName: 'Брендов',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: brandsQtyTooltipFormatter,
    field: COLUMNS.BRANDS_QTY,
    width: 150,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    cellRendererParams: {
      percentKey: COLUMNS.ACTIVE_BRANDS_PERCENT,
    },
    ...rangeFilter,
  },
  [COLUMNS.ACTIVE_BRANDS_QTY]: {
    headerName: 'Брендов с заказами',
    valueFormatter: amountShortcutFormatter,
    field: COLUMNS.ACTIVE_BRANDS_QTY,
    tooltipValueGetter: amountTooltipFormatter('Бренды с заказами'),
    width: 100,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS.REVIEWS_QTY]: {
    headerName: 'Отзывов',
    valueFormatter: amountShortcutFormatter,
    field: COLUMNS.REVIEWS_QTY,
    width: 100,
    tooltipValueGetter: amountTooltipFormatter('Отзывов'),
    ...rangeFilter,
  },
  [COLUMNS.REVIEWS_COUNT]: {
    headerName: 'Отзывов',
    valueFormatter: amountShortcutFormatter,
    field: COLUMNS.REVIEWS_COUNT,
    width: 80,
    tooltipValueGetter: amountTooltipFormatter('Отзывов'),
    ...rangeFilter,
  },
  [COLUMNS.REVIEWS_COUNT_LAST]: {
    headerName: 'Отзывов',
    valueFormatter: amountShortcutFormatter,
    field: COLUMNS.REVIEWS_COUNT_LAST,
    width: 80,
    tooltipValueGetter: amountTooltipFormatter('Отзывов'),
    ...headerTooltipDecorator(showFilterHiddenTooltip, `Количество отзывов о товаре`),
    ...rangeFilter,
  },
  [COLUMNS.RATING]: {
    headerName: 'Рейтинг',
    field: COLUMNS.RATING,
    width: 90,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Рейтинг'),
    ...rangeFilter,
  },
  [COLUMNS.RATING_AVG]: {
    headerName: 'Рейтинг',
    field: COLUMNS.RATING_AVG,
    width: 90,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Отзывов'),
    ...rangeFilter,
  },
  [COLUMNS.DAYS_IN_STOCK]: {
    headerName: 'Дней в наличии',
    field: COLUMNS.DAYS_IN_STOCK,
    width: 80,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Дней в наличии'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Количество дней, когда на складах был в наличии хотя бы один экземляр товара`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.DAYS_WITH_SALES]: {
    headerName: 'Дней с продажами',
    field: COLUMNS.DAYS_WITH_SALES,
    width: 80,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Дней с продажами'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Количество дней в которые товар заказали хотя бы один раз`,
    ),
    ...rangeFilter,
  },
  [COLUMNS.LISTING]: {
    headerName: 'Позиция',
    field: COLUMNS.LISTING,
    width: 45,
    valueFormatter: amountFormatter,
    cellRendererParams: {
      leaveEmpty: true,
    },
    tooltipValueGetter: simpleTooltipFormatter('Позиция'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Позиция товара в категории
Наивысшая позиция товара среди всех категорий, в которых он был 
зафиксирован`,
    ),
  },
  [COLUMNS.SELLER]: {
    headerName: 'Продавец',
    field: COLUMNS.SELLER,
    headerComponentParams: { sortable: false },
    cellRenderer: isSellerCellClickable ? 'filterLinkTableCell' : null,
    width: 200,
    pinned: true,
    cellRendererParams: {
      suppressRightAlign: true,
      leaveEmpty: true,
      id: 'seller',
    },
    ...textFilter,
  },
  [COLUMNS.SUBJECT]: {
    headerName: 'Предмет',
    field: 'name',
    pinned: true,
    width: 200,
    initialSort: 'desc',
    cellStyle: { textAlign: 'left' },
    headerComponentParams: { sortable: false },
    cellRenderer: 'filterLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
      leaveEmpty: true,
      id: 'subject',
    },
    ...textFilter,
  },
  [COLUMNS.SEARCH_PHRASE]: {
    headerName: 'Поисковой запрос',
    field: 'phrase',
    pinned: true,
    width: 200,
    initialSort: 'desc',
    cellStyle: { textAlign: 'left' },
    headerComponentParams: { sortable: false },
    // cellRenderer: 'filterLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
      leaveEmpty: true,
      id: 'subject',
    },
    ...textFilter,
  },
  [COLUMNS.BRAND]: {
    headerName: 'Бренд',
    pinned: true,
    field: COLUMNS.BRAND,
    cellRenderer: 'filterLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
      leaveEmpty: true,
      id: 'brand',
    },
    headerComponentParams: { sortable: false },
    ...textFilter,
  },
  [COLUMNS.NAME]: {
    headerName: 'Название товара',
    field: COLUMNS.NAME,
    minWidth: 250,
    pinned: true,
    cellRenderer: 'productLinkTableCell',
    cellRendererParams: {
      truncate: true,
    },
    headerComponentParams: { sortable: false },
  },
  [COLUMNS.IMAGE]: {
    headerName: 'Фото',
    field: COLUMNS.IMAGE,
    width: 60,
    minWidth: 60,
    ...imageValue,
  },
  [COLUMNS.LINK]: {
    headerName: 'Ссылка',
    field: COLUMNS.LINK,
    cellRenderer: 'platformLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    pinned: true,
    width: 70,
  },
  [COLUMNS.INNER_PRODUCT_ID]: {
    headerName: 'Артикул',
    field: COLUMNS.INNER_PRODUCT_ID,
    minWidth: 60,
    width: 100,
    pinned: false,
    headerComponentParams: { sortable: false },
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  [COLUMNS.CATEGORIES]: {
    headerName: 'Категория',
    field: COLUMNS.CATEGORIES,
    width: 300,
    cellRendererParams: {
      overflowHiddenLeft: true,
    },
    tooltipValueGetter: splitByCommasTooltipFormatter,
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Категории
Категория(и), в которой зафиксирован товар`,
    ),
  },
  [COLUMNS.CATEGORIES_QTY]: {
    headerName: 'Кол-во категорий',
    field: COLUMNS.CATEGORIES_QTY,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Количество категорий'),
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS_SIMILAR_QTY]: {
    headerName: 'Товаров в склейке',
    field: COLUMNS.PRODUCTS_SIMILAR_QTY,
    hide: hideSoldColumn,
    valueFormatter: amountFormatter,
    suppressColumnsToolPanel: hideSoldColumn,
    tooltipValueGetter: simpleTooltipFormatter('Количество товаров в склейке'),
    ...rangeFilter,
  },
  [COLUMNS.WAREHOUSES_QTY]: {
    headerName: 'Кол-во складов',
    field: COLUMNS.WAREHOUSES_QTY,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Кол-во складов'),
    ...rangeFilter,
  },
  [COLUMNS.TURNOVER]: {
    headerName: 'Оборачиваемость',
    field: COLUMNS.TURNOVER,
    valueFormatter: amountFormatter,
    tooltipValueGetter: simpleTooltipFormatter('Оборачиваемость'),
    ...rangeFilter,
  },

  [COLUMNS.PERIOD]: {
    headerName: 'Дата',
    pinned: true,
    field: COLUMNS.PERIOD,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    initialSort: 'desc',
    ...sortDateOnAggrid,
  },
  [COLUMNS.DATE]: {
    headerName: 'Дата',
    field: COLUMNS.DATE,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    minWidth: 110,
    initialSort: 'desc',
    ...sortDateOnAggrid,
  },
  [COLUMNS.BRANDS_COUNT]: {
    headerName: 'Брендов',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    minWidth: 150,
    field: COLUMNS.BRANDS_COUNT,
    ...sortable,
  },
  [COLUMNS.SELLERS_COUNT]: {
    headerName: 'Продавцов',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    minWidth: 160,
    field: COLUMNS.SELLERS_COUNT,
    ...sortable,
  },
  [COLUMNS.CATEGORY]: {
    headerName: 'Категория',
    field: COLUMNS.CATEGORY,
    minWidth: 200,
    pinned: true,
    ...categoryValue,
  },
  [COLUMNS.TYPE_OF_CHANGE]: {
    headerName: 'Тип изменения',
    field: COLUMNS.TYPE_OF_CHANGE,
    headerComponentParams: { sortable: false },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    minWidth: 250,
  },
  [COLUMNS.IT_WAS]: {
    headerName: 'Было',
    field: COLUMNS.IT_WAS,
    minWidth: 200,
    valueFormatter: amountFormatter,
    ...sortable,
  },
  [COLUMNS.BECAME]: {
    headerName: 'Стало',
    field: COLUMNS.BECAME,
    minWidth: 200,
    valueFormatter: amountFormatter,
    ...sortable,
  },
  [COLUMNS.ADV_TYPE]: {
    headerName: 'Тип размещения',
    field: COLUMNS.ADV_TYPE,
    headerComponentParams: { sortable: false },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    width: 180,
    valueFormatter: advTypeFormatter,
  },
  [COLUMNS.PARSING_TIME]: {
    headerName: 'Время',
    field: COLUMNS.PARSING_TIME,
    headerComponentParams: { sortable: false },
    width: 100,
  },
  [COLUMNS.TIME]: {
    headerName: 'Время',
    field: COLUMNS.TIME,
    headerComponentParams: { sortable: true },
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  [COLUMNS.ADV_MEANING]: {
    headerName: 'Место размещения',
    field: COLUMNS.ADV_MEANING,
    headerComponentParams: { sortable: false },
    minWidth: 200,
    tooltipValueGetter: splitByCommasTooltipFormatter,
    valueFormatter: amountFormatter,
    cellRendererParams: {
      overflowHiddenLeft: true,
      leaveEmpty: true,
    },
  },
  [COLUMNS.POSITION]: {
    headerName: 'Позиция',
    field: COLUMNS.POSITION,
    headerComponentParams: { sortable: true },
    width: 140,
    valueFormatter: amountFormatter,
  },
  [COLUMNS.CPM]: {
    headerName: 'Стоимость',
    field: COLUMNS.CPM,
    minWidth: 120,
    valueFormatter: currencyFormatter,
    ...sortable,
  },
  [COLUMNS.VERTICAL_HEADER]: {
    headerName: '',
    field: COLUMNS.VERTICAL_HEADER,
    cellRenderer: 'tableCellWithSelect',
    headerComponentParams: { sortable: false },
    maxWidth: 300,
    pinned: true,
    checkboxSelection: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...textFilter,
  },
  [COLUMNS.SKU_QTY_]: {
    headerName: 'Товаров',
    field: COLUMNS.SKU_QTY_,
    width: 100,
    valueFormatter: amountFormatter,
    cellRendererParams: {
      leaveEmpty: true,
    },
    ...sortable,
  },
  [COLUMNS.AVG_POSITION_BY_KEYWORD]: {
    headerName: 'Ср. позиция',
    field: COLUMNS.AVG_POSITION_BY_KEYWORD,
    sort: 'asc',
    width: 80,
    valueFormatter: amountFormatter,
    cellRendererParams: {
      leaveEmpty: true,
    },
    ...sortable,
  },
  [COLUMNS.PICTURES]: {
    headerName: 'Фото, шт',
    field: COLUMNS.PICTURES,
    valueFormatter: amountFormatter,
    headerComponentParams: { sortable: false },
    width: 80,
    hide: true,
    tooltipValueGetter: simpleTooltipFormatter('Количество фотографий'),
    ...headerTooltipDecorator(showFilterHiddenTooltip, `Количество фото в карточке товара`),
  },
  [COLUMNS.DESCRIPTION]: {
    headerName: 'Слов в описании',
    field: COLUMNS.DESCRIPTION,
    valueFormatter: amountFormatter,
    headerComponentParams: { sortable: false },
    width: 80,
    hide: true,
    tooltipValueGetter: simpleTooltipFormatter('Использовано слов в описании'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Количество слов в описании товара. Показатель служит для оценки
      качества заполенния описания. Максимальное возможно 888 символов
      это примерно 888 слов`,
    ),
  },
  [COLUMNS.SPECIFICATION]: {
    headerName: 'Характеристик',
    field: COLUMNS.SPECIFICATION,
    valueFormatter: amountFormatter,
    headerComponentParams: { sortable: false },
    width: 80,
    hide: true,
    tooltipValueGetter: simpleTooltipFormatter('Заполнено характеристик'),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `Количество заполненных характеристик. Показывает насколько
      полно заполнена информация о товаре`,
    ),
  },
  [COLUMNS.VIDEO]: {
    headerName: 'Видео',
    field: COLUMNS.VIDEO,
    valueFormatter: booleanFormatter,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 80,
    hide: true,
    tooltipValueGetter: simpleBooleanTooltipFormatter('Наличие видео у товара'),
    ...headerTooltipDecorator(showFilterHiddenTooltip, `Наличие видео у товара`),
  },
  [COLUMNS.PHRASE_NAME]: {
    headerName: 'Поисковая фраза',
    field: COLUMNS.PHRASE_NAME,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    ...textFilter,
  },
  [COLUMNS.ITEM]: {
    headerName: 'Кластер',
    field: COLUMNS.CLUSTER_NAME,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    ...textFilter,
  },
  [COLUMNS.COUNT_PRODUCTS]: {
    headerName: 'Товары, шт',
    field: COLUMNS.COUNT_PRODUCTS,
    valueFormatter: amountFormatter,
    width: 230,
    tooltipValueGetter: amountTooltipFormatter('Товары'),
    ...oneFilterTab,
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS]: {
    headerName: 'Товары, шт',
    field: COLUMNS.PRODUCTS,
    valueFormatter: amountShortcutFormatter,
    width: 140,
    tooltipValueGetter: amountTooltipFormatter('Товары'),
    ...rangeFilter,
  },
  [COLUMNS.FREQUENCY_OZON]: {
    headerName: 'Частотность ozon',
    field: COLUMNS.FREQUENCY_OZON,
    valueFormatter: amountFormatter,
    width: 200,
    comparator: nullComparator,
    ...oneFilterTab,
    ...rangeFilter,
  },
  [COLUMNS.FREQUENCY_YANDEX]: {
    headerName: 'Частотность yandex',
    field: COLUMNS.FREQUENCY_YANDEX,
    valueFormatter: amountFormatter,
    width: 210,
    comparator: nullComparator,
    ...oneFilterTab,
    ...rangeFilter,
  },
  [COLUMNS.FREQUENCY_WB]: {
    headerName: 'Частотность WB',
    field: COLUMNS.FREQUENCY_WB,
    width: 165,
    valueFormatter: amountFormatterWithHyphen,
    initialSort: 'desc',
    ...oneFilterTab,
    ...rangeFilter,
  },
  [COLUMNS.WORD]: {
    headerName: 'Слово',
    field: COLUMNS.WORD,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',

    ...textFilter,
  },
  [COLUMNS.FORMS]: {
    headerName: 'Словоформы',
    field: COLUMNS.FORMS,
    flex: 1,
    minWidth: 250,
    cellRendererParams: {
      suppressRightAlign: true,
      truncate: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
    tooltipValueGetter: splitByCommasTooltipFormatter,
    ...textFilter,
  },
  [COLUMNS.COUNT]: {
    headerName: 'Кол-во вхождений',
    field: COLUMNS.COUNT,
    valueFormatter: amountFormatter,
    width: 200,
    tooltipValueGetter: simpleTooltipFormatter(''),
    ...oneFilterTab,
    ...rangeFilter,
  },
  [COLUMNS.SIMILAR_NAME]: {
    headerName: 'Поисковая фраза',
    headerComponentParams: { sortable: false },
    field: COLUMNS.NAME,
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
    },
    cellClass: 'no-selection',
    ...textFilter,
  },
  [COLUMNS.ORDERS_RUB_]: {
    headerName: 'Заказы, ₽',
    field: COLUMNS.ORDERS_RUB_,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyWithPercentTooltipFormatter('Заказы'),
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_WITH_ORDERS]: {
    headerName: 'Продавцы с продажами, шт.',
    headerComponentParams: { sortable: true },
    field: COLUMNS.SELLERS_WITH_ORDERS,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
  },
  [COLUMNS.SELLERS_WITH_ORDERS_]: {
    headerName: 'Продавцы с заказами, шт.',
    headerComponentParams: { sortable: true },
    field: COLUMNS.SELLERS_WITH_ORDERS_,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...sortable,
  },
  [COLUMNS.SELLER_ABG_ORDERS]: {
    headerName: 'Заказов у продавца, ₽',
    headerComponentParams: { sortable: true },
    field: COLUMNS.SELLER_ABG_ORDERS,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  [COLUMNS.BRANDS_WITH_ORDERS]: {
    headerName: 'Бренды с продажами, шт.',
    headerComponentParams: { sortable: true },
    field: COLUMNS.BRANDS_WITH_ORDERS,
    tooltipValueGetter: amountTooltipFormatter(''),
    valueFormatter: amountShortcutFormatter,
  },
  [COLUMNS.SKU_WITH_ORDERS]: {
    headerName: 'Товары с продажами, шт.',
    field: COLUMNS.SKU_WITH_ORDERS,
    tooltipValueGetter: amountTooltipFormatter(''),
    valueFormatter: amountShortcutFormatter,
    ...sortable,
  },
  [COLUMNS.ORDERS_QTY_]: {
    headerName: 'Заказы, шт',
    field: COLUMNS.ORDERS_QTY_,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountWithPercentTooltipFormatter('Заказы'),
    ...sortable,
  },
  [COLUMNS.AVERAGE_RUB]: {
    headerName: 'Заказы, ₽',
    field: COLUMNS.AVERAGE_RUB,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: currencyTooltipFormatter('Заказы'),
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_WITH_PRODUCT]: {
    headerName: 'Продавцы, шт.',
    field: COLUMNS.SELLERS_WITH_PRODUCT,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: sellersTooltipFormatter,
    ...rangeFilter,
  },
  [COLUMNS.REVIEW]: {
    headerName: 'Отзывы',
    field: COLUMNS.REVIEW,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter('Отзывов'),
    ...rangeFilter,
  },
  [COLUMNS.BRANDS_WITH_PRODUCTS]: {
    headerName: 'Бренды, шт.',
    field: COLUMNS.BRANDS_WITH_PRODUCTS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: brandsQtyTooltipFormatter,
    ...rangeFilter,
  },
  [COLUMNS.BRANDS_WITH_ORDER]: {
    headerName: 'Бренды с заказами, шт.',
    field: COLUMNS.BRANDS_WITH_ORDER,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_ORDERS]: {
    headerName: 'Заказы на продавца, ₽',
    field: COLUMNS.SELLERS_ORDERS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS_WITH_ORDERS]: {
    headerName: 'Товары с заказами, шт.',
    field: COLUMNS.PRODUCTS_WITH_ORDERS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.SOLD_MONEY_GROWTH]: {
    headerName: 'Темп роста | Заказы, ₽',
    field: COLUMNS.SOLD_MONEY_GROWTH,
    width: 120,
    ...percentageValue,
    ...rangeFilter,
  },
  [COLUMNS.SOLD_NUMBER_GROWTH]: {
    headerName: 'Темп роста | Заказы, шт.',
    field: COLUMNS.SOLD_NUMBER_GROWTH,
    width: 120,
    ...percentageValue,
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS_GROWTH]: {
    headerName: 'Темп роста товаров',
    field: COLUMNS.PRODUCTS_GROWTH,
    width: 150,
    ...percentageValue,
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_GROWTH]: {
    headerName: 'Темп роста продавцов',
    field: COLUMNS.SELLERS_GROWTH,
    width: 150,
    ...percentageValue,
    ...rangeFilter,
  },
  [COLUMNS.SOLD_MONEY_BASIS]: {
    headerName: 'Заказы, ₽ | пр. период',
    field: COLUMNS.SOLD_MONEY_BASIS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.SOLD_NUMBER_BASIS]: {
    headerName: 'Заказы, шт | пр. период',
    field: COLUMNS.SOLD_NUMBER_BASIS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS_NUMBER]: {
    headerName: 'Товаров, шт',
    field: COLUMNS.PRODUCTS_NUMBER,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.PRODUCTS_BASIS]: {
    headerName: 'Товаров, шт | пр. период',
    field: COLUMNS.PRODUCTS_BASIS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_NUMBER]: {
    headerName: 'Продавцов, шт',
    field: COLUMNS.SELLERS_NUMBER,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.SELLERS_BASIS]: {
    headerName: 'Продавцов, шт | пр. период',
    field: COLUMNS.SELLERS_BASIS,
    valueFormatter: amountShortcutFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.DEMAND_INDEX]: {
    headerName: 'Индекс спроса',
    field: COLUMNS.DEMAND_INDEX,
    valueFormatter: amountFormatter,
    width: 150,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  [COLUMNS.CATEGORY_CHARTS]: {
    field: COLUMNS.CATEGORY_CHARTS,
    headerName: 'График категорий',
    ...getChartsColumn(COLUMNS.CATEGORY_CHARTS, (params) => {
      const { yValue, xValue } = params;
      return `<div>
                  <div>Категорий: ${yValue.toLocaleString('ru')}</div>
                  <div>Дата: ${xValue}</div>
            </div>`;
    }),
    ...headerTooltipDecorator(
      showFilterHiddenTooltip,
      `График категорий (кол-во категорий + акционные)`,
    ),
  },
});

const sparklineTooltipRenderer = (fieldPrefix, renderer) => {
  return (params) => {
    const { yValue, xValue } = params;

    return `<div style="margin-top:${window.scrollY}px" id="${fieldPrefix + '_' + xValue + '_' + yValue}">
                <div class='bg-green-light p-1 rounded border border-green'>
          ${renderer(params)}
                </div>
            </div>`;
  };
};

const getChartsColumn = (fieldPrefix, renderer) => {
  return {
    field: fieldPrefix,
    valueGetter: (props) => {
      const { data, colDef } = props;
      const { prefix = fieldPrefix, field } = colDef;
      let resultData = data[field];
      resultData = JSON.parse(resultData);
      return Object.keys(resultData)
        .filter((el) => el.indexOf(prefix) !== -1)
        .map((el) => {
          const date = el.split('/')[1]?.split('-').reverse().join('.');
          return {
            date,
            value: resultData[el],
          };
        });
    },
    cellRenderer: 'agSparklineCellRenderer',
    cellRendererParams: {
      sparklineOptions: {
        fill: '#219653',
        tooltip: {
          renderer: sparklineTooltipRenderer(fieldPrefix, renderer),
        },
        type: 'column',
        xKey: 'date',
        yKey: 'value',
        // axis: {
        //   type: 'string',
        // },
      },
    },
  };
};

const generateColumnDefsCategories = (props) => [
  {
    headerName: 'Категория',
    field: 'name',
    rowGroup: true, // Define this as a grouping column for tree structure
    hide: true, // Optionally hide it from the grid (it's used for the hierarchy)
    suppressColumnsToolPanel: true,
    filter: 'searchFilter',
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    field: 'orders_rub',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Изучите объем заказов, чтобы оценить спрос в нише 
и перспективность входа в конкретную категорию. 
Чем больше заказов, тем перспективнее категория.

Процент — доля заказов категории от суммы всех заказов по фильтру.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    field: 'orders_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Изучите количество заказов, чтобы оценить спрос в нише 
и перспективность входа в конкретную категорию. 
Чем больше заказов, тем перспективней категория.

Процент — доля заказов категории от суммы всех заказов по фильтру.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY],
    field: 'sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров, шт
Оцените количество уникальных товаров, доступных к заказу для
покупателей. 
Чем меньше товаров в категории, тем меньше конкуренция и тем проще
будет выйти в топ. 
Параметр отражает товары, которые были в наличии хотя бы один день 
за выбранный период.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Товаров в наличии',
          postfix: ' шт',
          value: data?.['sku_qty'],
        },
        {
          prefix: 'Товаров с заказами',
          postfix: ' шт',
          value: data?.['active_sku_qty'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SKU_QTY],
    field: 'active_sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Доля товаров с заказами
Оцените количество товаров с хотя бы одним заказом за период. 
Чем больше доля товаров с заказами, тем проще продавать в категории.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Товаров в наличии',
          postfix: ' шт',
          value: data?.['sku_qty'],
        },
        {
          prefix: 'Товаров с заказами',
          postfix: ' шт',
          value: data?.['active_sku_qty'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Оцените среднюю стоимость всех доступных к заказу товаров в 
категории. 
Это помогает определить средний ценовой сегмент в нише. 
Рассчитывается как средняя арифметическая цена всех товаров в
наличии.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
    field: 'avg_order_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена заказанного товара
Оцените среднюю стоимость товаров, которые заказывают покупатели. 
Чем она выше, тем больше они готовы платить. 
Рассчитывается как средняя арифметическая цена всех товаров, 
у которых был хотя бы один заказ за период.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Продавцов
Оцените конкуренцию в категории. 
Чем больше продавцов работает в категории, тем сильнее конкуренция.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SELLERS_QTY],
    field: 'active_sellers_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Продавцов с заказами
Оцените, какая доля продавцов имеет хотя бы один заказ за период. 
Чем больше доля продавцов с заказами, тем выше вероятность, что у 
вас будут продажи. 
Если доля меньше 20%, это может говорить о том, что ниша 
монополизирована и все заказы приходятся на небольшое количество 
продавцов.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_RUB],
    field: 'avg_orders_1seller_rub',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `В среднем заказов у продавца, руб
Оцените сколько в среднем зарабатывает один продавец в категории.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRANDS_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов
Оцените конкуренцию в категории. 
Чем больше брендов в категории, тем она сильнее.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_BRANDS_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов с заказами
Оцените, какая доля брендов имеет хотя бы один заказ за период. 
Чем доля выше, тем менее значима торговая марка для покупателей, 
тем проще продавать noname-бренды.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    field: 'stock_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Остаток
Оцените, насколько склады заполнены товарами данной категории.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_QTY],
    field: 'avg_orders_1sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, шт
Оцените, сколько раз в среднем заказывают один товар в категории. 
Чем выше значение, тем регулярнее будут ваши продажи.`,
    ),
    tooltipValueGetter: (params) =>
      `Один товар заказывали: ${params.value?.toLocaleString(
        'ru',
      )} раз \nОдин товар заказывали: ${params.data['avg_orders_1sku_rub'].toLocaleString('ru')} ₽`,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_RUB],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, руб
Оцените, сколько раз в среднем заказывают один товар в категории. 
Чем выше значение, тем регулярнее будут ваши продажи.`,
    ),
    tooltipValueGetter: (params) =>
      `Один товар заказывали: ${params.data['avg_orders_1sku_qty'].toLocaleString(
        'ru',
      )} раз \nОдин товар заказывали: ${params.value?.toLocaleString('ru')} ₽`,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_RUB],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, руб
Оцените на какую сумму заказов в сутки получают все селлеры в 
категории.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, шт
Оцените на какую сумму заказов в сутки получают все селлеры в 
категории.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_QTY],
    field: 'reviews_qty',
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, `Отзывов`),
    tooltipValueGetter: amountTooltipFormatter('Отзывов'),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING_AVG],
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, `Рейтинг`),
    tooltipValueGetter: amountTooltipFormatter('Рейтинг'),
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
  //     field: 'lost_orders_rub',
  //     cellRendererParams: {
  //       percentKey: 'lost_orders_percent',
  //     },
  //     tooltipValueGetter: (params) =>
  //       `Упущенно заказов: ${params.value?.toLocaleString('ru')} ₽ \nУпущенно заказов: ${
  //         params.data['lost_orders_percent']
  //       } %`,
  //     cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_RUB],
    field: 'potential_rub',
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
];

const generateColumnDefsProductInner = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.TIME],
    initialSort: 'asc',
    headerComponentParams: { sortable: true },
    width: 135,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    headerComponentParams: { sortable: true },
    width: 160,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    headerComponentParams: { sortable: true },
    width: 160,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    headerComponentParams: { sortable: true },
    width: 140,
    valueFormatter: amountFormatter,
  },
  { ...COLUMNS_INFO(props)[COLUMNS.PRICE], headerComponentParams: { sortable: true }, width: 120 },
  {
    ...COLUMNS_INFO(props)[COLUMNS.OLD_PRICE],
    headerComponentParams: { sortable: true },
    width: 180,
  },
];

const generateColumnDefsBrand = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRAND],
    headerName: 'Название бренда',
    field: 'name',
    headerComponentParams: {
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    field: 'orders_rub',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Оцените объем заказов у конкурентов, чтобы найти лидеров 
и изучить их стратегии продаж. 
Анализируйте бренды с оборотом, на которые вы ориентируетесь.
 
Процент - доля заказов бренда от суммы всех заказов
по выбранному фильтру. 
Если большая часть заказов приходится на 3-5 продавцов, то ниша 
считается монополизированной и продавать в ней будет сложнее.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    field: 'orders_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Оцените объем заказов у конкурентов, чтобы найти лидеров в нише 
и изучить их стратегии продаж. 
Анализируйте бренды с оборотом на который вы ориентируетесь. 

Процент — доля заказов от суммы всех заказов по выбранному фильтру`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY],
    field: 'sku_qty',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    cellRendererParams: {
      percentKey: 'active_sku_percent',
    },
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров
Оцените широту ассортимента у продавца. 
Этот показатель помогает определить, насколько разнообразен
ассортимент у конкурентов,и за счет какого количества товаров 
они достигают такого объема заказов.

Процент — доля товаров у которых был хотя бы один заказ.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SKU_QTY],
    field: 'active_sku_qty',
    hide: false,
    headerName: 'Товаров с заказами',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров с заказами
Оцените количество товаров, у которых был хотя бы один заказ за 
период. 
Показатель отражает востребованность товаров продавца.
Чем выше этот показатель, тем лучше составлен ассортимент.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
    headerName: 'Средняя цена товара',
    field: 'avg_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Средняя арифметическая цена товаров, которые были в наличии 
хотя бы один день. 
Показатель отражает средний ценовой сегмент, в котором работает
продавец`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
    headerName: 'Средняя цена заказанного товара',
    hide: false,
    field: 'avg_order_price',
    tooltipValueGetter: (props) =>
      `Средняя цена заказанного товара: ${props.value?.toLocaleString('ru')} ₽`,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена заказанного товара
Показатель отражает среднюю арифметическую цену товаров, 
которые получили хотя бы один заказ. 
Сопоставьте интересы покупателей с ценовой стратегией продавца, 
чтобы определить насколько востребованы товары разных сегментов.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_QTY],
    field: 'sellers_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Продавцов
Оцените, как много продавцов продают товары бренда. 
Чем их больше, тем востребованнее марка и тем сложнее продвигать ее
товары. 
Учитывайте эту информацию при формировании собственного 
ассортимента
Если бренд размещает только один продавец, вероятно, у него 
эксклюзивные права на торговлю брендом на маркетплейсе.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SELLERS_QTY_2],
    field: 'active_sellers_qty',
    hide: false,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Продавцов с заказами
Оцените, сколько продавцов зарабатывают за счет товаров бренда. 
Чем их больше, тем ниже риск монополии.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_RUB],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, руб
Оцените, на какую сумму в сутки продаются товары бренда.
Рассчитывается по формуле: Заказы, руб / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, шт
Оцените, сколько заказов поступает на товары бренда.
Рассчитывается по формуле: Заказы, шт / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_RUB],
    headerName: 'Один товар заказывали в среднем, ₽',
    field: 'avg_orders_1sku_rub',
    hide: false,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, шт
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется
ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_QTY],
    headerName: 'Один товар заказывали в среднем, шт',
    hide: true,
    field: 'avg_orders_1sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, шт
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется
ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
  //     field: 'lost_orders_rub',
  //     cellRendererParams: {
  //       percentKey: 'lost_orders_percent',
  //     },
  //     tooltipValueGetter: (params) =>
  //       `Упущенно заказов: ${params.value?.toLocaleString('ru')} ₽ \nУпущенно заказов: ${
  //         params.data['lost_orders_percent']
  //       } %`,
  //     cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_QTY],
  //     headerName: 'Упущенные заказы, шт',
  //     hide: true,
  //     field: 'lost_orders_qty',
  //     tooltipValueGetter: (params) =>
  //         `Упущенно заказов: ${params.data['lost_orders_rub']?.toLocaleString(
  //             'ru',
  //         )} ₽ \nУпущенно заказов: ${
  //             params.data['lost_orders_percent']
  //         } %\nУпущенно заказов: ${params.value?.toLocaleString('ru')} шт`,
  //     ...headerTooltipDecorator(
  //         props?.showFilterHiddenTooltip,
  //         `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы в шт 10*3 = 30 шт
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_RUB],
    field: 'potential_rub',
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
];

const generateColumnDefsProducts = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.LINK],
    field: 'url',
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.NAME],
    // headerComponentParams: {
    //   settingsIcon: LoopIcon,
    //   settingsFilteredIcon: LoopSuccess,
    // },
    // ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.IMAGE],
    pinned: true,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.INNER_PRODUCT_ID],
    field: 'external_id',
    ...textFilter,
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    valueFormatter: (params) => params.value?.name,
    width: 100,
    tooltipValueGetter: (params) => `Предмет: ${params.value}`,
    cellRendererParams: {
      suppressRightAlign: true,
      leaveEmpty: true,
    },
    ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRAND],
    pinned: false,
    filter: false,
    ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLER],
    pinned: false,
    filter: false,
    ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    field: 'orders_rub',
    initialSort: null,
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    width: 115,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у 
покупателей и лучше планировать свой ассортимент.`,
    ),
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    cellRenderer: null,
    field: 'orders_qty',
    valueFormatter: amountShortcutFormatter,
    width: 120,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у 
покупателей и лучше планировать свой ассортимент.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    tooltipValueGetter: (params) =>
      `Цена: ${(+params.value).toLocaleString('ru')} ₽ \nМинимальная цена: ${(+params.data[
        'price_min'
      ]).toLocaleString('ru')} ₽\nМаксимальная цена: ${(+params.data['price_max']).toLocaleString(
        'ru',
      )} ₽`,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Последняя зафиксированная цена товара, без учета скидки СПП.`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.MIN_PRICE],
    field: 'price_min',
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.MAX_PRICE],
    field: 'price_max',
  },
  {
    field: 'sales_chart',
    headerName: 'График продаж',
    valueGetter: (props) => {
      const { data, colDef } = props;
      const { prefix = 'sales_chart', field } = colDef;
      let resultData = data[field];
      resultData = JSON.parse(resultData);
      return Object.keys(resultData)
        .filter((el) => el.indexOf(prefix) !== -1)
        .map((el) => {
          const date = el.split('/')[1]?.split('-').reverse().join('.');
          return {
            date,
            value: resultData[el],
          };
        });
    },
    cellRenderer: 'agSparklineCellRenderer',
    cellStyle: { position: 'relative' },
    cellRendererParams: {
      sparklineOptions: {
        fill: '#219653',
        tooltip: {
          renderer: (params) => {
            const { yValue, xValue } = params;
            return `<div style="margin-top:${window.scrollY}px" id="${xValue + '_' + yValue}">
              <div id="tooltip-wrapper" class='bg-green-light p-1 rounded border border-green'>
                <div>Продано: ${yValue.toLocaleString('ru')} ₽</div>
                <div>Дата: ${xValue}</div>
              </div>
          </div>`;
          },
        },
        type: 'column',
        xKey: 'date',
        yKey: 'value',
        axis: {
          type: 'string',
        },
      },
    },
  },
  // {
  //   ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES],
  //   field: 'category',
  //   cellRendererParams: {},
  //   ...textFilter,
  // },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES_QTY],
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Кол-во категорий
  // Кол-во категорий в которых зафиксирован товар.`,
  //     ),
  //     tooltipValueGetter: simpleTooltipFormatter('Кол-во категорий'),
  //   },
  //   COLUMNS_INFO(props)[COLUMNS.LISTING],
  {
    headerName: 'Комиссия',
    field: 'commission_percent',
    width: 80,
    valueFormatter: (params) => percentageFormatter({ ...params, avoidChar: true }),
    tooltipValueGetter: percentTooltipFormatter('Комиссия'),
    minWidth: productColMinWidth,
    // ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DAYS_IN_STOCK],
    field: 'days_in_stock',
    hide: true,
    ...rangeFilter,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Дней в наличии
Количество дней, когда товар заказали хотя бы один раз.`,
    ),
  },
  {
    headerName: 'Закончится через, дн',
    field: 'out_of_stock',
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Закончится через, дн
Количество дней, когда товар закончится.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.DAYS_WITH_ORDERS],
    headerName: 'Дней с продажами',
    field: 'days_with_orders',
    ...rangeFilter,
    valueFormatter: (params) => `${params.value} из ${params.data['days_in_period']}`,
    tooltipValueGetter: (params) =>
      `Дней с продажами: ${params.value} из ${params.data['days_in_period']}  \nДней в наличиии: ${params.data['days_in_stock']}`,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Дней с продажами
Количество дней, когда товар заказали хотя бы один раз.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_RUB],
    headerName: 'В среднем заказов, руб',
    field: 'avg_orders_rub',
    ...rangeFilter,
    tooltipValueGetter: (params) => `В среднем заказов: ${params.value?.toLocaleString('ru')} ₽`,
    valueFormatter: currencyFormatter,
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },
  {
    headerName: 'В среднем заказов, шт',
    field: 'avg_orders_qty',
    ...rangeFilter,
    valueFormatter: amountFormatter,
    tooltipValueGetter: (params) => `В среднем заказов: ${params.value?.toLocaleString('ru')} шт`,
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_IF_IN_STOCK_RUB],
    headerName: 'В среднем заказов, когда в наличии, руб',
    ...rangeFilter,
    tooltipValueGetter: (params) =>
      `В среднем заказов, когда в наличии: ${params.value?.toLocaleString(
        'ru',
      )} ₽ \nВ среднем заказов, когда в наличии: ${params.data[
        'avg_orders_if_in_stock_qty'
      ]?.toLocaleString('ru')} шт`,
    valueFormatter: currencyFormatter,
    field: 'avg_orders_if_in_stock_rub',
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_IF_IN_STOCK_QTY],
    headerName: 'В среднем заказов, когда в наличии, шт',
    ...rangeFilter,
    hide: true,
    field: 'avg_orders_if_in_stock_qty',
    valueFormatter: amountFormatter,
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
  //     field: 'lost_orders_rub',
  //     cellRendererParams: {
  //       percentKey: 'lost_orders_percent',
  //     },
  //     tooltipValueGetter: (params) =>
  //       `Упущенно заказов: ${params.value.toLocaleString('ru')} ₽ \nУпущенно заказов: ${
  //         params.data['lost_orders_percent']
  //       } %\nУпущенно заказов: ${params.data['lost_orders_qty'].toLocaleString('ru')} шт`,
  //     cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы в шт 10*3 = 30 шт
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_QTY],
  //     headerName: 'Упущенные заказы, шт',
  //     hide: true,
  //     ...rangeFilter,
  //     field: 'lost_orders_qty',
  //     tooltipValueGetter: (params) =>
  //       `Упущенно заказов: ${params.data['lost_orders_rub'].toLocaleString(
  //         'ru',
  //       )} ₽ \nУпущенно заказов: ${
  //         params.data['lost_orders_percent']
  //       } %\nУпущенно заказов: ${params.value?.toLocaleString('ru')} шт`,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы в шт 10*3 = 30 шт
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_RUB],
    field: 'potential_rub',
    headerName: 'Потенциал, руб',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на 
складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_QTY],
    field: 'potential_qty',
    headerName: 'Потенциал, шт',
    tooltipValueGetter: (params) => `Потенциал: ${params.value?.toLocaleString('ru')} шт`,
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на 
складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_COUNT_LAST],
    field: 'reviews_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Отзывов
Количество отзывов о товаре`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Остаток
Количество товара на складах.`,
    ),
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.DAYS_WITH_SALES],
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Дней с заказами
  // Количество дней, когда на складах был в наличии хотя бы один
  // экземпляр товара.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING],
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Рейтинг
Последний известный рейтинг товара`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DESCRIPTION],
    field: 'words_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Слов в описании
Количество слов в описании товара. 
Максимум — 5000 символов: это примерно 750 слов. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PICTURES],
    headerName: 'Кол-во фотографий',
    field: 'pictures_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Фото
Количество фото в карточке товара. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SPECIFICATION],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Характеристик
Количество заполненных характеристик. 
Показывает насколько полно заполнена информация о товаре. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.VIDEO],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Видео
Наличие видео у товара. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  // {
  //   headerName: 'Склад',
  //   field: 'warehouse_type',
  //   width: 100,
  // },
];

const generateColumnDefsSimilarProducts = (props) => [
  COLUMNS_INFO(props)[COLUMNS.LINK],
  COLUMNS_INFO(props)[COLUMNS.NAME],
  {
    ...COLUMNS_INFO(props)[COLUMNS.IMAGE],
    pinned: true,
  },
  COLUMNS_INFO(props)[COLUMNS.INNER_PRODUCT_ID],
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRAND],
    pinned: false,
    filter: false,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLER],
    pinned: false,
    filter: false,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    initialSort: null,
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    width: 115,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у 
покупателей и лучше планировать свой ассортимент.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    width: 120,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у 
покупателей и лучше планировать свой ассортимент.`,
    ),
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.SOLD_RUB],
  //     cellRenderer: null,
  //     valueFormatter: amountShortcutFormatter,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Купили более, руб
  // WeCheck отслеживает изменения параметра «Купили более» в карточке
  // товара.
  // Он полезен для анализа товаров с типом склада FBS.
  // Wildberries не обновляет этот показатель для товаров, которые купили
  // более 100 000 раз.
  // Поэтому для них «Купили более» = 0.`,
  //     ),
  //     width: 115,
  //   },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.SOLD_QTY],
  //     cellRenderer: null,
  //     valueFormatter: amountShortcutFormatter,
  //     width: 120,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Купили более, шт
  // WeCheck отслеживает изменения параметра «Купили более» в карточке
  // товара.
  // Он полезен для анализа товаров с типом склада FBS.
  // Wildberries не обновляет этот показатель для товаров, которые купили
  // более 100 000 раз.
  // Поэтому для них «Купили более» = 0.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Последняя зафиксированная цена товара, без учета скидки СПП.`,
    ),
    width: 100,
  },
  COLUMNS_INFO(props)[COLUMNS.MIN_PRICE],
  COLUMNS_INFO(props)[COLUMNS.MAX_PRICE],
  COLUMNS_INFO(props)[COLUMNS.CATEGORIES],
  {
    ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Кол-во категорий
Кол-во категорий в которых зафиксирован товар.`,
    ),
    tooltipValueGetter: simpleTooltipFormatter('Кол-во категорий'),
  },
  COLUMNS_INFO(props)[COLUMNS.LISTING],
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_RUB],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на 
складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Упущенные заказы
Заказы, не оплаченные из-за отсутствия товара на складе. 

Пример расчета 
Товар продавался в среднем 10 шт в сутки 
Товар был не в наличии 3 дня 

Упущенные заказы в шт 10*3 = 30 шт
Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_COUNT_LAST],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Отзывов
Количество отзывов о товаре`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Рейтинг
Последний известный рейтинг товара`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Остаток
Количество товара на складах.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DAYS_IN_STOCK],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Дней в наличии
Количество дней, когда товар заказали хотя бы один раз.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DAYS_WITH_SALES],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Дней с заказами
Количество дней, когда на складах был в наличии хотя бы один 
экземпляр товара.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PICTURES],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Фото
Количество фото в карточке товара. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DESCRIPTION],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Слов в описании
Количество слов в описании товара. 
Максимум — 5000 символов: это примерно 750 слов. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SPECIFICATION],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Характеристик
Количество заполненных характеристик. 
Показывает насколько полно заполнена информация о товаре. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.VIDEO],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Видео
Наличие видео у товара. 
Рассмотрите этот показатель, чтобы оценить влияние контента на 
продажи.`,
    ),
  },
];

const generateColumnDefsSales = (props) => ({
  absolute: [
    {
      ...COLUMNS_INFO(props)[COLUMNS.SIMILAR_NAME],
      headerName: 'Дата',
      ...sortDateOnAggrid,
    },
    COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
    {
      ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
      ...rangeFilter,
    },

    {
      headerName: 'SKU',
      field: 'sku_qty',
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля брендов с заказами'),
      ...rangeFilter,
    },
    {
      headerName: 'Брендов',
      field: 'brands_qty',
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля брендов с заказами'),
      ...rangeFilter,
    },
    {
      headerName: 'Продавцов',
      field: 'sellers_qty',
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля брендов с заказами'),
      ...rangeFilter,
    },
  ],
});

const generateColumnDefsSubjects = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.SUBJECT],
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    field: 'orders_rub',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Оцените объем заказов у конкурентов, чтобы найти лидеров 
и изучить их стратегии продаж. 
Анализируйте продавцов с оборотом, на который вы ориентируетесь.
 
Процент — доля заказов продавца от суммы всех заказов по 
выбранному фильтру. 
Если большая часть заказов приходится на 3-5 продавцов, то ниша 
считается монополизированной и продавать в ней будет сложнее.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    field: 'orders_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Оцените объем заказов у конкурентов, чтобы найти лидеров в нише 
и изучить их стратегии продаж. 
Анализируйте продавцов с оборотом на который вы ориентируетесь 

Процент — доля заказов от суммы всех заказов по выбранному фильтру`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY],
    field: 'sku_qty',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    cellRendererParams: {
      percentKey: 'active_sku_percent',
    },
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров
Оцените широту ассортимента у продавца. 
Этот показатель помогает определить, насколько разнообразен
ассортимент у конкурентов,и за счет какого количества товаров 
они достигают такого объема заказов.

Процент — доля товаров у которых был хотя бы один заказ.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SKU_QTY],
    headerName: 'Товаров с заказами',
    hide: false,
    field: 'active_sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров с заказами
Оцените количество товаров, у которых был хотя бы один заказ за 
период. 
Показатель отражает востребованность товаров продавца.
Чем выше этот показатель, тем лучше составлен ассортимент.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
    headerName: 'Цена',
    field: 'avg_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Средняя арифметическая цена товаров, которые были в наличии 
хотя бы один день. 
Показатель отражает средний ценовой сегмент, в котором работает
продавец`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
    headerName: 'Цена заказанного товара',
    hide: false,
    field: 'avg_order_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена заказанного товара
Показатель отражает среднюю арифметическую цену товаров, 
которые получили хотя бы один заказ. 
Сопоставьте интересы покупателей с ценовой стратегией продавца, 
чтобы определить насколько востребованы товары разных сегментов.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_ORDERS_],
    field: 'active_sellers_qty',
    ...rangeFilter,
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_RUB],
    field: 'avg_orders_1seller_rub',
    tooltipValueGetter: (props) =>
      `В среднем заказов у продавца: ${props.data['avg_orders_1seller_rub'].toLocaleString(
        'ru',
      )} ₽`,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_QTY],
    headerName: 'В среднем заказов у продавца, шт',
    tooltipValueGetter: (props) =>
      `В среднем заказов у продавца: ${props.data['avg_orders_1seller_qty'].toLocaleString(
        'ru',
      )} шт`,
    field: 'avg_orders_1seller_qty',
    ...rangeFilter,
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_QTY],
    headerName: 'Продавцы, шт',
    cellRenderer: null,
    width: 170,
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.BRANDS_QTY],
    field: 'brands_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов
Оцените, с каким количеством брендов работает продавец. 
Чем больше брендов, тем более разносторонний ассортимент
реализует продавец 

Процент — доля брендов у которых был хотя бы один заказ.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_BRANDS_QTY],
    headerName: 'Брендов с заказами',
    hide: false,
    field: 'active_brands_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов с заказами
Оцените насколько эффективно собран портфель брендов у продавца. 
Чем больше брендов с заказами, тем востребованней бренды 
подобрал продавец.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY_REAL],
    minWidth: productColMinWidth,
    flex: 1,
    cellRenderer: null,
    valueFormatter: amountFormatter,
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_RUB],
    headerName: 'Один товар заказывали в среднем, ₽',
    field: 'avg_orders_1sku_rub',
    hide: false,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, руб
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_QTY],
    headerName: 'Один товар заказывали в среднем, шт',
    hide: true,
    field: 'avg_orders_1sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, шт
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_RUB],
    field: 'avg_orders_per_day_rub',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, руб
Оцените на какую сумму продавец получает заказов в сутки 
Рассчитывается по формуле: Заказы, руб / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_QTY],
    field: 'avg_orders_per_day_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, шт
Оцените сколько заказов в шт продавец получает в сутки 
Рассчитывается по формуле: Заказы, шт / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_COUNT],
    field: 'reviews_qty',
    minWidth: productColMinWidth,
    flex: 1,
    valueFormatter: amountFormatter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING_AVG],
    width: 90,
    flex: 1,
    ...rangeFilter,
  },
];

const generateColumnDefsSellers = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLER],
    headerName: 'Название продавца',
    field: 'name',
    headerComponentParams: {
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    field: 'orders_rub',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
Оцените объем заказов у конкурентов, чтобы найти лидеров 
и изучить их стратегии продаж. 
Анализируйте продавцов с оборотом, на который вы ориентируетесь.
 
Процент — доля заказов продавца от суммы всех заказов по 
выбранному фильтру. 
Если большая часть заказов приходится на 3-5 продавцов, то ниша 
считается монополизированной и продавать в ней будет сложнее.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    field: 'orders_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
Оцените объем заказов у конкурентов, чтобы найти лидеров в нише 
и изучить их стратегии продаж. 
Анализируйте продавцов с оборотом на который вы ориентируетесь 

Процент — доля заказов от суммы всех заказов по выбранному фильтру`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY],
    field: 'sku_qty',
    tooltipComponent: 'tableCellSellersFrontTooltip',
    tooltipValueGetter: (props) => props,
    cellRendererParams: {
      percentKey: 'active_sku_percent',
    },
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров
Оцените широту ассортимента у продавца. 
Этот показатель помогает определить, насколько разнообразен
ассортимент у конкурентов,и за счет какого количества товаров 
они достигают такого объема заказов.

Процент — доля товаров у которых был хотя бы один заказ.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_SKU_QTY],
    headerName: 'Товаров с заказами',
    hide: false,
    field: 'active_sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Товаров с заказами
Оцените количество товаров, у которых был хотя бы один заказ за 
период. 
Показатель отражает востребованность товаров продавца.
Чем выше этот показатель, тем лучше составлен ассортимент.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
    headerName: 'Средняя цена товара',
    field: 'avg_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
Средняя арифметическая цена товаров, которые были в наличии 
хотя бы один день. 
Показатель отражает средний ценовой сегмент, в котором работает
продавец`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
    headerName: 'Средняя цена заказанного товара',
    hide: false,
    field: 'avg_order_price',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена заказанного товара
Показатель отражает среднюю арифметическую цену товаров, 
которые получили хотя бы один заказ. 
Сопоставьте интересы покупателей с ценовой стратегией продавца, 
чтобы определить насколько востребованы товары разных сегментов.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRANDS_QTY],
    field: 'brands_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов
Оцените, с каким количеством брендов работает продавец. 
Чем больше брендов, тем более разносторонний ассортимент
реализует продавец 

Процент — доля брендов у которых был хотя бы один заказ.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ACTIVE_BRANDS_QTY],
    headerName: 'Брендов с заказами',
    hide: false,
    field: 'active_brands_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Брендов с заказами
Оцените насколько эффективно собран портфель брендов у продавца. 
Чем больше брендов с заказами, тем востребованней бренды 
подобрал продавец.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_RUB],
    field: 'avg_orders_per_day_rub',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, руб
Оцените на какую сумму продавец получает заказов в сутки 
Рассчитывается по формуле: Заказы, руб / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_PER_DAY_QTY],
    field: 'avg_orders_per_day_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов в сутки, шт
Оцените сколько заказов в шт продавец получает в сутки 
Рассчитывается по формуле: Заказы, шт / кол-во дней в периоде.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_RUB],
    headerName: 'Один товар заказывали в среднем, ₽',
    field: 'avg_orders_1sku_rub',
    hide: false,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, руб
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_QTY],
    headerName: 'Один товар заказывали в среднем, шт',
    hide: true,
    field: 'avg_orders_1sku_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказов одного товара, шт
Оцените сколько раз заказывают каждый уникальный товар. 
Чем выше этот показтель, тем более регулярным спросом пользуется ассортимент продавца.`,
    ),
    tooltipValueGetter: ({ data }) => {
      const strings = [
        {
          prefix: 'Один товар заказывали',
          postfix: ' раз',
          value: data?.['avg_orders_1sku_qty'],
        },
        {
          prefix: 'Один товар заказывали',
          postfix: ' руб',
          value: data?.['avg_orders_1sku_rub'],
        },
      ];

      return formatTooltipStrings(strings);
    },
  },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Остаток
  // Оцените в каких объемах пополнены склады товарами продавца.
  // Этот показатель помогает оценить финансовые возможности продавца
  // на закупку товаров.`,
  //     ),
  //   },
  //   {
  //     ...COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
  //     field: 'lost_orders_rub',
  //     cellRendererParams: {
  //       percentKey: 'lost_orders_percent',
  //     },
  //     tooltipValueGetter: (params) =>
  //       `Упущенно заказов: ${params.value?.toLocaleString('ru')} ₽ \nУпущенно заказов: ${
  //         params.data['lost_orders_percent']
  //       } %`,
  //     cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
  //     ...headerTooltipDecorator(
  //       props?.showFilterHiddenTooltip,
  //       `Упущенные заказы
  // Заказы, не оплаченные из-за отсутствия товара на складе.
  //
  // Пример расчета
  // Товар продавался в среднем 10 шт в сутки
  // Товар был не в наличии 3 дня
  //
  // Упущенные заказы, руб = 30 шт*последнюю известную цену товара.`,
  //     ),
  //   },
  {
    ...COLUMNS_INFO(props)[COLUMNS.POTENTIAL_RUB],
    field: 'potential_rub',
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Потенциал
Ожидаемая выручка при условии постоянного наличия товара на складе.
Рассчитывается по формуле: 
Сумма заказов товара + упущенные заказы.`,
    ),
    width: 100,
  },
];

const generateColumnDefsPriceSegment = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.SIMILAR_NAME],
    headerName: '',
    cellRenderer: 'productsPriceRangeLink',
    filter: false,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
    width: 165,
  },
  COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_ORDERS],
    width: 180,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLERS_QTY],
    headerName: 'Продавцы, шт',
    cellRenderer: null,
    width: 170,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLER_ABG_ORDERS],
    width: 180,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SKU_RUB],
    headerName: 'Заказов на товар, ₽',
    valueFormatter: amountShortcutFormatter,
    hide: false,
    width: 180,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRANDS_QTY],
    headerName: 'Бренды, шт',
    cellRenderer: null,
    width: 160,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.BRANDS_WITH_ORDERS],
    width: 220,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY_],
    headerName: 'Товары, шт',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_WITH_ORDERS],
    width: 220,
  },
];

const productColMinWidth = 100;
const generateColumnDefsProduct = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.DATE],
    cellRenderer: 'dateExpandTableCell',
    tooltipValueGetter: () => 'Нажмите на дату, чтобы увидеть детализацию по часам',
    width: 124,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
    minWidth: productColMinWidth,
    valueFormatter: amountShortcutFormatter,
    cellRenderer: null,
    flex: 1,
    initialSort: undefined,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
    minWidth: productColMinWidth,
    valueFormatter: amountShortcutFormatter,
    cellRenderer: null,
    flex: 1,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    minWidth: productColMinWidth,
    flex: 1,
    cellRenderer: null,
    valueFormatter: amountFormatter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    minWidth: productColMinWidth,
    flex: 1,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    field: 'price_before_disc',
    headerName: 'Цена до скидки, ₽',
    minWidth: productColMinWidth,
    flex: 1,
  },
  {
    headerName: 'Размер скидки, %',
    field: 'discount_percent',
    width: 80,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: percentTooltipFormatter('Размер скидки'),
    minWidth: productColMinWidth,
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_COUNT],
    field: 'reviews_qty',
    minWidth: productColMinWidth,
    flex: 1,
    valueFormatter: amountFormatter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING],
    width: 90,
    flex: 1,
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES_QTY],
    field: 'category_qty',
    minWidth: productColMinWidth,
    flex: 1,
  },
];

const generateListing = ({ listing, width = 120, isSearch }) => {
  const result = [];

  listing.forEach((period, i) => {
    const field = `${CELL_PERIOD_PREFIX}${i}`;

    result.push({
      headerName: period,
      field: field,
      width,
      valueFormatter: amountFormatter,
      cellRenderer: 'listingWithPositionTableCell',
      cellRendererParams: {
        isSearch,
        mainField: field,
      },
      headerClass: 'searchDateHeaderCell',
      cellClassRules: isSearch
        ? {
            'bg-orange-100': (params) => params.value?.promotion_type?.id === '1',
            'bg-pink-100': (params) => params.value?.promotion_type?.id === '2',
          }
        : {
            'bg-orange-100': (params) => params.value?.promotion_type?.id === '1',
            'bg-pink-100': (params) => params.value?.promotion_type?.id === '2',
          },
      suppressMenu: true,
      comparator: nullComparator,
      // ...rangeFilter,
    });
  });

  return result;
};

const generateColumnDefsProductListing = (data) => {
  if (!data.chart_dates) return [];

  const result = [COLUMNS_INFO({})[COLUMNS.CATEGORY]];
  const listing = data.chart_dates?.slice()?.reverse() || [];

  const listingResult = generateListing({ listing });

  return [...result, ...listingResult];
};

const generateColumnDefsProductSearch = (data) => {
  if (!data.chart_dates) return [];

  const result = [
    {
      ...COLUMNS_INFO({})[COLUMNS.VERTICAL_HEADER],
      minWidth: 250,
      maxWidth: 400,
      headerComponentParams: {
        settingsIcon: LoopIcon,
        settingsFilteredIcon: LoopSuccess,
      },
    },
    {
      headerName: 'Кластер',
      field: 'cluster',
      minWidth: 210,
      valueFormatter: ({ value }) => value || '-',
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
      cellRendererParams: {
        leaveEmpty: true,
      },
      valueFormatter: amountFormatter,
    },
    COLUMNS_INFO({})[COLUMNS.SKU_QTY_],
    COLUMNS_INFO({})[COLUMNS.AVG_POSITION_BY_KEYWORD],
  ];

  const listing = data.chart_dates?.slice()?.reverse() || [];

  const listingResult = generateListing({ listing, width: 100, isSearch: true });

  return [...result, ...listingResult];
};

const generateColumnDefsProductChanges = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.PERIOD],
    pinned: true,
  },
  COLUMNS_INFO(props)[COLUMNS.TYPE_OF_CHANGE],
  COLUMNS_INFO(props)[COLUMNS.IT_WAS],
  COLUMNS_INFO(props)[COLUMNS.BECAME],
];

const generateColumnDefsProductAdvertising = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.DATE],
    pinned: true,
    width: 110,
  },
  COLUMNS_INFO(props)[COLUMNS.PARSING_TIME],
  COLUMNS_INFO(props)[COLUMNS.ADV_TYPE],
  COLUMNS_INFO(props)[COLUMNS.ADV_MEANING],
  COLUMNS_INFO(props)[COLUMNS.POSITION],
  COLUMNS_INFO(props)[COLUMNS.CPM],
];

const generateColumnDefsDynamic = (props) => ({
  absolute: [
    {
      ...COLUMNS_INFO(props)[COLUMNS.SIMILAR_NAME],
      headerName: 'Дата',
      ...sortDateOnAggrid,
    },
    COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
    {
      ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
    },
    COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
    {
      ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_PRODUCT],
      field: 'sellers_qty',
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_ORDERS_],
      field: 'active_sellers_qty',
      ...rangeFilter,
    },
    {
      headerName: 'Доля продавцов с заказами %',
      field: 'active_sellers_percent',
      width: 80,
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля продавцов с заказами'),
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_RUB],
      field: 'avg_orders_1seller_rub',
      tooltipValueGetter: (props) =>
        `В среднем заказов у продавца: ${props.data['avg_orders_1seller_rub'].toLocaleString(
          'ru',
        )} ₽`,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_QTY],
      headerName: 'В среднем заказов у продавца, шт',
      tooltipValueGetter: (props) =>
        `В среднем заказов у продавца: ${props.data['avg_orders_1seller_qty'].toLocaleString(
          'ru',
        )} шт`,
      field: 'avg_orders_1seller_qty',
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
      headerName: 'Средняя цена товара',
      field: 'avg_price',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
      headerName: 'Цена заказанного товара',
      field: 'avg_order_price',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.BRANDS_WITH_PRODUCTS],
      field: 'brands_qty',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.BRANDS_WITH_ORDER],
      field: 'active_brands_qty',
    },
    {
      headerName: 'Доля брендов с заказами, %',
      field: 'active_brands_percent',
      width: 80,
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля брендов с заказами'),
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.PRODUCTS],
      field: 'sku_qty',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.PRODUCTS_WITH_ORDERS],
      field: 'active_sku_qty',
    },
    {
      headerName: 'Доля товаров с заказами, %',
      field: 'active_sku_percent',
      width: 80,
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: percentTooltipFormatter('Доля товаров с заказами'),
      ...rangeFilter,
    },
  ],
  percent: [
    {
      ...COLUMNS_INFO(props)[COLUMNS.SIMILAR_NAME],
      headerName: '',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    COLUMNS_INFO(props)[COLUMNS.LOST_ORDERS_RUB],
    {
      ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_PRODUCT],
      field: 'sellers_qty',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.SELLERS_WITH_ORDERS_],
      field: 'active_sellers_qty',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      headerName: 'Доля продавцов с заказами %',
      field: 'active_sellers_percent',
      width: 80,
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_RUB],
      field: 'avg_orders_1seller_rub',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_1SELLER_QTY],
      headerName: 'В среднем заказов у продавца, шт',
      field: 'avg_orders_1seller_qty',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_PRICE],
      headerName: 'Средняя цена товара',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
      field: 'avg_price',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDER_PRICE],
      headerName: 'Цена заказанного товара',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
      field: 'avg_order_price',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.BRANDS_WITH_PRODUCTS],
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
      field: 'brands_qty',
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.BRANDS_WITH_ORDER],
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
      field: 'active_brands_qty',
    },
    {
      headerName: 'Доля брендов с заказами, %',
      field: 'active_brands_percent',
      width: 80,
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.PRODUCTS],
      field: 'sku_qty',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.PRODUCTS_WITH_ORDERS],
      field: 'active_sku_qty',
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
    {
      headerName: 'Доля товаров с заказами, %',
      field: 'active_sku_percent',
      width: 80,
      valueFormatter: percentageFormatter,
      tooltipValueGetter: null,
      cellRendererParams: {
        suppressRightAlign: true,
      },
    },
  ],
});

const generateColumnDefsSeoFirst = (props) => [
  COLUMNS_INFO(props)[COLUMNS.PHRASE_NAME],
  COLUMNS_INFO(props)[COLUMNS.COUNT_PRODUCTS],
  COLUMNS_INFO(props)[COLUMNS.FREQUENCY_WB],
];

const generateColumnDefsSeoSecond = (props) => [
  COLUMNS_INFO(props)[COLUMNS.WORD],
  COLUMNS_INFO(props)[COLUMNS.FORMS],
  COLUMNS_INFO(props)[COLUMNS.COUNT],
  COLUMNS_INFO(props)[COLUMNS.FREQUENCY_WB],
];

const generateColumnDefsSeoSecondNested = (props) => [
  COLUMNS_INFO(props)[COLUMNS.SIMILAR_NAME],
  COLUMNS_INFO(props)[COLUMNS.FREQUENCY_WB],
];

const generateColumnDefsSeoClustersPhrase = (props) => [
  COLUMNS_INFO(props)[COLUMNS.PHRASE_NAME],
  COLUMNS_INFO(props)[COLUMNS.ITEM],
  COLUMNS_INFO(props)[COLUMNS.FREQUENCY_WB],
];

const generateColumnDefsSeoSearch = (props) => [
  COLUMNS_INFO(props)[COLUMNS.SEARCH_PHRASE],
  {
    ...COLUMNS_INFO(props)[COLUMNS.SKU_QTY_],
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.FREQUENCY_WB],
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.DEMAND_INDEX],
    tooltipValueGetter: () => 'Отношение частотности запросов к кол-ву товаров',
    ...rangeFilter,
  },
];

const generateColumnDefsComparison = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.IMAGE],
    pinned: true,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.NAME],
    headerComponentParams: {
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
    ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.INNER_PRODUCT_ID],
    field: 'external_id',
    ...textFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SUBJECT],
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.SELLER],
    pinned: false,
    filter: false,
    ...textFilter,
  },
  {
    headerName: 'Цвет',
    field: COLUMNS.COLOR,
    filter: 'searchFilter',
    cellStyle: { textAlign: 'left' },
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES],
    field: 'category',
    cellRendererParams: {},
    ...textFilter,
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.DAYS_IN_STOCK],
    field: 'days_in_stock',
    ...rangeFilter,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Дней в наличии
  Количество дней, когда товар заказали хотя бы один раз.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB_],
    initialSort: null,
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    width: 115,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, руб
  Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у
  покупателей и лучше планировать свой ассортимент.`,
    ),
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY_],
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    width: 120,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Заказы, шт
  Изучайте ТОПы продаж, чтобы понимать какие товары востребованы у
  покупателей и лучше планировать свой ассортимент.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_IF_IN_STOCK_RUB],
    headerName: 'В среднем заказов, когда в наличии, руб',
    ...rangeFilter,
    tooltipValueGetter: (params) =>
      `В среднем заказов, когда в наличии: ${params.value?.toLocaleString(
        'ru',
      )} ₽ \nВ среднем заказов, когда в наличии: ${params.data[
        'avg_orders_if_in_stock_qty'
      ]?.toLocaleString('ru')} шт`,
    valueFormatter: currencyFormatter,
    field: 'avg_orders_if_in_stock_rub',
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.AVG_ORDERS_IF_IN_STOCK_QTY],
    headerName: 'В среднем заказов, когда в наличии, шт',
    ...rangeFilter,
    hide: true,
    field: 'avg_orders_if_in_stock_qty',
    valueFormatter: amountFormatter,
    ...headerTooltipDecorator(props?.showFilterHiddenTooltip, ``),
  },

  {
    ...getChartsColumn('sales_chart', (params) => {
      const { yValue, xValue } = params;
      return `<div>
                  <div>Продано: ${yValue.toLocaleString('ru')} ₽</div>
                  <div>Дата: ${xValue}</div>
            </div>`;
    }),
    field: 'sales_chart',
    headerName: 'График продаж',
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.STOCK_QTY],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Остаток
  Количество товара на складах.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.TURNOVER],
    minWidth: productColMinWidth,
    flex: 1,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.WAREHOUSES_QTY],
    minWidth: productColMinWidth,
    flex: 1,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRODUCTS_SIMILAR_QTY],
    field: 'products_similar_qty',
    minWidth: productColMinWidth,
    flex: 1,
  },

  {
    ...getChartsColumn('price_chart', (params) => {
      const { yValue, xValue } = params;
      return `<div>
                  <div>Цена: ${yValue.toLocaleString('ru')} ₽</div>
                  <div>Дата: ${xValue}</div>
            </div>`;
    }),
    field: 'price_chart',
    headerName: 'График цены',
  },
  {
    ...getChartsColumn('stock_chart', (params) => {
      const { yValue, xValue } = params;
      return `<div>
                  <div>Остатков: ${yValue.toLocaleString('ru')} шт</div>
                  <div>Дата: ${xValue}</div>
            </div>`;
    }),
    field: 'stock_chart',
    headerName: 'График остатков',
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    field: 'price_before_disc',
    headerName: 'Цена до скидки, ₽',
    minWidth: productColMinWidth,
    flex: 1,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PRICE],
    tooltipValueGetter: (params) =>
      `Цена: ${(+params.value).toLocaleString('ru')} ₽ \nМинимальная цена: ${(+params.data[
        'price_min'
      ]).toLocaleString('ru')} ₽\nМаксимальная цена: ${(+params.data['price_max']).toLocaleString(
        'ru',
      )} ₽`,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Цена
  Последняя зафиксированная цена товара, без учета скидки СПП.`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.RATING],
    hide: true,
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Рейтинг
  Последний известный рейтинг товара`,
    ),
    width: 100,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.REVIEWS_COUNT_LAST],
    field: 'reviews_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Отзывов
  Количество отзывов о товаре`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.PICTURES],
    headerName: 'Кол-во фотографий',
    field: 'pictures_qty',
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Фото
  Количество фото в карточке товара.
  Рассмотрите этот показатель, чтобы оценить влияние контента на
  продажи.`,
    ),
  },

  {
    ...COLUMNS_INFO(props)[COLUMNS.VIDEO],
    ...headerTooltipDecorator(
      props?.showFilterHiddenTooltip,
      `Видео
  Наличие видео у товара.
  Рассмотрите этот показатель, чтобы оценить влияние контента на
  продажи.`,
    ),
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.CATEGORIES_QTY],
    minWidth: productColMinWidth,
  },
  {
    ...COLUMNS_INFO(props)[COLUMNS.CATEGORY_CHARTS],
  },
  //
];

const COLUMNS_PRODUCT_WAREHOUSES_SIZES = ({ percentKey }) => ({
  [COLUMNS_PRODUCT.ORDERS_RUB]: {
    headerName: 'Заказы, ₽',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Заказы'),
    width: 100,
    initialSort: 'desc',
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.ORDERS_QTY]: {
    headerName: 'Заказы, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Заказы'),
    width: 100,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.STOCK_RUB]: {
    headerName: 'Остатки, ₽',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Остаток товара'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.STOCK_QTY]: {
    headerName: 'Остатки, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Остаток товара'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.STOCK_BEFORE_OUT]: {
    headerName: 'Осталось товара на дней',
    cellRenderer: null,
    tooltipValueGetter: avoidPostfixTooltipFormatter('Осталось товара на дней'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.AVG_ORDERS_PER_DAY_RUB]: {
    headerName: 'В сутки, ₽',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Заказов в сутки'),
    width: 80,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.AVG_ORDERS_PER_DAY_QTY]: {
    headerName: 'В сутки, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Заказов в сутки'),
    width: 80,
    hide: true,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.IN_STOCK_RUB]: {
    headerName: 'Когда в наличии, ₽',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Заказов в сутки, когда в наличии'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.IN_STOCK_QTY]: {
    headerName: 'Когда в наличии, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Заказов в сутки, когда в наличии'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.MISSED_RUB]: {
    headerName: 'Упущено, ₽',
    valueFormatter: amountShortcutFormatter,
    cellRenderer: AMOUNT_WITH_PERCENT_TABLE_CELL_NAME,
    cellRendererParams: {
      percentKey,
    },
    tooltipValueGetter: currencyWithPercentTooltipFormatter('Упущено заказов', 'Упущено заказов'),
    width: 120,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.MISSED_QTY]: {
    headerName: 'Упущено, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountWithPercentTooltipFormatter('Упущено заказов на', 'Упущено заказов'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.MISSED_PERCENT]: {
    headerName: 'Упущено, %',
    width: 80,
    hide: true,
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: percentTooltipFormatter('Упущено заказов'),
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.POTENTIAL_RUB]: {
    headerName: 'Потенциал, ₽',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Потенциал'),
    width: 80,
    ...rangeFilter,
  },
  [COLUMNS_PRODUCT.POTENTIAL_QTY]: {
    headerName: 'Потенциал, шт',
    cellRenderer: null,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter('Потенциал'),
    width: 80,
    ...rangeFilter,
  },
});

const generateColumnDefsProductWarehousesSizesTabs = ({
  keys = {},
  tooltipKeyWord = '',
  percentKey,
}) => {
  const columns = COLUMNS_PRODUCT_WAREHOUSES_SIZES({ tooltipKeyWord, percentKey });
  const keysCommon = Object.keys(keys);
  const keysCurrent = Object.values(keys);

  return keysCurrent.map((key, index) => ({
    ...columns[keysCommon[index]],
    field: key,
  }));
};

const generateColumnDefsProductWarehouses = (props) => {
  const keys = {
    [COLUMNS_PRODUCT.ORDERS_RUB]: 'orders_rub',
    [COLUMNS_PRODUCT.ORDERS_QTY]: 'orders_qty',
    [COLUMNS_PRODUCT.STOCK_RUB]: 'stock_rub',
    [COLUMNS_PRODUCT.STOCK_QTY]: 'stock_qty',
    [COLUMNS_PRODUCT.STOCK_BEFORE_OUT]: 'days_before_stock_out',
    [COLUMNS_PRODUCT.IN_STOCK_RUB]: 'avg_orders_if_in_stock_rub',
    [COLUMNS_PRODUCT.IN_STOCK_QTY]: 'avg_orders_if_in_stock_qty',
    [COLUMNS_PRODUCT.MISSED_RUB]: 'lost_orders_rub',
    [COLUMNS_PRODUCT.MISSED_QTY]: 'lost_orders_qty',
    [COLUMNS_PRODUCT.MISSED_PERCENT]: 'lost_orders_percent',
    [COLUMNS_PRODUCT.POTENTIAL_RUB]: 'potential_rub',
    [COLUMNS_PRODUCT.POTENTIAL_QTY]: 'potential_qty',
  };

  return [
    {
      headerName: 'Склад',
      field: 'name',
      width: 200,
      cellRenderer: 'productVariationTableCell',
    },
    ...generateColumnDefsProductWarehousesSizesTabs({
      keys,
      percentKey: COLUMNS.WAREHOUSES__MISSED_PERCENT,
      ...props,
    }),
  ];
};

const generateColumnDefsSalesWarehouses = (props) => {
  const keys = {
    [COLUMNS_PRODUCT.ORDERS_RUB]: COLUMNS.WAREHOUSES__ORDERS_RUB,
    [COLUMNS_PRODUCT.ORDERS_QTY]: COLUMNS.WAREHOUSES__ORDERS_QTY,
    [COLUMNS_PRODUCT.STOCK_RUB]: COLUMNS.WH__STOCK_RUB,
    [COLUMNS_PRODUCT.STOCK_QTY]: COLUMNS.WH__STOCK_QTY,
    [COLUMNS_PRODUCT.AVG_ORDERS_PER_DAY_RUB]: COLUMNS.WAREHOUSES__AVG_ORDERS_PER_DAY_RUB,
    [COLUMNS_PRODUCT.AVG_ORDERS_PER_DAY_QTY]: COLUMNS.WAREHOUSES__AVG_ORDERS_PER_DAY_QTY,
    [COLUMNS_PRODUCT.IN_STOCK_RUB]: COLUMNS.WAREHOUSES__IN_STOCK_RUB,
    [COLUMNS_PRODUCT.IN_STOCK_QTY]: COLUMNS.WAREHOUSES__IN_STOCK_QTY,
    [COLUMNS_PRODUCT.MISSED_RUB]: COLUMNS.WAREHOUSES__MISSED_RUB,
    [COLUMNS_PRODUCT.MISSED_QTY]: COLUMNS.WAREHOUSES__MISSED_QTY,
    [COLUMNS_PRODUCT.MISSED_PERCENT]: COLUMNS.WAREHOUSES__MISSED_PERCENT,
    [COLUMNS_PRODUCT.POTENTIAL_RUB]: COLUMNS.WAREHOUSES__POTENTIAL_RUB,
    [COLUMNS_PRODUCT.POTENTIAL_QTY]: COLUMNS.WAREHOUSES__POTENTIAL_QTY,
  };

  return [
    {
      headerName: 'Склады',
      field: COLUMNS.CUSTOM_TITLE,
      width: 200,
      cellRenderer: 'productVariationTableCell',
    },
    ...generateColumnDefsProductWarehousesSizesTabs({
      keys,
      percentKey: COLUMNS.WAREHOUSES__MISSED_PERCENT,
      ...props,
    }),
  ];
};

const generateColumnDefsProductSizes = (props) => {
  const keys = { ...COMMON_KEYS };

  return [
    {
      headerName: 'Размер',
      field: 'name',
      width: 200,
      cellRenderer: 'productVariationTableCell',
    },
    ...generateColumnDefsProductWarehousesSizesTabs({
      keys,
      percentKey: COLUMNS.MISSED_BY_SIZES_PERCENT,
      ...props,
    }),
  ];
};

const generateColumnDefsProductColors = (props) => {
  const keys = { ...COMMON_KEYS };

  return [
    {
      headerName: '',
      field: 'id',
      width: 42,
      cellRenderer: 'productVariationCircleTableCell',
      headerComponent: () => <DonutIcon />,
      headerComponentParams: { sortable: false },
      suppressMenu: true,
    },
    {
      ...COLUMNS_INFO(props)[COLUMNS.IMAGE],
      field: 'image',
      suppressMenu: true,
    },
    {
      headerName: 'Атрибут',
      field: 'colors',
      width: 200,
      cellStyle: { textAlign: 'left' },
      // cellRenderer: 'productVariationLinkTableCell',
    },

    ...generateColumnDefsProductWarehousesSizesTabs({
      keys,
      percentKey: COLUMNS.MISSED_BY_COLORS_PERCENT,
      ...props,
    }),
  ];
};

const generateColumnDefsGrowth = (props) => [
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_RUB],
    cellRenderer: null,
  },
  COLUMNS_INFO(props)[COLUMNS.SOLD_MONEY_BASIS],
  COLUMNS_INFO(props)[COLUMNS.SOLD_MONEY_GROWTH],
  {
    ...COLUMNS_INFO(props)[COLUMNS.ORDERS_QTY],
    cellRenderer: null,
  },
  COLUMNS_INFO(props)[COLUMNS.SOLD_NUMBER_BASIS],
  COLUMNS_INFO(props)[COLUMNS.SOLD_NUMBER_GROWTH],
  COLUMNS_INFO(props)[COLUMNS.PRODUCTS_NUMBER],
  COLUMNS_INFO(props)[COLUMNS.PRODUCTS_BASIS],
  COLUMNS_INFO(props)[COLUMNS.PRODUCTS_GROWTH],
  COLUMNS_INFO(props)[COLUMNS.SELLERS_NUMBER],
  COLUMNS_INFO(props)[COLUMNS.SELLERS_BASIS],
  COLUMNS_INFO(props)[COLUMNS.SELLERS_GROWTH],
];

const generateColumnDefsGrowthSellers = (props) => [
  COLUMNS_INFO(props)[COLUMNS.SELLER],
  ...generateColumnDefsGrowth(props),
];

const generateColumnDefsGrowthBrands = (props) => [
  COLUMNS_INFO(props)[COLUMNS.BRAND],
  ...generateColumnDefsGrowth(props),
];

const generateColumnDefsGrowthProducts = (props) => [
  COLUMNS_INFO(props)[COLUMNS.NAME],
  ...generateColumnDefsGrowth(props),
];

const generateGridReferralsColumns = () => [
  {
    headerName: 'Дата',
    headerClass: 'table_first_column_padding',
    field: 'register_date',
    width: 140,
    headerComponentParams: { sortable: false, cellStyle: { textAlign: 'center' } },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellStyle: { paddingLeft: 30 },
  },
  {
    headerName: 'Пользователь',
    field: 'email',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    flex: 1,
    minWidth: 140,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
];

const generateGridReferralsPaymentColumns = () => [
  {
    headerName: 'Дата',
    headerClass: 'table_first_column_padding',
    field: 'date',
    width: 140,
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellStyle: { paddingLeft: 30 },
  },
  {
    headerName: 'Пользователь',
    field: 'user',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Тариф',
    field: 'tariff',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Период',
    field: 'period',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Сумма',
    field: 'bonus',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Начислено',
    field: 'total',
    headerComponentParams: { sortable: false },
    suppressMenu: true,
    flex: 1,
    minWidth: 140,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
];

const generateSeoCompareProductsColumns = (props = {}) => [
  {
    headerName: props.isA ? 'Группа А' : 'Группа B',
    children: [
      {
        ...COLUMNS_INFO(props)[COLUMNS.LINK],
        headerName: 'URL',
        field: 'link',
        columnGroupShow: 'open',
        width: 84,
        pinned: false,
        suppressMenu: true,
      },
      {
        ...COLUMNS_INFO(props)[COLUMNS.IMAGE],
        field: 'images',
        columnGroupShow: 'open',
        tooltipValueGetter: (props) => ({ ...props, isSeo: true }),
        pinned: false,
        suppressMenu: true,
      },
      {
        ...COLUMNS_INFO(props)[COLUMNS.EXTERNAL_ID],
        field: 'external_id',
        columnGroupShow: 'open',
        headerName: 'Артикул',
        width: 110,
        pinned: false,
        suppressMenu: true,
      },
      {
        ...COLUMNS_INFO(props)[COLUMNS.NAME],
        field: 'name',
        columnGroupShow: 'open',
        cellRendererParams: {
          idKey: 'id',
          withoutFavorite: true,
        },
        pinned: false,
        minWidth: 100,
        flex: 1,
        suppressMenu: true,
      },
    ],
  },
];

const generateSeoCompareResultsColumns = () => [
  {
    headerName: 'Поисковая фраза',
    headerClass: 'table_first_column_padding',
    field: 'name',
    headerComponentParams: {
      sortable: false,
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellStyle: { paddingLeft: 30 },
    ...textFilter,
    flex: 1,
  },
  {
    headerName: 'Товаров, шт',
    field: 'sku_qty',
    width: 215,
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Товаров, шт'),
    ...sortable,
  },
  {
    headerName: 'Частотность WB',
    field: 'frequency_wb',
    valueFormatter: amountFormatter,
    tooltipValueGetter: avoidPostfixTooltipFormatter('Частотность Wildberries'),
    flex: 1,
    cellStyle: { paddingRight: 30 },
    ...sortable,
  },
];

const generateSeoMonitoringResultsColumns = (data) => {
  const resultColumns = [
    {
      headerName: 'Поисковая фраза',
      headerClass: 'table_first_column_padding',
      field: 'name',
      width: 190,
      headerComponentParams: {
        sortable: false,
        settingsIcon: LoopIcon,
        settingsFilteredIcon: LoopSuccess,
      },
      cellRendererParams: {
        suppressRightAlign: true,
      },
      pinned: true,
      cellStyle: { paddingLeft: 30 },
      ...textFilter,
    },
    {
      headerName: 'Товаров, шт',
      field: 'sku_qty',
      valueFormatter: amountShortcutFormatter,
      tooltipValueGetter: amountTooltipFormatter('Товаров'),
      headerComponentParams: {
        sortable: true,
      },
      width: 85,
      ...rangeFilter,
    },
    {
      ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
      headerComponentParams: {
        sortable: true,
      },
      cellRendererParams: {
        leaveEmpty: true,
      },
      valueFormatter: amountFormatter,
      tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
      width: 90,
      ...rangeFilter,
    },
  ];
  const dates = data?.[0]?.dates || [];
  dates
    .slice()
    ?.reverse()
    .forEach((period, index) => {
      resultColumns.push({
        headerName: period.created_at.split('-').reverse().slice(0, 2).join('.'),
        field: `${index}_sort_position`,
        width: 90,
        valueFormatter: amountFormatter,
        cellRendererParams: {
          // leaveEmpty: false,
          mainField: period.created_at,
        },
        cellRenderer: 'monitoringPhrases',
        comparator: nullComparator,
        ...rangeFilter,
      });
    });
  return resultColumns;
};

const generateSeoByPhraseResultsColumns = (data) => {
  const resultColumns = [
    {
      ...COLUMNS_INFO({})[COLUMNS.LINK],
      headerName: 'URL',
      field: 'link',
      columnGroupShow: 'open',
      width: 84,
      pinned: false,
      suppressMenu: true,
    },
    { ...COLUMNS_INFO({})[COLUMNS.IMAGE], field: 'images' },
    {
      ...COLUMNS_INFO({})[COLUMNS.NAME],
      pinned: false,
      headerComponentParams: {
        settingsIcon: LoopIcon,
        settingsFilteredIcon: LoopSuccess,
        sortable: true,
      },
      cellRendererParams: {
        suppressRightAlign: true,
        withoutFavorite: true,
      },
      ...textFilter,
    },
    { ...COLUMNS_INFO({})[COLUMNS.INNER_PRODUCT_ID], field: 'external_id' },
    { ...COLUMNS_INFO({})[COLUMNS.ORDERS_RUB], field: 'sum_orders_rub', cellRenderer: null },
    // {
    //   headerName: 'График продаж',
    //   field: 'orders_rub',
    //   width: 200,
    //   cellRenderer: 'seoChartOrdersRub',
    // },
    {
      field: 'orders_rub',
      headerName: 'График продаж',
      valueGetter: (props) => {
        const { data, colDef } = props;
        const { prefix = 'byPhraseChart' } = colDef;
        let resultData = data;
        return Object.keys(resultData)
          .filter((el) => el.indexOf(prefix) !== -1)
          .map((el) => {
            const date = el.split('/')[1]?.split('-').reverse().join('.');
            return {
              date,
              value: resultData[el],
            };
          });
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellStyle: { position: 'relative' },
      cellRendererParams: {
        sparklineOptions: {
          fill: '#219653',
          tooltip: {
            renderer: (params) => {
              const { yValue, xValue } = params;
              return `<div style="margin-top:${window.scrollY}px">
              <div class='bg-green-light p-1 rounded border border-green'>
                <div>Продано: ${yValue?.toLocaleString('ru')} ₽</div>
                <div>Дата: ${xValue}</div>
              </div>
          </div>`;
            },
          },
          type: 'column',
          xKey: 'date',
          yKey: 'value',
          axis: {
            type: 'string',
          },
        },
      },
    },
  ];
  const positions = data?.[0]?.positions || [];
  positions
    .slice()
    ?.reverse()
    .forEach((period) => {
      resultColumns.push({
        headerName: period.date.split('-').reverse().slice(0, 2).join('.'),
        field: period.date,
        width: 120,
        valueFormatter: amountFormatter,
        cellRendererParams: {
          mainField: period.date,
        },
        cellRenderer: 'monitoringPhrases',
        comparator: nullComparator,
        ...rangeFilter,
      });
    });
  return resultColumns;
};

const generateSeoHintsRequests = () => [
  {
    headerName: 'Поисковая фраза',
    field: 'phrase',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 300,
    cellClass: 'no-selection',
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 600,
    ...rangeFilter,
  },
];

const generateSeoSmartRequests = () => [
  {
    headerName: 'Поисковая фраза',
    field: 'phrase_name',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: {
      sortable: false,
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
    width: 294,
    flex: 1,
    ...textFilter,
  },
  {
    headerName: 'Товаров, шт',
    field: 'count_products',
    width: 90,
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Товаров, шт'),
    ...sortable,
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency_wb',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 90,
    ...rangeFilter,
  },
];

const generateSeoHintsRequestsInner = () => [
  {
    headerName: 'Поисковая фраза',
    field: 'phrase',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 300,
    cellClass: 'no-selection',
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 300,
    ...rangeFilter,
  },
];
const generateSeoSmartWordsInner = () => [
  {
    headerName: 'Поисковая фраза',
    field: 'name',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: {
      sortable: false,
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
    minWidth: 500,
    flex: 1,
    ...textFilter,
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency_wb',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 90,
    flex: 1,
    ...rangeFilter,
  },
];

const generateSeoProductCheckInner = () => [
  {
    headerName: 'Текст фразы',
    field: 'name',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 300,
    cellClass: 'no-selection',
  },
  // {
  //   headerName: 'Пресет',
  //   field: 'id',
  //   cellRenderer: 'tableCellSeoPhraseNested',
  //   cellRendererParams: {
  //     suppressRightAlign: true,
  //   },
  //   headerComponentParams: { sortable: false },
  //   cellClass: 'no-selection',
  // },
  {
    headerName: 'Кластер',
    field: 'cluster',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Частотность ВБ',
    field: 'frequency',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Есть в карточке',
    field: 'check_in_card',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Есть в индексe',
    field: 'check_in_index_fields',
    cellRenderer: 'tableCellBoolean',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Описание',
    field: 'check_in_description',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Название',
    field: 'check_in_name',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Характеристики',
    field: 'check_in_characteristics',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Цвет',
    field: 'check_in_color',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Комплектация',
    field: 'check_in_equipment',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
  {
    headerName: 'Бренд',
    field: 'check_in_brand',
    cellRenderer: 'tableCellSeoProductCheckAppearance',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    cellClass: 'no-selection',
  },
];

const generateSeoHintsWordsInner = () => [
  {
    headerName: 'Поисковая фраза',
    field: 'name',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 300,
    cellClass: 'no-selection',
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency_wb',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 300,
    ...rangeFilter,
  },
];

const generateSeoHintsWords = () => [
  {
    headerName: 'Слова',
    field: 'word',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 300,
    cellClass: 'no-selection',
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    headerName: 'Кол-во повторений в подсказках',
    field: 'count',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Кол-во повторений'),
    width: 300,
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency_wb',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 300,
    ...rangeFilter,
  },
];

const generateSeoSmartWords = () => [
  {
    headerName: 'Слова',
    field: 'word',
    cellRenderer: 'tableCellSeoPhraseNested',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: {
      sortable: false,
      settingsIcon: LoopIcon,
      settingsFilteredIcon: LoopSuccess,
    },
    width: 294,
    flex: 1,
    ...textFilter,
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    headerName: 'Кол-во повторений в подсказках',
    field: 'count',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Кол-во повторений'),
    width: 90,
    ...rangeFilter,
  },
  {
    ...COLUMNS_INFO({})[COLUMNS.FREQUENCY_WB],
    field: 'frequency_wb',
    headerComponentParams: {
      sortable: true,
    },
    cellRendererParams: {
      leaveEmpty: true,
    },
    valueFormatter: amountFormatter,
    tooltipValueGetter: amountTooltipFormatter('Частотность Wildberries'),
    width: 90,
    ...rangeFilter,
  },
];

const generateGridColumnsInner = ({
  type,
  data,
  hideSoldColumn = false,
  isSellerCellClickable = true,
  showFilterHiddenTooltip = false,
}) => {
  switch (type) {
    case TABLE_TYPE.CATEGORIES:
      return generateColumnDefsCategories({
        hideSoldColumn,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.BRANDS:
      return generateColumnDefsBrand({
        hideSoldColumn,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.SELLERS:
      return generateColumnDefsSellers({
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.SUBJECTS:
      return generateColumnDefsSubjects({
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.PRODUCTS:
      return generateColumnDefsProducts({
        tooltipKeyWord: 'товаров',
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.PRODUCT_SIMILAR:
      return generateColumnDefsSimilarProducts({
        tooltipKeyWord: 'товаров',
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.SALES_BY_DAY:
      return generateColumnDefsSales({ tooltipKeyWord: 'продаж' });
    case TABLE_TYPE.PRODUCT_DAYS:
    case TABLE_TYPE.PRODUCT_SALES:
      return generateColumnDefsProduct({ hideSoldColumn });
    case TABLE_TYPE.PRODUCT_LISTING:
      return generateColumnDefsProductListing(data);
    case TABLE_TYPE.PRODUCT_SALES_INNER:
      return generateColumnDefsProductInner({ data });
    case TABLE_TYPE.PRODUCT_SEARCH:
      return generateColumnDefsProductSearch(data);
    case TABLE_TYPE.PRODUCT_CHANGES:
      return generateColumnDefsProductChanges(data);
    case TABLE_TYPE.PRODUCT_ADVERTISING:
      return generateColumnDefsProductAdvertising(data);
    case TABLE_TYPE.DYNAMIC:
      return generateColumnDefsDynamic({ data });
    case TABLE_TYPE.PRICE_SEGMENT:
      return generateColumnDefsPriceSegment(data);
    case TABLE_TYPE[TAB_FIRST]:
      return generateColumnDefsSeoFirst(data);
    case TABLE_TYPE[TAB_SECOND]:
      return generateColumnDefsSeoSecond(data);
    case TABLE_TYPE[`${TAB_SECOND}${NESTED}`]:
      return generateColumnDefsSeoSecondNested(data);

    case TABLE_TYPE.SALES_WAREHOUSES:
      return generateColumnDefsSalesWarehouses({
        hideSoldColumn,
      });
    case TABLE_TYPE.PRODUCT_WAREHOUSES:
      return generateColumnDefsProductWarehouses({
        hideSoldColumn,
      });
    case TABLE_TYPE.PRODUCT_COLORS:
      return generateColumnDefsProductColors({
        hideSoldColumn,
      });
    case TABLE_TYPE.PRODUCT_SIZES:
      return generateColumnDefsProductSizes({
        hideSoldColumn,
      });
    case TABLE_TYPE.BRANDS_GROWTH:
      return generateColumnDefsGrowthBrands({
        tooltipKeyWord: 'бренда',
        hideSoldColumn,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.CATEGORIES_GROWTH:
      return generateColumnDefsGrowth({
        hideSoldColumn,
      });
    case TABLE_TYPE.SELLERS_GROWTH:
      return generateColumnDefsGrowthSellers({
        tooltipKeyWord: 'продавца',
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.PRODUCTS_GROWTH:
      return generateColumnDefsGrowthProducts({
        tooltipKeyWord: 'товаров',
        hideSoldColumn,
        isSellerCellClickable,
        showFilterHiddenTooltip,
      });
    case TABLE_TYPE.REFERRALS_REGISTRATIONS:
      return generateGridReferralsColumns();
    case TABLE_TYPE.REFERRALS_PAYMENT:
      return generateGridReferralsPaymentColumns();
    case TABLE_TYPE.SEO_COMPARE_A_PRODUCTS:
      return generateSeoCompareProductsColumns({ isA: true });
    case TABLE_TYPE.SEO_COMPARE_B_PRODUCTS:
      return generateSeoCompareProductsColumns();
    case TABLE_TYPE.SEO_COMPARE_RESULTS:
      return generateSeoCompareResultsColumns();
    case TABLE_TYPE.SEO_MONITORING_RESULTS:
      return generateSeoMonitoringResultsColumns(data);
    case TABLE_TYPE.SEO_BY_PHRASE_RESULTS:
      return generateSeoByPhraseResultsColumns(data);
    case TABLE_TYPE.SEO_HINTS_REQUESTS:
      return generateSeoHintsRequests();
    case TABLE_TYPE.SEO_HINTS_REQUESTS_INNER:
      return generateSeoHintsRequestsInner();
    case TABLE_TYPE.SEO_HINTS_WORDS:
      return generateSeoHintsWords();
    case TABLE_TYPE.SEO_SMART_WORDS:
      return generateSeoSmartWords();
    case TABLE_TYPE.SEO_SMART_REQUESTS:
      return generateSeoSmartRequests();
    case TABLE_TYPE.SEO_SMART_WORDS_INNER:
      return generateSeoSmartWordsInner();
    case TABLE_TYPE.SEO_PRODUCT_CHECK_RESULTS:
      return generateSeoProductCheckInner();
    case TABLE_TYPE.SEO_HINTS_WORDS_INNER:
      return generateSeoHintsWordsInner();
    case TABLE_INNER_TYPE.PRODUCTS:
      return innerTableTooltipWrapper(columnDefsInnerProducts);
    case TABLE_INNER_TYPE.PRODUCTS_SETTINGS:
      return innerTableTooltipWrapper(columnDefsInnerProductsSettings);
    case TABLE_INNER_TYPE.IN_DAYS:
      return innerTableTooltipWrapper(columnDefsInnerInDays);
    case TABLE_INNER_TYPE.SALES:
      return innerTableTooltipWrapper(columnDefsInnerSales);
    case TABLE_INNER_TYPE.WEEKS:
      return innerTableTooltipWrapper(columnDefsInnerWeeks);
    case TABLE_INNER_TYPE.IN_DAYS_2:
      return innerTableTooltipWrapper(columnDefsInnerInDays2);
    case TABLE_INNER_TYPE.RATINGS:
      return innerTableTooltipWrapper(columnDefsInnerRatings);
    case TABLE_INNER_TYPE.ORDERS_SALES:
      return innerTableTooltipWrapper(columnDefsInnerOrdersSales);
    case TABLE_INNER_TYPE.PRICES_TAB:
      return innerTableTooltipWrapper(columnDefsInnerPriesTab);
    case TABLE_INNER_TYPE.PRODUCT_WAREHOUSES:
    case TABLE_INNER_TYPE.WAREHOUSES:
      return innerTableTooltipWrapper(columnDefsInnerWarehouses);
    case TABLE_TYPE.ADVERTISING_RATES:
      return innerTableTooltipWrapper(columnDefsAdvertisingRates);
    case TABLE_INNER_TYPE.DELIVERY:
      return innerTableTooltipWrapper(columnsDefsInnerDelivery);
    case TABLE_INNER_TYPE.PRODUCT_SIZES:
      return innerTableTooltipWrapper(columnDefsInnerProductSizes);
    case TABLE_TYPE.SEO_CLUSTERS_PHRASE_RESULTS:
      return generateColumnDefsSeoClustersPhrase(data);
    case TABLE_TYPE.SEO_CLUSTERS_ARTICLE_RESULTS:
      return generateColumnDefsSeoClustersPhrase(data);
    case TABLE_TYPE.SEO_SEARCH:
      return generateColumnDefsSeoSearch(data);
    case TABLE_TYPE.COMPARISON:
      return generateColumnDefsComparison(data);
    default:
      return [];
  }
};

export const generateGridColumns = ({
  type,
  data,
  hideSoldColumn = false,
  isSellerCellClickable = true,
  showFilterHiddenTooltip = false,
  period = null,
}) => {
  return generateGridColumnsInner({
    type,
    data,
    hideSoldColumn,
    isSellerCellClickable,
    showFilterHiddenTooltip,
    period,
  });
};

export const generateGridHierarchyColumns = ({ type, isMobile, columnState }) => {
  const state = columnState ? columnState.find((col) => col.field === 'ag-Grid-AutoColumn') : null;

  switch (type) {
    case TABLE_TYPE.CATEGORIES:
    case TABLE_TYPE.CATEGORIES_GROWTH:
      return {
        id: COLUMNS.CATEGORY,
        field: 'name',
        headerName: 'Категория',
        flex: 1.5,
        minWidth: 250,
        pinned: isMobile ? false : state ? state.pinned : true,
        checkboxSelection: false,
        suppressMovable: true,
        cellRendererParams: {
          suppressRightAlign: true,
        },
        headerComponentParams: {
          settingsIcon: LoopIcon,
          settingsFilteredIcon: LoopSuccess,
          sortable: false,
        },
        cellRenderer: 'tableCellHighlight',
        ...textFilter,
      };
    default:
      return null;
  }
};
