import LoadingIcon from 'assets/icons/LoadingIcon';
import Alert from 'components/Alert';

const { default: ButtonPrimary } = require('components/ButtonPrimary');
const { useSeoProductContext } = require('context/seo.product.context');

const ProductCardSearch = () => {
  const { search, setSearch, doSearch, searchIsLoading, error, setError } = useSeoProductContext();
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      doSearch();
    }
  };

  function onChange(e) {
    const value = e.target.value;
    if (value.length <= 250) setSearch(value);
  }
  return (
    <div className="bg-gray-100 grow  wrapper-wide">
      <div className="my-16 mx-auto lg:w-3/5 p-6 bg-white rounded z-10">
        <h1 className="leading-9 font-extrabold">Проверка карточки товара</h1>
        <div className="text-gray-400 mt-1 mb-6 leading-4 font-normal">
          Подберем ключевые слова, которые подходят для твоей карточки и покажем, какие из них стоит
          в нее добавить. Ты увидишь, как расставлены слова в карточке, и сможешь отредактировать
          текст. Если нужно, проверь свой список поисковых запросов.
        </div>
        <div className="flex space-x-4">
          <input
            value={search || ''}
            disabled={searchIsLoading}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            type="text"
            autoFocus={true}
            placeholder={'Введите ссылку на товар или SKU'}
            className="block p-3 grow border border-gray-300 rounded focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 z-10 leading-5 text-base"
          />
          <div className="relative w-fit">
            {error && (
              <Alert
                items={[
                  {
                    key: 'products-search',
                    message: error,
                    type: 'error',
                  },
                ]}
                placement="top"
                hide={() => setError(null)}
              />
            )}
            <div>
              <ButtonPrimary
                onClick={doSearch}
                label={
                  <>
                    {searchIsLoading && <LoadingIcon className={'w-5 h-5 animate-spin mr-2'} />}
                    Проверить
                  </>
                }
                className="text-base rounded-lg h-12"
                disabled={searchIsLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSearch;
