import { useParams } from 'react-router';

import { NavListOnCallback } from 'components/NavList/NavListOnCallback';
import { TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { useProduct } from 'context/product.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import PropTypes from 'prop-types';
import { useFetchFilteredInnerData } from '../../hooks/useFetchFilteredInnerData';
import { getLinks } from './links';

const ProductTableNav = ({ onTabChange, isInner = false, barcode }) => {
  const { id } = useParams();
  const { isOzonPlatform } = useProduct();
  const {
    isProductWarehousesAvailable,
    isProductSizesColorsAvailable,
    isProductListingAvailable,
    isProductSearchAvailable,
    isAdvertisingFilterAvailable,
  } = useUserTariffs();

  // const { data } = useFetchFilteredProductData({
  //   type: TABLE_TYPE.PRODUCT_DATA,
  //   id,
  //   enabled: isProductSizesColorsAvailable && !isInner,
  // });

  const { data: productColors } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_COLORS,
    id,
    enabled: isProductSizesColorsAvailable && !isInner,
  });

  const { data: innerData } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.PRODUCT,
    data: { id },
    enabled: isInner,
  });

  const { data: sumData = {} } = useFetchFilteredProductData({
    type: TABLE_TYPE.EXT_PRODUCT_SUM,
    id,
    enabled: isProductSizesColorsAvailable && !isInner,
  });

  const isHaveSomeSizesAvailable = () => {
    try {
      if (sumData) {
        return sumData.sizes_avaliable;
      }
      // if (data && data.stock_by_sizes) return Object.keys(data.stock_by_sizes).length > 1;
    } catch {
      return false;
    }
  };

  const isHaveSomeColorsAvailable = () => {
    try {
      if (sumData) {
        return sumData.colors_avaliable;
      }
      if (productColors && productColors.all_data)
        return Object.keys(productColors.all_data).length > 1;

      if (productColors.all_data[id] && productColors.all_data[id].colors)
        return Object.keys(productColors.all_data[id].colors).length > 1;

      return false;
    } catch {
      // if (data && data.stock_by_sizes) {
      //   return Object.keys(data.stock_by_sizes).length > 1;
      // }

      return false;
    }
  };

  const isInnerSizesAvailable = innerData?.size !== '-' && innerData?.size !== '0';

  const computedTip = () => {
    if (!isHaveSomeSizesAvailable()) return 'У данного товара нет размеров';
    if (!isHaveSomeColorsAvailable()) return 'У данного товара нет цветов';

    return null;
  };

  return (
    <NavListOnCallback
      callback={onTabChange}
      items={getLinks({
        id,
        barcode,
        isInner,
        isOzonPlatform,
        isProductWarehousesAvailable,
        isProductSizesColorsAvailable,
        isProductListingAvailable,
        isProductSearchAvailable,
        isAdvertisingFilterAvailable,
        isInnerSizesAvailable,
        isHaveSomeColorsAvailable,
        isHaveSomeSizesAvailable,
        computedTip,
        innerData,
      })}
    />
  );
};
ProductTableNav.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  isInner: PropTypes.bool,
  barcode: PropTypes.string,
};

export default ProductTableNav;
