import cn from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { ReactComponent as Icon } from 'assets/images/back.svg';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const ButtonBack = ({ backTo, label = 'Назад', className, callBack }) => {
  const navigate = useNavigate();
  const { setFilterDefault } = useFilterReducer();

  const goBack = () => {
    if (backTo) {
      //при переходе между страницами обнулять некоторые параметры фильтра
      setFilterDefault({ nextLink: backTo, prevLink: window.location.pathname });
      callBack && callBack();
      navigate(backTo);
    } else {
      callBack && callBack();
      navigate(-1);
    }
  };

  if (window.history.length <= 1) {
    return <></>;
  }

  return (
    <button
      className={cn(
        className,
        'flex items-center text-green text-large hover:text-black transition-all',
      )}
      onClick={goBack}
    >
      <Icon /> <span className="ml-2 leading-none pt-0.5">{label}</span>
    </button>
  );
};

ButtonBack.propTypes = {
  backTo: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  callBack: PropTypes.func,
};

export default ButtonBack;
