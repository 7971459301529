import { lazy } from 'react';

import ButtonReset from 'components/ButtonReset';
import ChipListInner from 'components/ChipListInner';
import { CalendarButton } from 'components/Filter';
import { FilterModalInnerProvider } from 'context/filter.modal.inner.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import PropTypes from 'prop-types';
import { ApiKeySelect, PricesApiKeySelect, QuantityFilter /*, StatusSelect*/ } from './components';
import WarehouseSelector from './components/WarehouseSelector';
// import {isInnerSalesPage} from "../../utils/pages";

const FilterModalInner = lazy(() => import('components/FilterModal/FilterModalInner'));

const FilterInner = ({ showQuantity = false, useWarehouseSelector = false }) => {
  const { isFilterApplied, resetFilter } = useFilterReducer();
  return (
    <div className="flex flex-wrap gap-2 items-center">
      <CalendarButton />

      {showQuantity ? <PricesApiKeySelect /> : <ApiKeySelect />}
      {/*{isInnerSalesPage() && <StatusSelect />}*/}
      <QuantityFilter showQuantity={showQuantity} />
      {useWarehouseSelector && <WarehouseSelector />}
      <ChipListInner />

      <FilterModalInnerProvider>
        <FilterModalInner showQuantity={showQuantity} useWarehouseSelector={useWarehouseSelector} />
      </FilterModalInnerProvider>

      {isFilterApplied && <ButtonReset onClick={resetFilter} />}
    </div>
  );
};

FilterInner.propTypes = {
  showQuantity: PropTypes.bool,
  useWarehouseSelector: PropTypes.bool,
};

export default FilterInner;
