import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { BookmarkAdd } from '../../assets/icons/BookmarkAdd';
import { BookmarkCheckedIcon } from '../../assets/icons/BookmarkCheckedIcon';
import { BookmarkIcon } from '../../assets/icons/BookmarkIcon';
import { useAuth } from '../../context/auth.context';
import { useMonitoringGroups } from '../../context/monitoring.groups.context';
import baseClient from '../../services/baseClient';
import { generateTemplateName, TEMPLATE_TYPES } from '../../utils/templates';
import ButtonPrimary from '../ButtonPrimary';
import Modal from '../Modal';

const MonitoringGroupCreator = ({ products, phrases, isEntering }) => {
  const { noTariff } = useAuth();
  const { editGroup, setEditGroup } = useMonitoringGroups();
  const [newName, setNewName] = useState('');
  const [showModalNameCreator, setShowModalNameCreator] = useState(false);
  const [showModalAfterCreate, setShowModalAfterCreate] = useState(false);
  const { monitoringGroups, setMonitoringGroups, setShowGroupsModal } = useMonitoringGroups();
  const newNameRef = useRef();

  const handleCreateGroup = () => {
    setShowModalNameCreator(true);
    setNewName(generateTemplateName('Группа'));
    setTimeout(() => {
      newNameRef?.current?.focus();
    }, 0);
  };

  const handleCreateTemplate = async (e) => {
    e.stopPropagation();
    const preset = /*JSON.stringify(*/ {
      products: products.filter((el) => el),
      phrases: phrases.filter((el) => el),
      isEntering,
    }; /*)*/
    const groupsBeforeCreation = JSON.parse(JSON.stringify(monitoringGroups));
    const dataObj = {
      type: TEMPLATE_TYPES.MONITORING,
      name: newName,
      preset,
    };
    baseClient.createCommonTemplate({ data: dataObj }).then((data) => {
      if (data.status !== 201) {
        setMonitoringGroups(() => groupsBeforeCreation);
      } else {
        setMonitoringGroups(() => [{ ...dataObj, id: data.data.data.id }, ...groupsBeforeCreation]);
        // setActiveGroup(data.data.data.id);
      }
    });
    setMonitoringGroups([dataObj, ...monitoringGroups]);
    setShowModalNameCreator(false);
    setShowModalAfterCreate(true);
  };

  return (
    <>
      <button
        className={cn(
          'flex space-x-2 text-base bg-gray-light border border-gray-250 rounded cursor-pointer py-1.5 px-4 leading-5 whitespace-nowrap transition-all hover:disabled:text-black hover:text-green disabled:opacity-30 disabled:bg-gray-light disabled:cursor-not-allowed',
        )}
        disabled={!!(products.filter((el) => el).length === 0 || noTariff)}
        onClick={handleCreateGroup}
      >
        {editGroup ? (
          <>
            <>
              <BookmarkAdd />
              <span className="block">Сохранить как новую группу</span>
            </>
          </>
        ) : (
          <>
            <BookmarkIcon />
            <span className="block">Сохранить</span>
          </>
        )}
      </button>
      {showModalNameCreator && (
        <Modal
          isOpen={showModalNameCreator}
          title="Назовите группу"
          submitButtonText="Создать"
          submitModal={handleCreateTemplate}
          closeModal={() => setShowModalNameCreator(false)}
          width={500}
        >
          <div className="h-[152px] flex items-center justify-center">
            <input
              ref={newNameRef}
              className="w-full lg:w-72 text-2xl font-bold focus:outline-none border-b border-gray-250 pb-1 text-center"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
        </Modal>
      )}
      {showModalAfterCreate && (
        <Modal
          isOpen={showModalAfterCreate}
          title="Группа сохранена"
          submitButtonText="Закрыть"
          submitModal={() => {
            setShowModalAfterCreate(false);
            setEditGroup(undefined);
          }}
          closeModal={() => {
            setShowModalAfterCreate(false);
            setEditGroup(undefined);
          }}
          renderCancelButton={false}
          externalFooterContent={
            <ButtonPrimary
              className={'button-primary__inverted'}
              onClick={() => {
                setShowModalAfterCreate(false);
                setShowGroupsModal(true);
                setEditGroup(undefined);
              }}
              label={'Посмотреть пресеты'}
            />
          }
          width={500}
          externalFooterRight
        >
          <div className="flex items-center justify-center">
            <span className="text-[#858585]">
              Теперь вы можете найти его в разделе{' '}
              <span className="ml-[1px] inline-flex items-center space-x-1 text-black text-semibold relative top-1">
                <BookmarkCheckedIcon />
                <span className="block mr-0.5">Группы</span>
              </span>{' '}
              в верхнем правом углу экрана
            </span>
          </div>
        </Modal>
      )}
    </>
  );
};
MonitoringGroupCreator.propTypes = {
  products: PropTypes.array,
  isEntering: PropTypes.bool,
  phrases: PropTypes.array,
};

export { MonitoringGroupCreator };
