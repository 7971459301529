import CloseIcon from 'assets/icons/CrossIcon';
import LoadingSpin from 'components/LoadingSpin';
import PropTypes from 'prop-types';

const SearchInputSimplePostfix = ({ onCancel, isLoading }) => {
  return (
    <div className="flex absolute right-3">
      {isLoading && (
        <div className="w-fit">
          <LoadingSpin iconClassNames="!w-4 !h-4" />
        </div>
      )}
      <button className="ml-1 text-gray-400" onClick={onCancel}>
        <CloseIcon className={'h-5 w-5'} />
      </button>
    </div>
  );
};

SearchInputSimplePostfix.propTypes = {
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default SearchInputSimplePostfix;
