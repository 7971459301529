import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'components/App';
import AppProviders from 'context';

import './assets/styles/index.css';

import ErrorFallback from 'components/ErrorFallback';
import ErrorFallbackCommon from 'components/ErrorFallbackCommon';
import { IS_PRODUCTION } from 'constants/environment';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Ping from 'components/Ping';
import TagManager from 'react-gtm-module';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

if (IS_PRODUCTION) {
  const tagManagerArgs = {
    gtmId: 'GTM-T6DSKXK',
  };

  TagManager.initialize(tagManagerArgs);
}

import('react-yandex-metrika/lib/init').then((YMInitializer) => {
  if (IS_PRODUCTION) {
    YMInitializer.default([54208747], { webvisor: true }, '2');
  }
});

import('ga-gtag').then(({ install }) => {
  if (IS_PRODUCTION) {
    install('G-GSREK1D7HK');
  }
});

// Find the DOM element where you want to render your app
const rootElement = document.getElementById('root');
rootElement.setAttribute('notranslate', true);
// Create a root using the new ReactDOM.createRoot() method
const root = createRoot(rootElement);

root.render(
  <AppProviders>
    <ErrorBoundary
      FallbackComponent={
        process.env.NODE_ENV === 'production' ? ErrorFallbackCommon : ErrorFallback
      }
    >
      <Ping />
      <App />
    </ErrorBoundary>
  </AppProviders>,
);
