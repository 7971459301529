import PropTypes from 'prop-types';

const Button = ({ icon, label, onClick, disabled = false }) => {
  return (
    <button
      className="bg-white border border-gray-250 flex items-center self-stretch 
    px-1.5 lg:px-2 py-1 space-x-0.5 lg:space-x-1.5 rounded 
    transition-all hover:text-green hover:border-green focus:text-green focus:border-green
    "
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {label}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

export default Button;
