import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import Popover from 'components/Popover';

import { NOT_AVAILABLE_BECAUSE_TARIFF_DATE } from 'constants/tooltips';

export const RadioButton = ({
  name,
  value,
  label,
  isChecked,
  onChange,
  first,
  last,
  disabled = false,
}) => {
  const tooltipRef = useRef();

  return (
    <>
      <div ref={tooltipRef}>
        <input
          type="radio"
          id={name}
          name={name}
          value={value}
          className="hidden"
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <label
          dangerouslySetInnerHTML={{ __html: label }}
          htmlFor={name}
          className={cn('button-group__item', {
            ['button-group__item--active']: isChecked,
            ['button-group__item--disabled']: disabled,
            ['button-group__item--first']: first,
            ['button-group__item--last']: last,
          })}
        />
      </div>
      {disabled && <Popover tooltip={NOT_AVAILABLE_BECAUSE_TARIFF_DATE} tooltipRef={tooltipRef} />}
    </>
  );
};
RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  disabled: PropTypes.bool,
};
