import { useUI } from 'context/ui.context';
import PropTypes from 'prop-types';

const PositionsRating = ({
  selectedSeller,
  selectedBrand,
  rating = {
    top100: 0,
    top30: 0,
    top10: 0,
    top1: 0,
  },
}) => {
  const { isMobile } = useUI();
  const getBlock = (title, key) => {
    return (
      <div className="flex">
        <span className="text-gray-400 block text-sm">{title}</span>
        <div className="border-dashed border-b flex-1" />
        <span className="block text-black min-w-[45px] text-right">{`${rating[key]}%`}</span>
      </div>
    );
  };
  return (
    <div>
      {isMobile ? (
        <div className="my-3 bg-white rounded-lg border border-gray-230 p-3">
          <span className="text-black block font-medium">{`Доля ${
            selectedSeller?.title || selectedBrand?.title
          }`}</span>
          <div className="flex mt-4">
            <div className="grow pr-2 border-r border-gray-230 mr-2 flex">
              {getBlock(`Топ 100`, 'top100')}
            </div>
            <div className="grow flex">{getBlock(`Топ 10`, 'top10')}</div>
          </div>
          <div className="flex mt-2">
            <div className="grow pr-2 border-r border-gray-230 mr-2 flex">
              {getBlock(`Топ 30`, 'top30')}
            </div>
            <div className="grow flex">{getBlock(`Топ 1`, 'top1')}</div>
          </div>
        </div>
      ) : (
        <div className="mt-4 bg-white rounded-lg border border-gray-230 py-4 flex grow divide-x">
          <div className="flex justify-between px-4 w-3/12 border-l border-white">
            {getBlock(`Доля ${selectedSeller?.title || selectedBrand?.title} в топ 100`, 'top100')}
          </div>
          <div className="flex justify-between px-4 w-3/12">
            {getBlock(`Доля ${selectedSeller?.title || selectedBrand?.title} в топ 30`, 'top30')}
          </div>
          <div className="flex justify-between px-4 w-3/12">
            {getBlock(`Доля ${selectedSeller?.title || selectedBrand?.title} в топ 10`, 'top10')}
          </div>
          <div className="flex justify-between px-4 w-3/12">
            {getBlock(`Доля ${selectedSeller?.title || selectedBrand?.title} в топ 1`, 'top1')}
          </div>
        </div>
      )}
    </div>
  );
};

PositionsRating.propTypes = {
  selectedSeller: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  selectedBrand: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  rating: PropTypes.oneOfType([
    PropTypes.shape({
      top100: PropTypes.number,
      top30: PropTypes.number,
      top10: PropTypes.number,
      top1: PropTypes.number,
    }),
    PropTypes.array,
  ]),
};

export default PositionsRating;
