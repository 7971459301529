import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import CheckIcon from 'assets/icons/CheckIcon';
import DownIcon from 'assets/icons/DownIcon';
import TrashIcon from 'assets/icons/TrashIcon';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import { useUI } from 'context/ui.context';
import useClickOutside from 'hooks/useClickOutside';
import baseClient from 'services/baseClient';
import ButtonPrimary from '../../ButtonPrimary';
import { RadioButton } from '../../MobileInterface/components/PlatformPanel';
import Popover from '../../Popover';

const API_KEYS_TYPES = {
  STATISTIC: 1,
  PRICE: 2,
};

const MobileApiKeyTypeSelect = ({
  setIsTypeDropdownExpanded,
  isTypeDropdownExpanded,
  setApiKeyType,
  type,
}) => {
  const [selectType, setSelectType] = useState(type);
  return (
    <MobileInterfaceV2
      onPanelClose={() => setIsTypeDropdownExpanded(false)}
      rootClassName="h-fit bottom-0 absolute"
      isActive={isTypeDropdownExpanded}
      headerText="Выберите тип ключа"
    >
      <div className="px-4 divide-y">
        <div className="border-b border-[#E8E8E8]">
          <RadioButton
            onChange={() => setSelectType(1)}
            label={'Статистика'}
            isChecked={selectType === 1}
            containerCn="border-none mx-0"
          />
          <span className="block text-xs font-normal text-gray-400 leading-[19px] mb-4">
            Чтобы получать аналитику по заказам
            <br /> и продажам своих товаров
          </span>
        </div>
        <div>
          <RadioButton
            onChange={() => setSelectType(2)}
            label={'Цены'}
            isChecked={selectType === 2}
            containerCn="border-none mx-0"
          />
          <span className="block text-xs font-normal text-gray-400 leading-[19px] mb-8">
            Чтобы управлять изменением цен и скидками
          </span>
        </div>
        <ButtonPrimary
          onClick={() => {
            setApiKeyType(selectType);
            setIsTypeDropdownExpanded(false);
          }}
          label={<span className="block mx-auto">Применить</span>}
          disabled={!selectType}
          className="w-full text-center py-2 mb-4 items-center"
        />
      </div>
    </MobileInterfaceV2>
  );
};

MobileApiKeyTypeSelect.propTypes = {
  setIsTypeDropdownExpanded: PropTypes.func,
  isTypeDropdownExpanded: PropTypes.bool,
  setApiKeyType: PropTypes.func,
  type: PropTypes.any,
};

const ApiKeyTypeDropDown = ({ isDropdownExpanded, setType }) => {
  if (!isDropdownExpanded) {
    return null;
  }
  const hoverClass = 'hover:text-green cursor-pointer focus:outline-none hover:bg-green-50';
  return (
    <div
      className={
        'absolute -right-[1px] rounded bg-white top-12 shadow-[0_2px_8px_rgba(0,0,0,0.1)] z-10 w-64'
      }
    >
      <button
        className={cn('block px-3 py-2 rounded-t text-left w-full group', hoverClass)}
        onClick={() => setType(API_KEYS_TYPES.STATISTIC)}
      >
        <span className="block text-sm font-normal text-gray-700 mb-1 group-hover:text-gray-400">
          Статистика
        </span>
        <span className="block text-xs font-normal text-gray-400 leading-[19px]">
          Чтобы получать аналитику по заказам и продажам своих товаров
        </span>
      </button>
      <button
        className={cn('block w-full pl-3 py-2 rounded-b text-left group', hoverClass)}
        onClick={() => setType(API_KEYS_TYPES.PRICE)}
      >
        <span className="block text-sm font-normal text-gray-700 mb-1 group-hover:text-gray-400">
          Цены
        </span>
        <span className="block text-xs font-normal text-gray-400 leading-[19px]">
          Чтобы управлять изменением цен <br />и скидками
        </span>
      </button>
    </div>
  );
};

ApiKeyTypeDropDown.propTypes = {
  isDropdownExpanded: PropTypes.bool,
  setType: PropTypes.func,
};

export const ApiKey = (props) => {
  const { isMobile } = useUI();
  const { apiKey, isNew, canDelete = () => true, handleDelete, refetch } = props;
  const { id, status } = apiKey;
  const [name, setName] = useState(apiKey?.name || '');
  const [api_key, setApiKey] = useState(apiKey?.token || '');
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFocused, setIsFocused] = useState(isNew);
  const [isTypeDropdownExpanded, setIsTypeDropdownExpanded] = useState(false);
  const [apiKeyType, setApiKeyType] = useState(apiKey?.type);

  const ref = useRef();

  useClickOutside(ref, () => {
    if (!isMobile) setIsTypeDropdownExpanded(false);
  });
  const inputClasses =
    'w-full h-[46px] border border-gray-250 rounded-lg px-5 focus:outline-none focus:ring-1 focus:ring-green placeholder:text-sm placeholder:text-gray-400';
  const handleNameChange = (e) => {
    setIsError(false);
    setName(e.target.value);
    setIsEdit(true);
  };
  const handleApiKeyChange = (e) => {
    if (isNew) {
      setApiKey(e.target.value);
      if (isError) {
        setIsError(false);
      }
    }
  };

  const handleTypeChange = (value) => {
    if (isError && !apiKeyType) {
      setIsError(false);
    }
    setApiKeyType(value);
    setIsTypeDropdownExpanded(false);
  };
  const handleSubmitClick = async () => {
    if (!name || !api_key) {
      setIsError(true);
      return;
    }
    if (isNew) {
      if (!apiKeyType) {
        setIsError(true);
        return;
      }
      const { status } = await baseClient.createApiKey({ name, api_key, apiKeyType });
      if (status === 201 || status === 200) {
        refetch();
      } else {
        setIsError(true);
      }
    } else if (isEdit) {
      const { status } = await baseClient.updateApiKeyName({ id, name });
      if (status === 204) {
        setIsEdit(false);
        refetch();
      }
    }
  };
  const isCanDelete = canDelete(name, api_key);
  const actionButtonClasses = 'py-3 border rounded-lg h-[46px] text-center w-28 justify-center';

  const statusBlock = (
    <div
      className={cn(
        'mt-4 lg:mt-0 lg:mb-4 flex grow lg:w-fit bg-gray-50 rounded-lg h-[46px] px-4 items-center',
      )}
    >
      <div
        className={cn('rounded h-2 w-2 bg-green', {
          '!bg-red': status === 0,
        })}
      ></div>
      <span className="block ml-2">{status === 0 ? 'Неактивен' : 'Активен'}</span>
    </div>
  );

  return (
    <div>
      <div className="block space-y-3 lg:flex w-full lg:space-x-3 lg:space-y-0 py-4">
        <div className="block grow lg:space-x-3 lg:flex lg:space-y-0 space-y-3">
          <input
            className={cn('lg:w-3/12', inputClasses, {
              'ring-1 ring-red-bright': isError && !name,
            })}
            value={name}
            onChange={handleNameChange}
            placeholder="Название компании"
          />
          <div
            className={cn('block lg:grow lg:flex border border-gray-250 rounded-lg', {
              'ring-1 ring-red-bright': isError && ((name && !apiKeyType) || !api_key),
              'ring-1 ring-green': isFocused,
            })}
          >
            <input
              className={cn(
                'h-[46px] lg:h-full lg:grow w-full px-5 focus:outline-none rounded-lg',
                {
                  '!text-red': isError && name && apiKeyType,
                },
              )}
              value={api_key}
              onChange={handleApiKeyChange}
              placeholder="API-Ключ для Wildberries"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              autoFocus={isNew}
            />
            <div
              className="grow lg:max-w-[132px] lg:w-[132px] lg:min-w-[132px] h-[46px] flex-col flex lg:flex-row relative"
              ref={ref}
            >
              {!isNew && (
                <Popover
                  className="opacity-100 bg-opacity-100 whitespace-nowrap"
                  offset={[0, 10]}
                  tooltip={`<span>Тип ключа нельзя изменить<br/> после добавления</span>`}
                  tooltipRef={ref}
                />
              )}
              <div className="lg:my-3 lg:w-px lg:h-auto lg:mx-0 bg-gray-230 mx-5 h-px" />
              <button
                disabled={!isNew}
                className={cn('flex grow text-gray-400 items-center px-5 lg:px-3 justify-between', {
                  '!text-red': isError && !apiKeyType,
                })}
                onClick={() => setIsTypeDropdownExpanded(!isTypeDropdownExpanded)}
              >
                <span className={cn('block', { 'text-gray-700': apiKeyType })}>
                  {apiKeyType
                    ? apiKeyType === API_KEYS_TYPES.STATISTIC
                      ? 'Статистика'
                      : 'Цены'
                    : 'Выберите тип'}
                </span>
                <DownIcon
                  width={14}
                  height={14}
                  className={cn('text-gray-700', {
                    '!text-red': isError && !apiKeyType,
                  })}
                />
              </button>
              {isMobile ? (
                <MobileApiKeyTypeSelect
                  setIsTypeDropdownExpanded={setIsTypeDropdownExpanded}
                  isTypeDropdownExpanded={isTypeDropdownExpanded}
                  setApiKeyType={setApiKeyType}
                  type={apiKeyType}
                />
              ) : (
                <ApiKeyTypeDropDown
                  isDropdownExpanded={isTypeDropdownExpanded}
                  setType={handleTypeChange}
                />
              )}
            </div>
          </div>
          {!isNew && <div className="lg:hidden visible">{statusBlock}</div>}
        </div>
        <div className="space-x-3 flex lg:w-[184px]">
          {(isNew || isEdit) && (
            <button
              className={cn(actionButtonClasses, {
                ['flex grow lg:w-fit text-white bg-green border-green transition-all hover:bg-gray-50 focus:bg-gray-50 hover:text-green focus:text-green']:
                  isNew || (!isNew && isEdit),
              })}
              onClick={handleSubmitClick}
            >
              {isNew ? 'Добавить' : 'Сохранить'}
            </button>
          )}

          {!isNew && !isEdit && (
            <div
              className={cn(
                actionButtonClasses,
                'flex grow lg:w-fit border-gray-150 bg-gray-50 items-center space-x-[7px]',
              )}
            >
              <CheckIcon width={14} height={14} className="text-green" />
              <span className="block">Добавлен</span>
            </div>
          )}

          {isCanDelete && (
            <button
              onClick={() => {
                handleDelete(setName, setApiKey);
                setIsError(false);
              }}
              className="text-green border border-gray-150 bg-gray-50 rounded-lg px-4 py-3.5 h-[46px] transition-all hover:bg-green focus:bg-green hover:border-green focus:border-green hover:text-gray-50 focus:text-gray-50"
            >
              <TrashIcon width={18} height={18} />
            </button>
          )}
        </div>
      </div>
      {!isNew && <div className="hidden lg:block">{statusBlock}</div>}
    </div>
  );
};

ApiKey.propTypes = {
  apiKey: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.any,
    status: PropTypes.any,
  }),
  isNew: PropTypes.bool,
  canDelete: PropTypes.func,
  handleDelete: PropTypes.func,
  refetch: PropTypes.func,
};
