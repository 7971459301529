import DownIcon from 'assets/icons/DownIcon';
import LinkIcon from 'assets/icons/LinkIcon';
import NoPhotoIcon from 'assets/icons/NoPhotoIcon';
import { useUI } from 'context/ui.context';
import { useProductLink, useSellerLink } from 'hooks/useLocationPath';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Link } from 'react-router';
import { replaceJpgWithWebp } from 'utils/images';
import Popover from '../../Popover';

const AdvertisingRatesGridCard = ({ cardInfo }) => {
  const {
    image,
    cpm,
    position,
    name,
    seller,
    page,
    id,
    external_id,
    listing,
    url,
    subject,
    price,
  } = cardInfo;
  const [isHover, setIsHover] = useState(false);
  const { isMobile } = useUI();

  const productLink = useProductLink(id);
  const sellerLink = useSellerLink(seller.id);

  const sellerRef = useRef();
  const productRef = useRef();

  return (
    <div
      className="flex-1 flex sm:flex-col rounded-lg border border-solid border-gray-150 sm:divide-y sm:divide-gray-150 bg-white hover:shadow-[0_2px_8px_rgba(0,0,0,0.1)]"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="relative">
        <div className="absolute flex top-3 left-3 rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle bg-[#F4F4D2]">
          <span className="block text-xs text-[#333D37] font-bold">{position || listing}</span>
          <span className="block text-xs ml-1 text-gray-400 relative top-[1px]">позиция</span>
        </div>
        <div className="absolute flex top-9 left-3 rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle bg-[#F4F4D2]">
          <span className="block text-xs text-[#333D37] font-bold">{page}</span>
          <span className="block text-xs ml-1 text-gray-400 relative top-[1px]">стр</span>
        </div>
        {isHover && (
          <>
            <a
              href={productLink}
              rel="noreferrer"
              target="_blank"
              className={
                'absolute top-3 right-3 cursor-pointer border border-green-200 hover:border-green bg-green-200 hover:bg-green rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle transition-all text-green hover:text-white'
              }
            >
              <span className="inline-block align-middle pt-0.5">WECHECK</span>
              <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
            </a>
            <a
              href={
                url
                  ? url
                  : `https://www.wildberries.ru/catalog/${external_id}/detail.aspx?targetUrl=XS`
              }
              rel="noreferrer"
              target="_blank"
              className={
                'absolute top-9 right-3 cursor-pointer border border-[#FBF2FA] hover:border-wb bg-[#FBF2FA] hover:bg-wb rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle transition-all text-wb hover:text-white'
              }
            >
              <span className="inline-block align-middle pt-0.5">WB</span>
              <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
            </a>
          </>
        )}
        <div
          className={`w-[140px] h-full lg:h-[200px] rounded-l-lg sm:h-[19rem] sm:w-full sm:rounded-l-none sm:rounded-t-lg flex`}
        >
          {!image || image === '-' ? (
            <NoPhotoIcon className="self-center mx-auto" />
          ) : (
            <img
              src={replaceJpgWithWebp(image)}
              alt={name}
              className="w-full rounded-l-lg sm:h-full sm:rounded-l-none sm:rounded-t-lg object-cover"
            />
          )}
        </div>
      </div>
      <div className="divide-y divide-gray-150 lg:px-3 lg:py-2 sm:px-5 pt-2 sm:pt-1 grow sm:grow-0">
        <div className="flex justify-between pb-1 items-center">
          <span className="font-bold text-lg leading-5 grow">{`${cpm.toLocaleString()} ₽`}</span>
          <span className="block text-gray-400 text-xs leading-[18px]">за 1000 показов</span>
        </div>
        <div className="relative">
          <Link
            ref={productRef}
            to={productLink}
            rel="noreferrer"
            target="_blank"
            className="flex justify-between grow py-1 items-center group"
          >
            <span className="font-medium text-sm leading-5 grow text-black group-hover:text-green whitespace-nowrap text-ellipsis overflow-hidden">
              {name}
            </span>
            <div className="flex text-gray-400 group-hover:text-green items-center ml-1 text-xs leading-[18px]">
              <span className="block">товар</span>
              <DownIcon width={10} height={10} className="-rotate-90 ml-0.5 relative top-[1px]" />
            </div>
          </Link>
          {!isMobile && name && (
            <Popover tooltipRef={productRef} className="bg-opacity-100" tooltip={name} />
          )}
        </div>
        <div className="relative">
          <Link
            ref={sellerRef}
            to={sellerLink}
            rel="noreferrer"
            target="_blank"
            className="flex justify-between grow py-1 items-center group"
          >
            <span className="font-medium text-sm leading-5 grow text-black group-hover:text-green whitespace-nowrap text-ellipsis overflow-hidden">
              {seller.name}
            </span>
            <div className="flex text-gray-400 group-hover:text-green items-center ml-1 text-xs leading-[18px]">
              <span className="block">продавец</span>
              <DownIcon width={10} height={10} className="-rotate-90 ml-0.5 relative top-[1px]" />
            </div>
          </Link>
          {!isMobile && seller?.name && (
            <Popover tooltipRef={sellerRef} className="bg-opacity-100" tooltip={seller.name} />
          )}
        </div>
        <div className="relative">
          <div className="flex justify-between grow py-1 items-center">
            <span className="font-medium text-sm leading-5 grow text-black group-hover:text-green whitespace-nowrap text-ellipsis overflow-hidden">
              {subject}
            </span>
            <div className="flex text-gray-400 group-hover:text-green items-center ml-1 text-xs leading-[18px]">
              <span className="block">предмет</span>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="flex justify-between grow py-1 items-center">
            <span className="font-medium text-sm leading-5 grow text-black group-hover:text-green whitespace-nowrap text-ellipsis overflow-hidden">
              {`${price} ₽`}
            </span>
            <div className="flex text-gray-400 group-hover:text-green items-center ml-1 text-xs leading-[18px]">
              <span className="block">цена товара</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AdvertisingRatesGridCard.propTypes = {
  cardInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    position: PropTypes.number,
    listing: PropTypes.number,
    cpm: PropTypes.number,
    name: PropTypes.string.isRequired,
    external_id: PropTypes.number,
    page: PropTypes.number,
    seller: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      external_id: PropTypes.number,
    }),
    subject: PropTypes.string,
    price: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};
export default AdvertisingRatesGridCard;
