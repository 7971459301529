import PropTypes from 'prop-types';

const Arrow = ({ strokeWidth = 2, ...props }) => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.58301 1.5L5.08301 5L1.58301 8.5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Arrow.propTypes = {
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Arrow;
