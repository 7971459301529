import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Chip from 'components/Chip';
import { F } from 'constants/filter';
import { QUERY_TYPE } from 'constants/query';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUI } from 'context/ui.context';
import { useUserList } from 'context/user.list.context';
import { useFetchNamesData } from 'hooks/useFetchNamesData';
import { useCategoriesParents } from 'hooks/useSearch';
import { useNavigate } from 'react-router';
import { getBreadcrumbs } from 'utils/chips';
import { PAGE_TRENDS } from '../../constants';

const CategoryChip = ({ id, title, color, action }) => {
  const { updateChips, chips } = useFilterReducer();

  const { data, isLoading } = useCategoriesParents(id);
  const { isMobile } = useUI();

  const breadcrumbs = getBreadcrumbs(data || []);

  const handleClick = ({ key, title }) => {
    const previousChips = chips?.[F.CATEGORY];
    const currentChipKey = breadcrumbs[breadcrumbs.length - 1]?.key;

    updateChips({
      [F.CATEGORY]: [
        ...previousChips.filter((chip) => chip.key !== currentChipKey),
        { key, title },
      ],
    });
  };

  const breadcrumbsOutput = breadcrumbs.map((item, i) => (
    <React.Fragment key={item.key}>
      <button
        className="hover:text-green"
        onClick={() => handleClick({ key: item.key, title: item.title })}
      >
        {item.title}
      </button>

      {i !== breadcrumbs.length - 1 ? <span> / </span> : ''}
    </React.Fragment>
  ));

  if (!title) {
    return <></>;
  }

  return (
    <Chip
      isMobile={isMobile}
      color={color}
      title={isLoading ? title : <>{breadcrumbsOutput}</>}
      action={action}
    />
  );
};
CategoryChip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['bg-blue', 'bg-green-light', 'bg-yellow', 'bg-gray-200']).isRequired,
  action: PropTypes.func.isRequired,
};

const prepareChip = ({ data, key }) => {
  const result = {};

  if (data?.all_data) {
    result[key] = [];
    data?.all_data.forEach(({ id, name }) => {
      result[key].push({ key: id, title: name });
    });
  }
  return result;
};

const ChipList = () => {
  const {
    chips,
    deleteChip,
    updateChips,
    filter,
    toggleIsNew,
    toggleHasAdvertising,
    setFilterDefault,
  } = useFilterReducer();
  const navigate = useNavigate();
  const { handleListActive } = useUserList();
  const isTrends = window.location.pathname.indexOf(PAGE_TRENDS) >= 0;
  const brandsNoNamed = chips?.brand?.filter(({ title }) => !title).map(({ key }) => key);
  const sellersNoNamed = chips?.seller?.filter(({ title }) => !title).map(({ key }) => key);
  const subjectsNoNamed = chips?.subject?.filter(({ title }) => !title).map(({ key }) => key);

  const { data: brands } = useFetchNamesData({
    type: QUERY_TYPE.SEARCH_BRANDS,
    ids: brandsNoNamed,
  });
  const { data: sellers } = useFetchNamesData({
    type: QUERY_TYPE.SEARCH_SELLERS,
    ids: sellersNoNamed,
  });

  const { data: subjects } = useFetchNamesData({
    type: QUERY_TYPE.SEARCH_SUBJECTS,
    ids: subjectsNoNamed,
  });
  useEffect(() => {
    if (!brands && !sellers && !subjects) return;

    const brandsNamed = prepareChip({ data: brands, key: 'brand' });
    const sellersNamed = prepareChip({ data: sellers, key: 'seller' });
    const subjectsNamed = prepareChip({ data: subjects, key: 'subject' });
    updateChips({ ...brandsNamed, ...sellersNamed, ...subjectsNamed });
  }, [brands, sellers, subjects, updateChips]);

  const onRemove = (type, key) => {
    deleteChip({ type, key });
    handleListActive(null);
    if (isTrends && [...chips?.category, ...chips?.seller, ...chips?.brand].length === 1) {
      setFilterDefault({ nextLink: '/main', prevLink: window.location.pathname });
      navigate('/main');
    }
  };

  return (
    <>
      {chips?.category?.map(({ key, title }) => (
        <CategoryChip
          id={key}
          key={key}
          color="bg-blue"
          title={title || `${key}`}
          action={() => onRemove(F.CATEGORY, key)}
        />
      ))}
      {chips?.promoCategory?.map(({ key, title }) => (
        <CategoryChip
          id={key}
          key={key}
          color="bg-cyan"
          title={title || `${key}`}
          action={() => onRemove(F.PROMO_CATEGORY, key)}
        />
      ))}
      {chips?.subject?.map(({ key, title }) => (
        <Chip
          id={key}
          key={key}
          color="bg-gray-200"
          title={title || `${key}`}
          action={() => onRemove(F.SUBJECT, key)}
        />
      ))}
      {chips?.seller?.map(({ key, title }) => (
        <Chip
          key={key}
          color="bg-yellow"
          title={title || `${key}`}
          action={() => onRemove(F.SELLER, key)}
        />
      ))}
      {chips?.brand?.map(({ key, title }) => (
        <Chip
          key={key}
          color="bg-green-light"
          title={title || `${key}`}
          action={() => onRemove(F.BRAND, key)}
        />
      ))}
      {!!filter.isNew && <Chip color="bg-zinc-100" title="Новинки" action={toggleIsNew} />}
      {!!filter.hasAdvertising && (
        <Chip color="bg-zinc-100" title="Реклама" action={toggleHasAdvertising} />
      )}
    </>
  );
};

export default ChipList;
