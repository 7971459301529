import LinkIcon from 'assets/icons/LinkIcon';
import classNames from 'classnames';
import propTypes from 'prop-types';
/**
 * @typedef IProps
 *
 * @property {string} value Значение, которое будет отрисовано, товар не относится ни к одной из платформ (WB, Ozon)
 * @property {boolean} isOzonPlatform Флаг, который определяет товар как OZON'овский
 * @property {boolean} isWbPlatform Флаг, который пределяте товар как WB'ский
 * */
/**
 * @returns {JSX.Element} Ссылку, которая отрисовывает внутри себя НАЗВАНИЕ ПЛАТФОРМЫ и СТРЕЛОЧКУ, окрашенные в брендовые цвета платформы
 *
 * @param {IProps} props
 * */
export default function PlatformLink({ value, isWbPlatform, isOzonPlatform }) {
  if (!value) return '';
  if (!isOzonPlatform && !isWbPlatform) return value;

  let url = value;
  if (isWbPlatform) {
    url = 'https://www.wildberries.ru/catalog/' + value + '/detail.aspx';
  }
  return (
    <a
      href={url}
      rel="noreferrer"
      target="_blank"
      className={classNames(
        'cursor-pointer border border-gray-250 rounded text-[10px] leading-none px-1 py-0.5 inline-block align-middle transition-all hover:text-white',
        {
          ['text-ozon hover:border-ozon hover:bg-ozon']: isOzonPlatform,
          ['text-wb hover:border-wb hover:bg-wb']: !isOzonPlatform,
        },
      )}
    >
      <span className="inline-block align-middle pt-0.5">{isOzonPlatform ? 'OZ' : 'WB'}</span>
      <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
    </a>
  );
}

PlatformLink.propTypes = {
  value: propTypes.string.isRequired,
  isOzonPlatform: propTypes.bool,
  isWbPlatform: propTypes.bool,
};
