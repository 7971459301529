import Button from 'components/Button';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ProductSearchModal from './ProductSearchModal';

const ProductSearchModalButton = ({ button, modal }) => {
  const { onComplete } = modal || {};
  const [showModal, setShowModal] = useState(false);

  const submitModal = (data) => {
    if (onComplete) onComplete(data);
    setShowModal(false);
  };
  return (
    <>
      <Button {...button} onClick={() => setShowModal(true)} />
      {showModal && (
        <ProductSearchModal
          title={'Поиск Товара'}
          {...modal}
          closeModal={() => setShowModal(false)}
          submitModal={submitModal}
        />
      )}
    </>
  );
};

ProductSearchModalButton.propTypes = {
  button: PropTypes.object,
  modal: PropTypes.object,
};

export default ProductSearchModalButton;
