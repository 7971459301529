import PropTypes from 'prop-types';

import { COLUMNS } from 'constants/table';
import { replaceJpgWithWebp } from 'utils/images';
import EmptyPhoto from '../../assets/icons/EmptyPhoto';

const TableCellImage = ({ value = '', data, valueFormatted }) => {
  if (value === 'no_seo_image') {
    return null;
  }
  if (!value && !valueFormatted) {
    return (
      <div className="flex items-center pt-[2px]">
        <EmptyPhoto width={32} height={32} />
      </div>
    );
  }

  return (
    <>
      <img
        src={valueFormatted ? replaceJpgWithWebp(valueFormatted) : replaceJpgWithWebp(value)}
        alt={data?.[COLUMNS.NAME] || 'photo'}
        className="h-8 w-8 border border-solid border-grey-light rounded"
      />
    </>
  );
};

TableCellImage.propTypes = {
  value: PropTypes.string,
  valueFormatted: PropTypes.string,
  data: PropTypes.object,
};

export default TableCellImage;
