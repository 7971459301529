import SearchIcon from 'assets/icons/SearchIcon';
import TabletIcon from 'assets/icons/TabletIcon';
import { ProductSearchModalButton } from 'components/ProductsSearch';
import { useComparisonContext } from '../../../context/comparison.context';
import ProductListModalButton from '../ProductListModalButton';

const ComparisonEmptyProducts = () => {
  const { updateProducts } = useComparisonContext();

  return (
    <div className="min-h-full flex flex-col items-center justify-center flex-grow place-content-center bg-gray-100 border border-l-0 border-r-0 border-gray-200 ">
      <div className="text-gray-500">
        <div className="space-y-10 text-center">
          <div className="flex-col space-y-2">
            <TabletIcon className={'h-10 w-10 m-auto'} />
            <div>Сейчас нет товаров для сравнения</div>
          </div>

          <div className="space-y-2 flex flex-col">
            <ProductListModalButton label={'Выберите из сохраненных списков'} />
            <div>или</div>
            <ProductSearchModalButton
              button={{
                icon: <SearchIcon className={'h-4 w-4'} />,
                label: 'Воспользуйтесь поиском по сервису',
              }}
              modal={{ onComplete: updateProducts }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonEmptyProducts;
